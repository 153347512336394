import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Loader from "../../components/Loader/Loader";
import "./studentList.css";
import ReactPaginate from "react-paginate";
// import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../customHooks/searchLayout";
import { ChildFilterStage, URL } from "../../DROPDOWNDATA";

import OutsideClickHandler from "react-outside-click-handler";
import Pagination from "react-js-pagination";
import FileSaver from "file-saver";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function StudentList(props) {
  const Userrole = useSelector((store) => store.privilege);
  const [loader, setLoader] = useState(false);
  const [value, setValue] = useState(0);
  const [searchType, setSearchType] = useState(true);
  const [studentTableStateData, setStudentTableStateData] = useState([]);
  const [schoolTableStateData, setSchoolTableStateData] = useState([]);

  const [SearchValue, setSearchValue] = useState("");
  const [Count, setCount] = useState(1);

  const searchRef = useRef();
  const DropDown = useRef();

  const [ClearSearchTime, setClearSearchTime] = useState(0)

  // PAGINATION
  const [pg_count, setPg_count] = useState(1);

  const [dropdown, setDropdown] = useState(true);
  const [valueDropdown, setvalueDropdown] = useState(0);

  const [childFilterData, setChildFilterData] = useState({
    child_name: SearchValue,
    standard_filter: "",
    filter: {
      yet_to_start: 0,

      teacher_evaluation: 0,

      parent_evaluation: 0,

      level_1_complete: 0,

      level_2_complete: 0,

      screening_report_shared: 0,

      child_enrolled_for_screening: 0,

      child_enrollment_paused: 0,

      child_enrollment_completed: 0,
    },
  });

  const getStudentTableData = async () => {
    let datas;
    setLoader(true);
    await fetch(URL + "/child_filter?page_no=1", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(
        (datas = {
          child_name: SearchValue,
          standard_filter: "",
          filter: {
            yet_to_start: 0,

            teacher_evaluation: 0,

            parent_evaluation: 0,

            level_1_complete: 0,

            level_2_complete: 0,

            screening_report_shared: 0,

            child_enrolled_for_screening: 0,

            child_enrollment_paused: 0,

            child_enrollment_completed: 0,
          },
        })
      ),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        setLoader(false);
        console.log("obj.body");
        console.log(obj.body);

        setStudentTableStateData(obj.body.child_list);
        setCount(obj.body.total_count);
      });
  };

  const handlePageClick = async (event) => {
    let datas;
    let go_to = event;
    console.log(go_to);

    if (Count == go_to) {
      console.log("disable crash");
    }
    // setLoader(true)
    await fetch(URL + `/child_filter?page_no=${go_to}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(childFilterData),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        // setLoader(false)
        console.log("obj.body");
        console.log(obj.body);
        setPg_count(go_to);
        setStudentTableStateData(obj.body.child_list);
        setCount(obj.body.total_count);
      });
  };

  const getSchoolTableData = async () => {
    setLoader(true);
    await fetch(URL + "/school_list?page_no=1", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        setLoader(false);
        console.log("school list");
        console.log(obj.body);

        setSchoolTableStateData(obj.body);
      });
  };




  const handleChange = (event, newValue) => {
    searchRef.current.value = "";
    setSearchType(!searchType);
    setValue(newValue);
  };

  const Search_Input = async (data) => {
    let datas;
    setLoader(false);
    await fetch(URL + "/child_filter?page_no=1", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(data),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        setStudentTableStateData(() => obj.body.child_list);
        setCount(obj.body.total_count);
        setLoader(false);
        setPg_count(1);
      });
  };
  // let ClearSearchTime = 0;
  // let intervalbreak;
  // function Increment() {
  //   intervalbreak = setInterval(() => {
  //     setClearSearchTime(ClearSearchTime + 1)
  //   }, 1000);
  // }


  const HandleSearch = (e) => {
    setSearchValue(e);
    setChildFilterData(childFilterData, (childFilterData.child_name = e));
    setClearSearchTime(1)
    // Increment()
    // console.log(ClearSearchTime);
    // if (ClearSearchTime == 1000) {
    //   setClearSearchTime(0);
    //   clearInterval(intervalbreak)

    // }
  };
  const toggleDropdown = (value) => {
    setDropdown(false);
    setvalueDropdown(value);

    if (value == valueDropdown) {
      setDropdown(true);
      setvalueDropdown(0);
    }
  };
  const standard_filter = (event) => {
    let Standard_array = [];
    let std_dp = document.querySelectorAll(".std_dp");

    for (let i = 0; i < std_dp.length; i++) {
      if (std_dp[i].checked) {
        // console.log(std_dp[i].value);
        Standard_array.push(std_dp[i].value);
      }
    }
    setChildFilterData(
      childFilterData,
      (childFilterData.standard_filter = Standard_array.join())
    );
    console.log(childFilterData);

    Search_Input(childFilterData);
  };

  const stageFilter = (event) => {
    if (event.target.name == "Teacherevaluation") {
      setChildFilterData(
        childFilterData,
        (childFilterData.filter.teacher_evaluation = event.target.checked
          ? 1
          : 0)
      );
    } else if (event.target.name == "Parentevaluation") {
      setChildFilterData(
        childFilterData,
        (childFilterData.filter.parent_evaluation = event.target.checked
          ? 1
          : 0)
      );
    } else if (event.target.name == "level1") {
      setChildFilterData(
        childFilterData,
        (childFilterData.filter.level_1_complete = event.target.checked ? 1 : 0)
      );
    } else if (event.target.name == "level2") {
      setChildFilterData(
        childFilterData,
        (childFilterData.filter.level_2_complete = event.target.checked ? 1 : 0)
      );
    } else if (event.target.name == "Screeningreportshared") {
      setChildFilterData(
        childFilterData,
        (childFilterData.filter.screening_report_shared = event.target.checked
          ? 1
          : 0)
      );
    } else if (event.target.name == "Enrolledforintervention") {
      setChildFilterData(
        childFilterData,
        (childFilterData.filter.child_enrolled_for_screening = event.target
          .checked
          ? 1
          : 0)
      );
    } else if (event.target.name == "Interventionsuspended") {
      setChildFilterData(
        childFilterData,
        (childFilterData.filter.child_enrollment_paused = event.target.checked
          ? 1
          : 0)
      );
    } else if (event.target.name == "Interventioncompleted") {
      setChildFilterData(
        childFilterData,
        (childFilterData.filter.child_enrollment_completed = event.target
          .checked
          ? 1
          : 0)
      );
    } else if (event.target.name == "yettostart") {
      setChildFilterData(
        childFilterData,
        (childFilterData.filter.yet_to_start = event.target.checked ? 1 : 0)
      );
    }

    console.log(childFilterData);
    Search_Input(childFilterData);
  };
  const closeDropDown = (e) => {
    console.log(e.path);
    console.log(DropDown.current);
    setDropdown(true);
  };
  async function DownloadHandle() {
    setLoader(true);
    await fetch(URL + "/graded_tools_excel", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
    })
      .then((r) => r.blob())
      .then((obj) => {
        let d = new Date();
        let Gdate = d.toISOString().split("T")[0];
        console.log(Gdate);
        setLoader(false);
        FileSaver.saveAs(obj, `Graded_tool_export(${Gdate}).xlsx`);
      });
  }
  useEffect(() => {
    getStudentTableData();
    // getSchoolTableData();

    props.setBreadcrumItem([{ name: "Student List", link: "/" }]);

    localStorage.removeItem("childID");
    localStorage.removeItem("childName");

    // return () => {
    //   document.querySelector('.student_list_container :not(.stage)').removeEventListener('click', closeDropDown)
    // }
  }, []);
  useEffect(() => {
    let interval = '';
    if (ClearSearchTime != 0) {
      interval = setInterval(() => {
        setClearSearchTime(ClearSearchTime => ClearSearchTime + 1);
      }, 1);
    }
    if (ClearSearchTime > 100) {
      clearInterval(interval)
      Search_Input(childFilterData)
    }
    return () => {
      if (ClearSearchTime != 0) { clearInterval(interval) }
    };
  }, [ClearSearchTime])
  // document.querySelectorAll('.stage').addEventListener('click', closeDropDown)
  if (loader) {
    return <Loader />;
  }
  return (
    <div className="student_list_container">
      {/* <OutsideClickHandler
        onOutsideClick={() => {
          // alert('You clicked outside of this component!!!');
        }}
      >
        Hello World
      </OutsideClickHandler> */}
      <div className="">
        <Box>
          {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Students" {...a11yProps(0)} />

            </Tabs>



          </Box> */}

          {/* Individual */}
          <TabPanel TabPanel value={value} index={0}>
            {/* < IndividualSchedule />  */}

            {Userrole?.role != "School principal" && <div className="download_graded_consolidated_btn">
              <label htmlFor="">Consolidated graded assessment report - </label>
              <button
                className="student_list_download_btn"

                onClick={DownloadHandle}
              >
                Download <i class="fa-solid fa-download"></i>
              </button>
            </div>}

            <Search>
              <SearchIconWrapper>
                <h2>
                  <i class="fas fa-search"></i>
                </h2>
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                onInput={(e) => {
                  HandleSearch(e.target.value);
                }}
                inputRef={searchRef}
              />
            </Search>

            <div class="tableContainer">
              <div class="ltr">
                <div class="">
                  <table class="mytable">
                    <thead>
                      <tr>
                        <th className="studID">Student ID</th>
                        <th className="studName">Student Name</th>
                        <th className="parentName">Parent Name</th>
                        <th className="parentName">School Name</th>
                        <th className="emailID">Email ID</th>
                        <th className="mobile">Mobile</th>
                        {Userrole?.role != "School principal" ? <th className="dropdown_container stage">
                          <OutsideClickHandler
                            onOutsideClick={() => {
                              if (valueDropdown == 1) {
                                console.log("true");
                                setDropdown(true);
                                setvalueDropdown(0);
                              } else {
                                console.log("false");
                                setDropdown(false);
                              }
                            }}
                            className="DropDown_Outside"
                          >
                            <p
                              className="filterName"
                              onClick={() => toggleDropdown(1)}
                            >
                              Stage<i class="fas fa-filter"></i>
                            </p>

                            <div
                              className={
                                dropdown
                                  ? "dropDownActive"
                                  : valueDropdown == 1
                                    ? "dropdownList"
                                    : "dropDownActive"
                              }
                            >
                              {ChildFilterStage?.map((item, index) => {
                                return (
                                  <div
                                    className="input_container"
                                    key={index}
                                    ref={DropDown}
                                  >
                                    <input
                                      type="checkbox"
                                      id={item.id}
                                      className="DD_Input"
                                      name={item.id}
                                      value={item.id}
                                      onChange={stageFilter}
                                    />

                                    <label
                                      htmlFor={item.id}
                                      className="DD_Label"
                                    >
                                      <i class="fa-solid fa-check"></i>
                                      <p>{item.name}</p>
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                          </OutsideClickHandler>
                        </th> : <th className="mobile">Stage</th>}

                        <th className="source">Source</th>
                        {/* <th className="std">Standard</th> */}
                        <th className="dropdown_container std">
                          <OutsideClickHandler
                            onOutsideClick={() => {
                              if (valueDropdown == "2") {
                                setDropdown(true);
                                setvalueDropdown(0);
                              }
                            }}
                          >
                            <p
                              className="filterName"
                              onClick={() => toggleDropdown(2)}
                            >
                              Standard<i class="fas fa-filter"></i>
                            </p>

                            <div
                              className={
                                dropdown
                                  ? "dropDownActive"
                                  : valueDropdown == 2
                                    ? "dropdownList"
                                    : "dropDownActive"
                              }
                              ref={DropDown}
                            >
                              <div className="input_container">
                                <input
                                  type="checkbox"
                                  id="1std"
                                  className="DD_Input std_dp"
                                  name="1std"
                                  value={"1"}
                                  onChange={standard_filter}
                                />

                                <label htmlFor="1std" className="DD_Label">
                                  <i class="fa-solid fa-check"></i>
                                  <p>I STD</p>
                                </label>
                              </div>
                              <div className="input_container">
                                <input
                                  type="checkbox"
                                  id="2std"
                                  className="DD_Input std_dp"
                                  value={"2"}
                                  name="2std"
                                  onChange={standard_filter}
                                />

                                <label htmlFor="2std" className="DD_Label">
                                  <i class="fa-solid fa-check"></i>
                                  <p>II STD</p>
                                </label>
                              </div>
                              <div className="input_container">
                                <input
                                  type="checkbox"
                                  id="3std"
                                  name="3std"
                                  onChange={standard_filter}
                                  className="DD_Input std_dp"
                                  value={"3"}
                                />
                                <label htmlFor="3std" className="DD_Label">
                                  <i class="fa-solid fa-check"></i>
                                  <p>III STD</p>
                                </label>
                              </div>
                              <div className="input_container">
                                <input
                                  type="checkbox"
                                  id="4std"
                                  className="DD_Input std_dp"
                                  name="4std"
                                  onChange={standard_filter}
                                  value={"4"}
                                />
                                <label htmlFor="4std" className="DD_Label">
                                  <i class="fa-solid fa-check"></i>
                                  <p>IV STD</p>
                                </label>
                              </div>
                              <div className="input_container">
                                <input
                                  type="checkbox"
                                  id="5std"
                                  className="DD_Input std_dp"
                                  onChange={standard_filter}
                                  name="5std"
                                  value={"5"}
                                />
                                <label htmlFor="5std" className="DD_Label">
                                  <i class="fa-solid fa-check"></i>
                                  <p>V STD</p>
                                </label>
                              </div>
                            </div>
                          </OutsideClickHandler>
                        </th>
                        <th className="createdOn">Created on</th>
                        <th className="action">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {studentTableStateData?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td title={item.child_id}>{item.child_id}</td>
                            <td title={item.child_name}>{item.child_name}</td>
                            <td title={item.parent_name}>{item.parent_name}</td>
                            <td title={item.school_name}>{item.school_name}</td>
                            <td title={item.parent_email}>
                              {item.parent_email}
                            </td>
                            <td title={item.mobile_no}>{item.mobile_no}</td>
                            <td title={item.child_status}>
                              {item.child_status}
                            </td>
                            <td>{item.refferal}</td>
                            <td>{item.class_section_name}</td>
                            <td>{item.child_created_on}</td>
                            {Userrole?.role != "School principal" ? <td
                              onClick={() => {
                                localStorage.setItem("childID", item.child_id);
                                localStorage.setItem(
                                  "childName",
                                  item.child_name
                                );
                                props.setBreadcrumItem([
                                  {
                                    name: "Student List",
                                    link: "/",
                                  },
                                  {
                                    name: item.child_name,
                                    link: `/child_details/${btoa(
                                      item.child_name
                                    )}/${btoa(item.child_id)}`,
                                  },
                                ]);
                              }}
                            >
                              <NavLink

                                to={`/child_details/${btoa(
                                  item.child_name
                                )}/${btoa(item.child_id)}`}
                              >
                                <i class="fas fa-eye"></i>
                              </NavLink>
                            </td> : <td
                              onClick={() => {
                                localStorage.setItem("childID", item.child_id);
                                localStorage.setItem(
                                  "childName",
                                  item.child_name
                                );
                                props.setBreadcrumItem([
                                  {
                                    name: "Student List",
                                    link: "/",
                                  },
                                  {
                                    name: item.child_name,
                                    link: `/gradeddetails/${btoa(
                                      item.child_name
                                    )}/${btoa(item.child_id)}`,
                                  },
                                ]);
                              }}
                            >
                              <NavLink

                                to={`/gradeddetails/${btoa(
                                  item.child_name
                                )}/${btoa(item.child_id)}`}
                              >
                                <i class="fas fa-eye"></i>
                              </NavLink>
                            </td>}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* <ReactPaginate
              breakLabel="..."
              nextLabel="Next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={4}
              pageCount={Count}
              previousLabel="< Prev"
              renderOnZeroPageCount={null}
              marginPagesDisplayed={2}
              pageClassName="paginationLi"
              pageLinkClassName="paginationLink"
              previousClassName="prevBtn"
              nextClassName="nextBtn"
              previousLinkClassName="prevLink"
              nextLinkClassName="nextLink"
              breakClassName="dots"
              disabledClassName="disabled"
              disabledLinkClassName="disableBtn"
              className="pagination"
              activeLinkClassName="pagLi"
            /> */}
            <Pagination
              activePage={pg_count}
              itemsCountPerPage={10}
              totalItemsCount={Count}
              pageRangeDisplayed={10}
              itemClass="paginationLi_new"
              // pageClassName="paginationLi"
              // pageLinkClassName="paginationLink"
              // previousClassName="prevBtn"
              // nextClassName="nextBtn"
              // previousLinkClassName="prevLink"
              // nextLinkClassName="nextLink"
              // breakClassName="dots"
              // disabledClassName="disabled"
              // disabledLinkClassName="disableBtn"
              // className="pagination"
              prevPageText="Prev"
              nextPageText="Next"
              activeLinkClass="pagLi_sc"
              activeClass="pagLi_sc"
              itemClassFirst="go_first"
              itemClassLast="go_first"
              itemClassPrev="prev_btn"
              itemClassNext="next_btn"
              onChange={(e) => {
                handlePageClick(e);
              }}
            />
          </TabPanel>

          {/* Group */}
          <TabPanel TabPanel value={value} index={1}>
            <div class="tableContainer">
              <div class="ltr">
                <div class="">
                  <table class="mytable">
                    <thead>
                      <tr>
                        <th>SNo</th>
                        {/* <th>School ID</th> */}
                        <th>School Name</th>
                        <th>School City</th>
                        {/* <th>Action</th> // later */}
                      </tr>
                    </thead>
                    <tbody>
                      {schoolTableStateData?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            {/* <td>{item.school_id}</td> */}
                            <td>{item.school_name}</td>
                            <td>{item.school_city}</td>
                            {/* <td onClick={() => {
                                                                localStorage.setItem('childID', item.child_id)
                                                            }}>

                                                                <Link to='/child_enrollment_payment'>
                                                                    <i class="fas fa-eye"></i>
                                                                </Link>

                                                            </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </TabPanel>
        </Box>
      </div>

      {/* <div className="create_schedule_btns_holder">
                <button className='btn btn1'>Cancel</button>
                <button className='btn btn2'>Create Schedule</button>
            </div> */}
    </div>
  );
}
