import React from "react";

const Header = ({ ActiveStep, setActiveStep, SeletedRole }) => {
  return (
    <div className="add_enabler_tab_switch">
      <button
        className={ActiveStep == 1 ? "active" : ""}
        onClick={() => {
          setActiveStep(1);
        }}
      >
        1.General details
      </button>
      <button
        className={ActiveStep == 2 ? "active" : ""}
        onClick={() => {
          setActiveStep(2);
        }}
      >
        2.About yourself
      </button>
      <button
        className={ActiveStep == 3 ? "active" : ""}
        onClick={() => {
          setActiveStep(3);
        }}
      >
        3.Assign role
      </button>
      {SeletedRole != "School enabler" &&
        SeletedRole != "School co-ordinater" && (
          <button
            className={ActiveStep == 4 ? "active" : ""}
            disabled={
              SeletedRole == "School enabler" ||
              SeletedRole == "School co-ordinater"
                ? true
                : false
            }
            onClick={() => {
              setActiveStep(4);
            }}
          >
            4.Schedule
          </button>
        )}
    </div>
  );
};

export default Header;
