import React, { useEffect, useState } from "react";
import "./Parentdashboard.css";
import LineChart from "../../../components/Graph/Line/Linechart";
import Donut from "../../../components/Graph/Donut/Donut";
import Pie from "../../../components/Graph/Pie/Pie";
import Funnel from "../../../components/Graph/Funnel/Funnel";
import EnrollDonut from "../../../components/Graph/Enroll/EnrollDonut";
import { URL as UR } from "../../../DROPDOWNDATA";
import FileSaver from "file-saver";
import Loader from "../../../components/Loader/Loader";
const Parentdashboard = () => {
  const authKey = "Bearer " + localStorage.getItem("loggedToken");
  const [FilterValue, setFilterValue] = useState({
    category: 4,
    date: new Date().toISOString().split("T")[0],
  });
  const [isLoading, setisLoading] = useState(false);
  const [LinechartXaxis, setLinechartXaxis] = useState([
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]);
  const [PieChartXaxis, setPieChartXaxis] = useState([
    "Grade 1",
    "Grade 2",
    "Grade 3",
    "Grade 4",
    "Grade 5",
    "Grade 6",
    "Grade 7",
    "Grade 8",
  ]);
  const [ChartData, setChartData] = useState();

  const [FunnelType, setFunnelType] = useState(1);

  const filterBy_category = (e) => {
    setFilterValue(FilterValue, (FilterValue.category = e.target.value));

    if (e.target.value == 1) {
      setLinechartXaxis([
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24,
      ]);
    } else if (e.target.value == 2) {
      setLinechartXaxis(["Mon", "Tue", "Wed", "Thr", "Fri", "Sat", "Sun"]);
    } else if (e.target.value == 3) {
      setLinechartXaxis([
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ]);
    } else if (e.target.value == 4) {
      setLinechartXaxis([
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ]);
    }
  };
  const filterBy_date = (e) => {
    setFilterValue(FilterValue, (FilterValue.date = e.target.value));
  };
  let dashboard_filter_select = document.querySelectorAll(
    "#dashboard_filter_select option"
  );

  async function getChartData(value) {
    let data;
    setisLoading(true);
    await fetch(`${UR}/get_overall_data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authKey,
      },
      body: JSON.stringify((data = { fetch_date: value })),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        let PiechartArray = [];
        for (let i = 0; i < obj.body?.grade?.max_std; i++) {
          PiechartArray.push(`Grade ${i + 1}`);
        }
        setPieChartXaxis(PiechartArray);
        setChartData(obj.body);
        setisLoading(false);

        dashboard_filter_select?.forEach((item) => {
          item.removeAttribute("selected");

          if (item.getAttribute("value") == FilterValue.category) {
            item.setAttribute("selected", true);
          }
        });
      });
  }
  async function Downloadreport() {
    let data;
    let startDate =
      FilterValue.category == 1
        ? ChartData?.dates?.daily_data?.sd
        : FilterValue.category == 2
        ? ChartData?.dates?.weekly_data?.sd
        : FilterValue.category == 3
        ? ChartData?.dates?.monthly_data?.sd
        : FilterValue.category == 4
        ? ChartData?.dates?.yearly_data?.sd
        : "";
    let updatestartdate = "";
    if (startDate.includes("-")) {
      updatestartdate = startDate?.split("-")?.reverse()?.join("-");
    }
    let EndDate =
      FilterValue.category == 1
        ? ""
        : FilterValue.category == 2
        ? ChartData?.dates?.weekly_data?.ed
        : FilterValue.category == 3
        ? ChartData?.dates?.monthly_data?.ed
        : FilterValue.category == 4
        ? ChartData?.dates?.yearly_data?.ed
        : "";
    let updateEnddate = "";
    if (EndDate.includes("-")) {
      updateEnddate = EndDate?.split("-")?.reverse()?.join("-");
    }
    setisLoading(true);
    await fetch(`${UR}/overview_download`, {
      method: "POST",
      headers: {
        Authorization: authKey,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(
        (data = {
          start_date: updatestartdate,
          end_date: updateEnddate,
        })
      ),
    })
      .then((r) => r.blob())
      .then((obj) => {
        setisLoading(false);
        FileSaver.saveAs(obj, "fime.xlsx");

        setisLoading(false);
      })
      .catch((err) => {
        setisLoading(false);
      });
  }

  useEffect(() => {
    getChartData(new Date().toISOString().split("T")[0]);
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="dashboard_coatiner">
      <div className="date_range">
        {FilterValue.category == 1 ? `${ChartData?.dates?.daily_data?.sd}` : ""}
        {FilterValue.category == 2
          ? `${ChartData?.dates?.weekly_data?.sd} To ${ChartData?.dates?.weekly_data?.ed}`
          : ""}
        {FilterValue.category == 3
          ? `${ChartData?.dates?.monthly_data?.sd} To ${ChartData?.dates?.monthly_data?.ed}`
          : ""}
        {FilterValue.category == 4
          ? `${ChartData?.dates?.yearly_data?.sd} To ${ChartData?.dates?.yearly_data?.ed}`
          : ""}
      </div>

      <div className="dashboard_filter">
        <select
          name=""
          id="dashboard_filter_select"
          defaultValue={FilterValue.category}
          onChange={(e) => filterBy_category(e)}
        >
          <option value="1">Day</option>
          <option value="2">Weekly</option>
          <option value="3">Monthly</option>
          <option value="4">Yearly</option>
        </select>
        <input
          type="date"
          name=""
          id=""
          defaultValue={FilterValue.date}
          onChange={(e) => filterBy_date(e)}
        />
        <button
          onClick={() => {
            getChartData(FilterValue.date);
          }}
        >
          Get result
        </button>
        <button
          onClick={() => {
            Downloadreport();
          }}
        >
          Download report
        </button>
      </div>
      <div className="line_dont_container">
        <div className="donut_pie_container">
          {ChartData?.source && (
            <Donut
              overAll={
                FilterValue.category == 1
                  ? ChartData?.source?.daily_data
                  : FilterValue.category == 2
                  ? ChartData?.source?.weekly_data
                  : FilterValue.category == 3
                  ? ChartData?.source?.monthly_data
                  : FilterValue.category == 4
                  ? ChartData?.source?.yearly_data
                  : []
              }
              total_count={
                FilterValue.category == 1
                  ? ChartData?.source?.overall_daily_count
                  : FilterValue.category == 2
                  ? ChartData?.source?.overall_weekly_count
                  : FilterValue.category == 3
                  ? ChartData?.source?.overall_monthly_count
                  : FilterValue.category == 4
                  ? ChartData?.source?.overall_yearly_count
                  : 0
              }
            />
          )}
          {ChartData?.grade && (
            <Pie
              FilterValue={FilterValue}
              labelData={PieChartXaxis}
              ChartData={
                FilterValue.category == 1
                  ? ChartData?.grade?.daily_data
                  : FilterValue.category == 2
                  ? ChartData?.grade?.weekly_data
                  : FilterValue.category == 3
                  ? ChartData?.grade?.monthly_data
                  : FilterValue.category == 4
                  ? ChartData?.grade?.yearly_data
                  : ChartData?.grade?.yearly_data
              }
            />
          )}
          {ChartData?.intervension_enrolled && (
            <EnrollDonut
              FilterValue={FilterValue}
              Label={["Course completed", "Child suspended", "Active child"]}
              data={
                FilterValue.category == 1
                  ? ChartData?.intervension_enrolled?.monthly_data?.count
                  : FilterValue.category == 2
                  ? ChartData?.intervension_enrolled?.monthly_data?.count
                  : FilterValue.category == 3
                  ? ChartData?.intervension_enrolled?.monthly_data?.count
                  : FilterValue.category == 4
                  ? ChartData?.intervension_enrolled?.yearly_data?.count
                  : ChartData?.intervension_enrolled?.yearly_data?.count
              }
              total_count={
                FilterValue.category == 1
                  ? ChartData?.intervension_enrolled?.monthly_data
                      ?.overall_enrollment
                  : FilterValue.category == 2
                  ? ChartData?.intervension_enrolled?.monthly_data
                      ?.overall_enrollment
                  : FilterValue.category == 3
                  ? ChartData?.intervension_enrolled?.monthly_data
                      ?.overall_enrollment
                  : FilterValue.category == 4
                  ? ChartData?.intervension_enrolled?.yearly_data
                      ?.overall_enrollment
                  : ChartData?.intervension_enrolled?.yearly_data
                      ?.overall_enrollment
              }
            />
          )}
        </div>
        <LineChart
          FilterValue={FilterValue}
          labelData={LinechartXaxis}
          ChartData={
            FilterValue.category == 1
              ? ChartData?.registration?.daily_data
              : FilterValue.category == 2
              ? ChartData?.registration?.weekly_data
              : FilterValue.category == 3
              ? ChartData?.registration?.monthly_data
              : FilterValue.category == 4
              ? ChartData?.registration?.yearly_data
              : []
          }
        />
      </div>

      <div className="pie_funnel_container">
        <div className="conversion_select">
          <p>Conversion</p>
          <select name="" id="" onChange={(e) => setFunnelType(e.target.value)}>
            <option value="1">Direct child</option>
            <option value="2">School child</option>
          </select>
        </div>
        <Funnel
          Funneldata={
            FilterValue.category == 1
              ? ChartData?.conversion[
                  FunnelType == 1 ? "direct_child" : "school_child"
                ].daily_data
              : FilterValue.category == 2
              ? ChartData?.conversion[
                  FunnelType == 1 ? "direct_child" : "school_child"
                ].weekly_data
              : FilterValue.category == 3
              ? ChartData?.conversion[
                  FunnelType == 1 ? "direct_child" : "school_child"
                ].monthly_data
              : FilterValue.category == 4
              ? ChartData?.conversion[
                  FunnelType == 1 ? "direct_child" : "school_child"
                ].yearly_data
              : []
          }
        />
      </div>
    </div>
  );
};

export default Parentdashboard;
