import React, { useEffect, useState } from "react";
import "./sidebarList.css";
import { Autocomplete, TextField } from "@mui/material";
import useSearch from "../../../customHooks/useSearch";
import { NavLink } from "react-router-dom";
import { URL } from "../../../DROPDOWNDATA";
function PendingChildren({ childDetail }) {
    return (
        <div className="list-holder">
            <div className="avatar"></div>

            <div className="name">
                <p>{childDetail.child_name}</p>
            </div>

            <div className="value">
                <p>{childDetail.child_view_id}</p>
            </div>
        </div>
    );
}

function AvaliableEnablers({ enablerDetail }) {
    return (
        <div className="list-holder">
            <div className="avatar"></div>

            <div className="name">
                <p>{enablerDetail.users_firstname}</p>
                {/* <span>9:00 am - 13:00 pm</span> */}
            </div>

            <div className="value">
                {/* <p>Mon - Fri</p> */}
            </div>
        </div>
    );
}
// let URL = "http://20.120.84.12:5588";
export default function SidebarList() {
    const [EnablerSearch_Data, setEnablerSearch_Data, getEnabler_Search] = useSearch()
    const [pendingChild, setPendingChild] = useState([]);
    const [pendingEnablers, setPendingEnablers] = useState([]);

    const authKey = "Bearer " + localStorage.getItem("loggedToken");
    async function Get_initial_Enabler_and_childDAta() {
        await fetch(URL + "/pending_child_enabler_list", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: authKey,
            },
        })
            .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
            .then((obj) => {
                // setHolidaylist(obj.body);
                // setIsloading(false);
                console.log(obj.body);
                setPendingChild(obj.body.child_list);
                setPendingEnablers(obj.body.enabler_list);
            });
    }
    useEffect(() => {
        Get_initial_Enabler_and_childDAta();
        getEnabler_Search("")
        console.log(pendingChild);
    }, []);
    const SearchEnabler = (event) => {
        if (event.target.value != "") {
            getEnabler_Search(event.target.value)
            setPendingEnablers(EnablerSearch_Data)
        } else {
            Get_initial_Enabler_and_childDAta();
        }
    };

    const SearchChild = (value) => {
        let data;
        async function Search_childData(value) {
            await fetch(URL + "/child_name_filter", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: authKey,
                },
                body: JSON.stringify((data = { child_name_filter: value })),
            })
                .then((r) =>
                    r.json().then((data) => ({ status: r.status, body: data }))
                )
                .then((obj) => {
                    console.log(obj.body);
                    setPendingChild(obj.body);
                });
        }
        Search_childData(value);
    };
    return (
        <>
            <p className="heading">Avaliable Children</p>

            <TextField
                label="Search Child"
                fullWidth
                className="enablerInput"
                onInput={(event) => SearchChild(event.target.value)}
            />

            <div className="pending_Child_container">
                {pendingChild?.map((item, index) => {
                    return <PendingChildren key={index} childDetail={item} />;
                })}
            </div>
            <div>
                <p className="heading">Avaliable Enablers</p>

                <TextField
                    fullWidth
                    label="Search Enabler"
                    className="enablerInput"
                    onInput={(value) => SearchEnabler(value)}
                />

                <div className="pending_enabler_container">
                    {EnablerSearch_Data?.map((item, index) => {
                        return <AvaliableEnablers key={index} enablerDetail={item} />;
                    })}
                </div>
            </div>

            <div className="view-schedule-btn-holder">
                <NavLink to={"/scheduleList/0/all/1"} className="btn btn2">View Schedule</NavLink>
            </div>
        </>
    );
}
