import { TextField } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";

const GenerateLink = ({
  setOpenGenerateModal,
  copyLink = "invalid URL",
  id,
  childname,
}) => {
  return (
    <div className="generator_link_center">
      <h2>Payment Link</h2>
      <div className="url_container">
        <TextField
          autoComplete="off"
          id="outlined-basic"
          label="Payment Link"
          variant="outlined"
          value={copyLink}
          InputProps={{
            readOnly: true,
          }}
        />

        <button
          onClick={() => {
            if (window.location.protocol != "http:") {
              navigator.clipboard.writeText(copyLink);
              toast.success("Link Copied");
              toast.clearWaitingQueue();
            } else {
              toast.warn("you are using HTTP server,please copy it manually");
              toast.clearWaitingQueue();
            }
          }}
        >
          Copy
        </button>
      </div>

      <NavLink
        to={`/child_enrollment_payment/${childname}/${id}`}
        onClick={() => {
          setOpenGenerateModal(false);
          window.location.reload();
        }}
      >
        Ok
      </NavLink>
    </div>
  );
};

export default GenerateLink;
