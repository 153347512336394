import React, { useState, useEffect } from "react";
import DataTable from "../../components/DataTable/DataTable";
import SidebarInsights from "./SidebarInsights/SidebarInsights";
import "./viewSchedule.css";
import Pagination from "react-js-pagination";
import Manageparentlist from "./Manageparentlist/Manageparentlist";
import Manageparentchild from "./ManageparentChild/Manageparentchild";

export default function ViewSchedule(props) {
  const [ManageparentView, setManageparentView] = useState(true);
  const [ParentDetails, setParentDetails] = useState({
    id: "",
    name: "",
    mobile_no: "",
    alt_no: "",
    email: "",
    date: "",
    no_child: "",
  });
  useEffect(() => {
    props.setBreadcrumItem([{ name: "", link: `/viewSchedule` }]);
  }, []);
  return (
    <div>
      <div className="manage_parent_breadcrumb">
        <button
          onClick={() => {
            setParentDetails({
              id: "",
              name: "",
              mobile_no: "",
              alt_no: "",
              email: "",
              date: "",
              no_child: "",
            });
            setManageparentView(true);
          }}
        >
          All parent
        </button>
        {ParentDetails?.name != "" && (
          <span>
            <i class="fa-solid fa-angle-right"></i>
          </span>
        )}
        <button>{ParentDetails?.name}</button>
      </div>

      {ManageparentView ? (
        <Manageparentlist
          setManageparentView={setManageparentView}
          setParentDetails={setParentDetails}
          ParentDetails={ParentDetails}
        />
      ) : (
        <Manageparentchild ParentDetails={ParentDetails} />
      )}
    </div>
  );
}
