import React, { useEffect, useState } from "react";
import "./dashboard.css";
import Parentdashboard from "./ParentDashboard/Parentdashboard";
import Screening from "./Screening/Screening";
import Vakt from "./VAKT/Vakt";
import { useSelector } from "react-redux";
import Schooldashboard from "../SchoolDashboard/Schooldashboard";
import Mircosoft from "./Microsoft/Mircosoft";

const Dashboard = (props) => {
  const UserRole = useSelector((store) => store.privilege);
  const [ActiveScreen, setActiveScreen] = useState(
    UserRole.role == "DL Admin" ? 2 : 3
  );

  useEffect(() => {
    props.setBreadcrumItem([{ name: "Dashboard", link: `/` }, ,]);
    localStorage.setItem("childID", "");
  }, []);
  return (
    <div className="dashboard_overall">
      <div className="dashboard_tab_switcher">
        <button
          style={{
            backgroundColor: ActiveScreen == 1 ? "#007872" : "#f3fcf9",
            color: ActiveScreen == 1 ? "white" : "#007872",
          }}
          onClick={() => {
            setActiveScreen(1);
          }}
        >
          {UserRole.role == "schooladmin" ? "School" : "DL Parent"}
        </button>
        {UserRole.role == "DL Admin" && (
          <button
            onClick={() => {
              setActiveScreen(2);
            }}
            style={{
              backgroundColor: ActiveScreen == 2 ? "#007872" : "#f3fcf9",
              color: ActiveScreen == 2 ? "white" : "#007872",
            }}
          >
            Microsoft
          </button>
        )}
        <button
          onClick={() => {
            setActiveScreen(3);
          }}
          style={{
            backgroundColor: ActiveScreen == 3 ? "#007872" : "#f3fcf9",
            color: ActiveScreen == 3 ? "white" : "#007872",
          }}
        >
          Screening
        </button>
        <button
          onClick={() => {
            setActiveScreen(4);
          }}
          style={{
            backgroundColor: ActiveScreen == 4 ? "#007872" : "#f3fcf9",
            color: ActiveScreen == 4 ? "white" : "#007872",
          }}
        >
          VAKT
        </button>
      </div>
      {UserRole.role == "schooladmin"
        ? ActiveScreen == 1 && <Schooldashboard />
        : ActiveScreen == 1 && <Parentdashboard />}
      {/* {ActiveScreen == 1 && <Parentdashboard />} */}
      {ActiveScreen == 2 && <Mircosoft />}
      {ActiveScreen == 3 && <Screening />}
      {ActiveScreen == 4 && <Vakt />}
    </div>
  );
};

export default Dashboard;
