import React, { useEffect, useState } from "react";
import "./Manageparentlist.css";
import Pagination from "react-js-pagination";
import { ToastContainer, toast } from "react-toastify";
import { URL } from "../../../DROPDOWNDATA";
import FileSaver from "file-saver";
const Manageparentlist = ({
  setManageparentView,
  setParentDetails,
  ParentDetails,
}) => {
  const [MangaparentData, setMangaparentData] = useState();
  // PAGINATION
  const [pg_count, setPg_count] = useState(1);
  //   search
  const [Searchinput, setSearchinput] = useState("");
  // start date and end date
  const [Filterdate, setFilterdate] = useState({
    start_date: "",
    end_date: "",
  });

  //   PARENT ID AND NAME

  const [Showfilter, setShowfilter] = useState(false);

  async function Get_manage_parents() {
    let item = {
      search: Searchinput,
      start_date: Filterdate?.start_date,
      end_date: Filterdate?.end_date,
      download_report: 0,
    };

    await fetch(`${URL}/manage_parents?page_no=${pg_count}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(item),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        console.log(obj);
        setMangaparentData(obj.body);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  }
  const Parentchild_handle = (data) => {
    setParentDetails({
      id: data?.Users_id,
      name: data?.parent_name,
      mobile_no: data?.Users_Mobile,
      alt_no: data?.Users_Alternate_Mobile,
      email: data?.Users_Email,
      date: data?.Users_created_on,
      no_child: data?.child_count,
    });
    setManageparentView(false);
  };

  const Downloadreport = async () => {
    let item = {
      search: Searchinput,
      start_date: Filterdate?.start_date,
      end_date: Filterdate?.end_date,
      download_report: 1,
    };

    await fetch(`${URL}/manage_parents?page_no=${pg_count}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(item),
    })
      .then((r) => r.blob())
      .then((obj) => {
        FileSaver.saveAs(obj, "Parentlist.xlsx");
      })
      .catch((err) => {
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  };

  const getFilter = () => {
    Get_manage_parents();
    setShowfilter(false);
  };
  useEffect(() => {
    Get_manage_parents();
  }, [pg_count, Searchinput]);

  return (
    <div>
      <div className="manage_parent_search">
        <input
          type="text"
          placeholder="search"
          defaultValue={Searchinput}
          onInput={(e) => {
            // console.log(e.target.value);
            setPg_count(1);
            setSearchinput(e.target.value);
          }}
        />

        <button
          onClick={() => {
            setShowfilter(!Showfilter);
          }}
        >
          Filter
        </button>
        <button onClick={Downloadreport}>Download now</button>

        {Showfilter && (
          <div className="manage_parent_filter_date">
            <div className="manage_date_input">
              <div>
                <label htmlFor="">From </label>
                <input
                  type="date"
                  name=""
                  id=""
                  max={new Date().toISOString().split("T")[0]}
                  value={Filterdate?.start_date}
                  onChange={(e) => {
                    setFilterdate({
                      ...Filterdate,
                      start_date: e.target.value,
                    });
                  }}
                />
              </div>
              <div>
                <label htmlFor="">To </label>
                <input
                  type="date"
                  name=""
                  id=""
                  max={new Date().toISOString().split("T")[0]}
                  value={Filterdate?.end_date}
                  onChange={(e) => {
                    setFilterdate({
                      ...Filterdate,
                      end_date: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            {Filterdate?.start_date != "" && Filterdate?.end_date != "" && (
              <button onClick={getFilter}>Done</button>
            )}

            {/* <button
              onClick={() => {
                setFilterdate({
                  start_date: "",
                  end_date: "",
                });
                setTimeout(() => {
                  Get_manage_parents();
                }, 1000);
              }}
            >
              Clear
            </button> */}
          </div>
        )}
      </div>

      <table className="direct_parent_table">
        <thead>
          <tr>
            <th>S.no</th>
            <th>Parent Name</th>
            <th>Mobile Number</th>
            <th>Alternate no</th>
            <th>Email</th>
            <th>Created on</th>
            <th>no.of child</th>
          </tr>
        </thead>
        <tbody>
          {MangaparentData?.parent_list?.map((item, index) => {
            return (
              <tr key={index}>
                <td>{(pg_count - 1) * 10 + (index + 1)}</td>
                <td
                  onClick={() => Parentchild_handle(item)}
                  className="parent_child_btn"
                >
                  {item?.parent_name}
                </td>
                <td>{item?.Users_Mobile}</td>
                <td>{item?.Users_Alternate_Mobile}</td>
                <td>{item?.Users_Email}</td>
                <td>{item?.Users_created_on}</td>
                <td>{item?.child_count}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <Pagination
        activePage={pg_count}
        itemsCountPerPage={10}
        totalItemsCount={MangaparentData?.count}
        pageRangeDisplayed={10}
        itemClass="paginationLi_new"
        // pageClassName="paginationLi"
        // pageLinkClassName="paginationLink"
        // previousClassName="prevBtn"
        // nextClassName="nextBtn"
        // previousLinkClassName="prevLink"
        // nextLinkClassName="nextLink"
        // breakClassName="dots"
        // disabledClassName="disabled"
        // disabledLinkClassName="disableBtn"
        // className="pagination"
        prevPageText="Prev"
        nextPageText="Next"
        activeLinkClass="pagLi_sc"
        activeClass="pagLi_sc"
        itemClassFirst="go_first"
        itemClassLast="go_first"
        itemClassPrev="prev_btn"
        itemClassNext="next_btn"
        onChange={(e) => {
          setPg_count(e);
        }}
      />
    </div>
  );
};

export default Manageparentlist;
