import React from "react";

const Complete = ({ item, Actions_btn, Showcheckbox_modal }) => {
  const pendingCheckbox = (e) => {
    let Strike_out =
      e.target.parentElement.parentElement.querySelector(".pending_title h2");

    if (e.target.checked) {
      Actions_btn("approved", item?.si_no, "");
    } else {
      Actions_btn("pending", item?.si_no, "");
    }
  };
  return (
    <div className="pending_list_block">
      <div className="pending_checkbox">
        <input
          type="checkbox"
          name="pending"
          id={`pending${item?.si_no}`}
          checked={true}
          onChange={(e) => {
            pendingCheckbox(e);
          }}
        />
        <label htmlFor={`pending${item?.si_no}`}>Done</label>
      </div>
      <div className="pending_heading">
        <div className="pending_title">
          <h2>{item?.notification_name}</h2>
          <p>{item?.created_date}</p>
          <p className="dot">
            <i className="fa-solid fa-circle"></i>
          </p>
          <p>{item?.created_time}</p>
        </div>

        <div className="pending_child">
          {item?.group_id != null ? (
            <p>
              <span className="label">Group</span> : {item?.group_name}
            </p>
          ) : (
            <p>
              <span className="label">Child</span> : {item?.child_name}
            </p>
          )}
          <p className="dot">
            <i className="fa-solid fa-circle"></i>
          </p>
          <p>
            <span className="label">Requested by {item?.user_role}</span> : {item?.username}
          </p>
        </div>
      </div>

      <div className="task_completed_status">
        <p>{item?.notification_status}</p>
      </div>
    </div>
  );
};

export default Complete;
