import React from "react";
import "./Schooldashboard.css";

const Schooldashboard = () => {
  return (
    <div className="school_dashboard">
      <h2>/\ School dashboard coming soon /\</h2>
    </div>
  );
};

export default Schooldashboard;
