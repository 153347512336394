import React, { useState, useEffect } from "react";
import { URL } from "../DROPDOWNDATA";
const useSearch = () => {
  const authKey = "Bearer " + localStorage.getItem("loggedToken");
  let data;
  const [EnablerSearch_Data, setEnablerSearch_Data] = useState(null);
  async function getEnabler_Search(value) {
    await fetch(URL + "/enabler_search", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authKey,
      },
      body: JSON.stringify((data = { search: value })),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        setEnablerSearch_Data(obj.body);
      });
  }

  //   useEffect(() => {
  //     getEnabler_Search(value);
  //   }, [getEnabler_Search(value)]);
  return [EnablerSearch_Data, setEnablerSearch_Data, getEnabler_Search];
};

export default useSearch;
