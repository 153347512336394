import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { TextField } from "@mui/material";
import Switch from "react-switch";
import React, { useEffect, useState, useRef } from "react";
import "./enrollmentPayment.css";
import DataTable from "../../../components/DataTable/DataTable";
import TopMenu from "../topMenu/TopMenu";
import { NavLink } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
// import planImage from '../../../images/planGuide.png';

import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import CourseNameComponent from "./EnrollmentComponents/CoursesList";
import useEnrollment from "../../../customHooks/useEnrollment";
import GenerateLink from "./EnrollmentComponents/GenerateLink";
import { URL } from "../../../DROPDOWNDATA";
import { useParams, useNavigate } from "react-router-dom";
// Resusable function

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

function addMonths(date, months) {
  return new Date(date.setMonth(date.getMonth() + months));
}

// getting num of days between two dates
function getDaysBetweenMonths(date1, date2) {
  // let mydate1 = new Date(date1)
  // let mydate2 = new Date(date2)

  let mydate1 = new Date(formatDate(date1));
  let mydate2 = new Date(formatDate(date2));

  let diff = mydate2.getTime() - mydate1.getTime();
  let msInDay = 1000 * 3600 * 24;

  // console.log(diff / msInDay)

  return diff / msInDay;
}

// end of ---  getting num of days between two dates
//------- End of Resusable function

export default function EnrollmentPayment(props) {
  const { id, childname } = useParams();
  const History = useNavigate();
  const [
    loader,
    setLoader,
    AddTransaction,
    setAddTranaction,
    DiscountShow_instant,
    setDiscountShow_instant,
    enrollmentID,
    setEnrollmentID,
    enrollmentUserState,
    setEnrollmentUserState,
    payInFullTransactionList,
    setPayInFullTransactionList,
    errorFieldMessages,
    setErrorFieldMessages,
    transactionDetailsPayInfull,
    setTransactionDetailsPayInfull,
    togglePlanGuide,
    setTogglePlanGuide,
    toggleDiscountRemarks,
    setToggleDiscountRemarks,
    isDiscountApplied,
    setDiscountApplied,
    endDateValue,
    setEndDate,
    startDateValue,
    setStartDate,
    currentInstallmentValues,
    setCurrentInstallmentValues,
    course_details_list,
    setCourse_details_list,
    OpenGenerateModal,
    setOpenGenerateModal,
    copyLink,
    setcopyLink,
  ] = useEnrollment();

  // const CHILD_ID = localStorage.getItem("childID");
  const CHILD_ID = atob(id);
  const [StoreDiscountRemarks, setStoreDiscountRemarks] = useState("");

  const [INterval_days, setINterval_days] = useState("");
  const [CurrentInterval_day, setCurrentInterval_day] = useState("");

  // INSTALLMENT DETAILS
  const [NewInstallment, setNewInstallment] = useState({
    current_course_price: 0,
    current_down_payment: 0,
    current_discount_amount: 0,
    current_balance: 0,
    current_no_of_installment: 1, // total_installment
    current_installmentInterval: 1, //installment_duration
    current_installmentAmount: 0, // per_installment_amount
  });

  const [CompletedEnrollment, setCompletedEnrollment] = useState("");

  async function getCourseData() {
    setLoader(true);
    await fetch(URL + "/enrollment_list", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        setLoader(false);

        // Setting All Intial Values

        setCourse_details_list(obj.body);

        // console.log('obj.body[courseID].course_price : ' + obj.body[courseID].course_price);

        setCurrentInstallmentValues({
          ...currentInstallmentValues,
          current_course_price: obj.body[courseID ? courseID : 0].course_price,
          current_down_payment:
            obj.body[courseID ? courseID : 0].down_payment_amount,
          current_balance:
            obj.body[courseID ? courseID : 0].course_price -
            obj.body[courseID ? courseID : 0].down_payment_amount,
        });

        setStartDate(new Date());
        setEndDate(
          addMonths(
            new Date(),
            obj.body[courseID ? courseID : 0]?.duration_in_months
          )
        );
      })
      .catch((err) => {
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });

    props.setBreadcrumItem([
      { name: "Student List", link: `/` },
      {
        // name: localStorage.getItem("childName"),
        name: atob(childname),
        link: `/child_enrollment_payment/${childname}/${id}`,
      },
    ]);
  }

  const toastId = React.useRef(null);
  const EndDate_new = useRef(null);

  // ENROLLMENT HISTORY DETAILS
  const [EnrollmentHistory, setEnrollmentHistory] = useState("");
  const [EnrollmentHistory_Modal, setEnrollmentHistory_Modal] = useState(false);
  const [EnrollmentEmiHistory, setEnrollmentEmiHistory] = useState(false);
  const [EnrollmentEmiData, setEnrollmentEmiData] = useState([]);

  async function getPayInFullTransactionList() {
    let item = {
      child_id: CHILD_ID,
    };

    await fetch(URL + "/view_transaction", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(item),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        setPayInFullTransactionList(obj.body.transaction_details); // for pay in full

        if (obj.body.transaction_details?.end_date) {
          Check_enroll_complete(obj.body.transaction_details?.end_date);
        }
        setCurrentInstallmentValues({
          // for installment
          ...currentInstallmentValues,
          current_no_of_installment: obj.body.transaction_details
            .total_no_of_installment
            ? obj.body.transaction_details.total_no_of_installment
            : 1,
          current_installmentInterval: obj.body.transaction_details
            .installment_interval
            ? obj.body.transaction_details.installment_interval
            : 0,
          // current_installmentAmount: (currentInstallmentValues.current_installmentAmount == 0) ? obj.body.transaction_details.installment_amount : 0
          current_installmentAmount: obj.body.transaction_details
            .installment_amount
            ? obj.body.transaction_details.installment_amount
            : 0,
        });
      });
  }

  // Installments

  const downPaymentRefValue = useRef();
  const balanceRefValue = useRef(0);
  const noOfInstallmentRefValue = useRef();
  const discountAmountRef = useRef(0);
  const discountRemarkRef = useRef();

  async function getEnrollmentUserState() {
    // {
    //     "is_enrolled": true, // is child enrolled into the program , inital -> false
    //     "is_payment_complete": 0,  // is payment is complete ("Pay in full" is complete),  inital -> 0
    //     "is_installment": 1,  // check if the user payment method is installment or not , 1 -> installment | 0 -> pay in full, inital -> 0
    //     "is_valid": 1, // Automatically invalid -> true if the user payment is not made beyound the time limit | inital -> 0
    //     "is_paused": 0 // active enrollment -> 0/1 (admin can make the user active/inactive purposly)
    // }

    let item = {
      child_id: CHILD_ID,
    };

    await fetch(URL + "/enrolment_details", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(item),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        setEnrollmentUserState(obj.body);
        setEnrollmentID(obj.body.enrollment_id);
        setPaymentMethod(
          obj.body.is_installment == 1 ? "installment" : "payInfull"
        );
        getPayInFullTransactionList(); // calling again to fetch the data for installments (after enrollment)

        setSwitchValue({
          state: obj.body.is_paused == 1 ? true : false,
          name: obj.body.is_paused == 1 ? "InActive" : "Active",
        });
      })
      .catch((err) => {
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  }

  useEffect(() => {
    getCourseData(); // API -> course data
    getEnrollmentUserState();

    getPayInFullTransactionList(); // is the child is already enrolled
  }, []);
  useEffect(() => {
    setINterval_days(
      Math.floor(currentInstallmentValues.current_installmentInterval)
    );
    setCurrentInterval_day(
      Math.floor(currentInstallmentValues.current_installmentInterval)
    );
  }, [currentInstallmentValues]);
  async function postTransactionData() {
    let item = {
      child_id: CHILD_ID,
      is_installment: paymentMethod == "installment" ? 1 : 0,
      from_date: formatDate(startDateValue),
      to_date: EndDate_new.current.value.split("/").reverse().join("-"),
      course_id: courseID + 1,
      amount:
        paymentMethod == "installment"
          ? downPaymentRefValue.current.value
          : course_details_list[courseID].course_price -
            currentInstallmentValues.current_discount_amount,
      total_amount_to_be_paid:
        course_details_list[courseID].course_price -
        currentInstallmentValues.current_discount_amount, // amt after discount
      discount_amount: currentInstallmentValues.current_discount_amount,
      discount_remarks: StoreDiscountRemarks,
      total_installment: currentInstallmentValues.current_no_of_installment,
      installment_duration: CurrentInterval_day,
      payment_reference: transactionDetailsPayInfull.transaction_reference,
      payment_comment: transactionDetailsPayInfull.transaction_comment,
      payment_date: formatDate(transactionDetailsPayInfull.transaction_date),
      down_payment:
        paymentMethod == "installment" ? downPaymentRefValue.current.value : 0,
      balance:
        paymentMethod == "installment" ? balanceRefValue.current.value : 0,
      per_installment_amount: Math.floor(
        parseInt(currentInstallmentValues.current_installmentAmount)
      ),
    };

    await fetch(URL + "/add_transaction", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(item),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        if (obj.body.message == "error") {
          toast.error("Something went wrong");
          toast.clearWaitingQueue();
        } else {
          getEnrollmentUserState();
          toast.success("Transaction added Successfully");
          toast.clearWaitingQueue();
        }
        // switch (obj.status) {
        //     case 201:
        //         break;
        //     case 400:
        //         console.log('wrong password');
        //         break;
        // }
      })
      .catch((err) => {
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  }

  // const [value, setValue] = useState(null);// dummy Use state

  // addMonths(new Date(startDateValue), course_details_list[courseID]?.duration_in_months)
  //-------------- Toogle  (Active/ Inactive Switch)

  const [switchValue, setSwitchValue] = useState({
    // state: enrollmentUserState.is_paused,
    // name: "Active"
    state: false, // false -> active | true -> inactive
    // name: "Active"
    name: "Active",
  });

  const [lockToggle, setLockToggle] = useState(false);
  const [totalAmount_main, settotalAmount_main] = useState("");

  async function switchToogle() {
    let currenState = switchValue.state;
    // setSwitchValue(!switchValue)

    setLockToggle(true);
    setTimeout(function () {
      setLockToggle(false);
    }, 3000);

    let obj = {
      state: !currenState,
      name: currenState ? "Active" : "InActive",
    };
    setSwitchValue(obj);

    // toast.dismiss()

    if (obj.name == "Active") {
      toast.success("User Activated");
      toast.clearWaitingQueue();
    } else {
      toast.success("User Deactivated");
      toast.clearWaitingQueue();
    }

    let toggleItem = {
      enrollment_id: enrollmentID,
      is_paused: obj.state ? 1 : 0,
    };

    await fetch(URL + "/pause_or_resume", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(toggleItem),
    });
  }
  //--------- End Toogle

  const [courseID, selectedCourseID] = useState(); // 0, 1, 2, 3
  const [paymentMethod, setPaymentMethod] = useState(
    enrollmentUserState.is_installment == 1 ? "installment" : "payInfull"
  ); //    payInfull / installment
  // const [showTransactionList, setToogleTransactionList] = useState(false)
  const [showInstallmentPlan, setToogleInstallmentPlan] = useState(false);

  const [currentCourseDownPaymentAmount, setCurrentCourseDownPaymentAmount] =
    useState(null);
  // const [currentCourseBalanceAmount, setCurrentCourseBalanceAmount] = useState(null)
  const [
    currentCourseInstallmentPaymentAmount,
    setCurrentCourseInstallmentPaymentAmount,
  ] = useState(null);

  function setPaymentMethodClick(paymentValue) {
    setPaymentMethod(paymentValue);
    setInstallmentPlan(false);
  }

  function setInstallmentPlan(stateValue) {
    setToogleInstallmentPlan(stateValue);
  }

  // function addDays(date, days) {
  //     let result = new Date(date);
  //     result.setDate(result.getDate() + days);
  //     return result;
  // }

  //   ADD TRANSACTION BUTTON
  const ADD_TRANSACTION = () => {
    setAddTranaction(!AddTransaction);
  };

  // GENERATE LINK
  const GENERATE_LINK = () => {
    async function postGenerateLink() {
      setLoader(true);
      let item = {
        child_id: CHILD_ID,
        is_installment: paymentMethod == "installment" ? 1 : 0,
        from_date: formatDate(startDateValue),
        to_date: EndDate_new.current.value.split("/").reverse().join("-"),
        course_id: courseID + 1,
        amount:
          paymentMethod == "installment"
            ? downPaymentRefValue.current.value
            : course_details_list[courseID].course_price -
              currentInstallmentValues.current_discount_amount,
        total_amount_to_be_paid:
          course_details_list[courseID].course_price -
          currentInstallmentValues.current_discount_amount, // amt after discount
        discount_amount: currentInstallmentValues.current_discount_amount,
        discount_remarks: StoreDiscountRemarks,
        total_installment: currentInstallmentValues.current_no_of_installment,
        installment_duration: CurrentInterval_day,
        payment_reference: transactionDetailsPayInfull.transaction_reference,
        payment_comment: transactionDetailsPayInfull.transaction_comment,
        payment_date: formatDate(transactionDetailsPayInfull.transaction_date),
        down_payment:
          paymentMethod == "installment"
            ? downPaymentRefValue.current.value
            : 0,
        balance:
          paymentMethod == "installment" ? balanceRefValue.current.value : 0,
        per_installment_amount: Math.floor(
          parseInt(currentInstallmentValues.current_installmentAmount)
        ),
      };

      await fetch(URL + "/add_transaction_link", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("loggedToken"),
        },
        body: JSON.stringify(item),
      })
        .then((r) =>
          r.json().then((data) => ({
            status: r.status,
            body: data,
          }))
        )
        .then((obj) => {
          if (obj.body.message == "success") {
            setcopyLink(copyLink, (copyLink.url = obj.body.payment_url));
            setOpenGenerateModal(true);
            setLoader(false);
          } else {
            toast.error("Please try again");
            toast.clearWaitingQueue();
          }
        })
        .catch((err) => {
          setLoader(false);
          toast.error("Please try again");
          toast.clearWaitingQueue();
        });
    }

    if (
      errorFieldMessages.error__showStartDate ||
      errorFieldMessages.error__showTransactionDate ||
      errorFieldMessages.error__showTransactionReference ||
      errorFieldMessages.error__showTransactionComment ||
      errorFieldMessages.error__showDiscountAmount ||
      errorFieldMessages.error__no_of_installment ||
      errorFieldMessages.error__downPaymentAmount ||
      errorFieldMessages.error_for_interval
    ) {
      toast.warn("Fields contains error");
      toast.clearWaitingQueue();
      return;
    } else {
      postGenerateLink();
    }
  };

  if (loader) {
    return <Loader />;
  }

  function ResetALL() {
    discountAmountRef.current.value = "";
    discountRemarkRef.current.value = "";
    setStoreDiscountRemarks("");
    setDiscountShow_instant({
      discountAmount: "",
      discountRemarks: "",
    });
    setDiscountApplied(false);
    setCurrentInstallmentValues(
      currentInstallmentValues,
      (currentInstallmentValues.current_discount_amount = 0)
    );
  }

  const COPY_LINK = async () => {
    let data;
    setLoader(true);
    await fetch(URL + "/copy_link", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(
        (data = {
          child_view_id: CHILD_ID,
        })
      ),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        setcopyLink(copyLink, (copyLink.url = obj.body.url));
        setOpenGenerateModal(true);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        toast.error("Please try again");
        toast.clearWaitingQueue();
      });
  };

  // CHECK THE ENROLLMENT IS COMPLETED
  const Check_enroll_complete = (endDate) => {
    const currnetdate = new Date().toISOString().split("T")[0];
    const date1 = new Date(currnetdate);
    const date2 = new Date(endDate);
    const diffTime = date2 - date1;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    setCompletedEnrollment(diffDays);
  };

  // VIEW ENROLLMENT HISTORY
  const EnrollmentHistory_Handler = async () => {
    let data;
    setLoader(true);
    await fetch(URL + "/child_enrollment_history", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(
        (data = {
          child_id: CHILD_ID,
        })
      ),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        setLoader(false);
        setEnrollmentHistory(obj.body);
        setEnrollmentHistory_Modal(true);
      })
      .catch((err) => {
        setLoader(false);
        toast.error("Please try again");
        toast.clearWaitingQueue();
      });
  };

  // VIEW EMI DETAILS
  const ViewEmiDetails = async (si_no, view_type) => {
    let data;
    setLoader(true);
    await fetch(URL + "/view_old_emi_details", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(
        (data = {
          si_no,
          view_type,
        })
      ),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        setLoader(false);
        setEnrollmentEmiData(obj.body);
        setEnrollmentEmiHistory(true);
      })
      .catch((err) => {
        setLoader(false);
        toast.error("Please try again");
        toast.clearWaitingQueue();
      });
  };
  return (
    <>
      <TopMenu id={id} childname={childname} />
      <div className="enrollment-container">
        <div className="section_container">
          <div className="course_section">
            {/* <p>Select Course</p> */}
            <div className="top-layer">
              <p className="heading">Enrollment and Payment</p>

              <div
                className={
                  enrollmentUserState.is_enrolled
                    ? "active_child_toogle"
                    : "none"
                }
              >
                <span>{switchValue.name}</span>
                <Switch
                  className={lockToggle ? "lock" : ""}
                  onChange={switchToogle}
                  checked={!switchValue.state}
                  onColor="#007872"
                />
              </div>

              <div
                className={
                  enrollmentUserState.is_enrolled ? "none" : "viewPlans_holder"
                }
                onClick={() => {
                  setTogglePlanGuide(!togglePlanGuide);
                }}
              >
                <p>View Plans Guide</p>
                <i class="far fa-image"></i>
              </div>
            </div>

            <div
              className={
                switchValue.state
                  ? "course_list_container disabled"
                  : "course_list_container"
              }
            >
              <ul className={enrollmentUserState.is_enrolled ? "none" : "show"}>
                {course_details_list.map((item, index) => {
                  return (
                    <CourseNameComponent
                      key={index}
                      ResetALL={ResetALL}
                      settotalAmount_main={settotalAmount_main}
                      course_name={course_details_list[index].course_name}
                      fa={course_details_list[index].image}
                      discountAmount={
                        course_details_list[index].discount_amount
                      }
                      amount={course_details_list[index].course_price}
                      duration={course_details_list[index].duration_in_months}
                      class_count={course_details_list[index].no_of_classes}
                      indexValue={index}
                      useStateCourseID={courseID}
                      useStateSetCourseID={selectedCourseID}
                      addMonths={addMonths}
                      getDaysBetweenMonths={getDaysBetweenMonths}
                      setCurrentInstallmentValues={setCurrentInstallmentValues}
                      currentInstallmentValues={currentInstallmentValues}
                      course_details_list={course_details_list}
                      startDateValue={startDateValue}
                    />
                  );
                })}
              </ul>

              <div
                className={
                  enrollmentUserState.is_enrolled
                    ? "discount_container none"
                    : "discount_container show"
                }
              >
                <input
                  onInput={(e) => {
                    if (e.target.value == "") {
                      setErrorFieldMessages({
                        ...errorFieldMessages,
                        error__showDiscountAmount: false,
                        error__discountAmountMessage: "",
                      });
                    } else if (e.target.value == 0) {
                      setErrorFieldMessages({
                        ...errorFieldMessages,
                        error__showDiscountAmount: true,
                        error__discountAmountMessage: "Can't be zero",
                      });
                    } else if (e.target.value < 0) {
                      setErrorFieldMessages({
                        ...errorFieldMessages,
                        error__showDiscountAmount: true,
                        error__discountAmountMessage: "Can't be negative value",
                      });
                    }
                    // else if (e.target.value > currentInstallmentValues.current_course_price
                    //     && currentInstallmentValues.current_course_price == 0) {

                    // }
                    else if (e.target.value > totalAmount_main / 2) {
                      setErrorFieldMessages({
                        ...errorFieldMessages,
                        error__showDiscountAmount: true,
                        error__discountAmountMessage:
                          "Can't be more than 50% of course price",
                      });
                    } else if (
                      e.target.value >
                      Math.abs(
                        downPaymentRefValue.current.value -
                          currentInstallmentValues.current_course_price
                      )
                    ) {
                      // Math.abs(downPaymentRefValue.current.value - currentInstallmentValues.current_course_price);
                      setErrorFieldMessages({
                        ...errorFieldMessages,
                        error__showDiscountAmount: true,
                        error__discountAmountMessage:
                          "Can't be more than current balance",
                      });
                    } else {
                      setErrorFieldMessages({
                        ...errorFieldMessages,
                        error__showDiscountAmount: false,
                        error__discountAmountMessage: "",
                      });
                    }
                  }}
                  min={0}
                  type="number"
                  ref={discountAmountRef}
                  placeholder="Enter Discount"
                />
                <span className={isDiscountApplied ? "active" : ""}>
                  {" "}
                  <i class="fas fa-tag"></i>
                  {DiscountShow_instant?.discountAmount} Discount Applied
                </span>

                <span
                  className={
                    errorFieldMessages.error__showDiscountAmount
                      ? "active error"
                      : "error"
                  }
                >
                  {" "}
                  <i class="fas fa-tag"></i>
                  {errorFieldMessages.error__discountAmountMessage}
                </span>

                <button
                  className="btn btn1"
                  onClick={(event) => {
                    if (courseID == null) {
                      toast.warn("Please Select Course");
                      toast.clearWaitingQueue();
                    } else if (errorFieldMessages.error__showDiscountAmount) {
                      toast.warn("Discount Fields contain error");
                      toast.clearWaitingQueue();
                    } else if (discountAmountRef.current.value == "") {
                      toast.warn("Please Enter Discount amount");
                      toast.clearWaitingQueue();
                    } else if (
                      discountAmountRef.current.value >
                      totalAmount_main / 2
                    ) {
                      toast.warn("Please Enter Discount amount");
                      toast.clearWaitingQueue();
                    } else {
                      // setDiscountApplied(true)
                      setToggleDiscountRemarks(true);
                      // setDiscountShow_instant(
                      //   DiscountShow_instant,
                      //   (DiscountShow_instant.discountAmount =
                      //     discountAmountRef.current.value)
                      // );
                      // setCurrentInstallmentValues({
                      //   ...currentInstallmentValues,
                      //   current_discount_amount:
                      //     discountAmountRef.current.value,
                      //   // current_balance: (currentInstallmentValues.current_balance - discountAmountRef.current.value),
                      //   current_course_price:
                      //     currentInstallmentValues.current_course_price -
                      //     discountAmountRef.current.value,
                      //   // course amt after discount

                      //   current_balance:
                      //     currentInstallmentValues.current_balance -
                      //     discountAmountRef.current.value,
                      //   current_installmentAmount:
                      //     (currentInstallmentValues.current_balance -
                      //       discountAmountRef.current.value) /
                      //     currentInstallmentValues.current_no_of_installment,
                      // });

                      // event.target.parentElement.classList.add('disabled')
                    }
                  }}
                >
                  Apply
                </button>

                {StoreDiscountRemarks != "" ? (
                  <div className="discountRemarks">
                    <b>Discount Remarks</b>
                    <p>{StoreDiscountRemarks}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <ul className={enrollmentUserState.is_enrolled ? "show" : "none"}>
                <li>
                  <div className="star_icon">
                    <i className="fas fa-star"></i>
                    <span>
                      {
                        course_details_list[enrollmentUserState.course_id - 1]
                          ?.course_name
                      }
                    </span>
                  </div>
                  <div>
                    <p
                      className={
                        enrollmentUserState.discount_amount == 0
                          ? "none"
                          : "discount_amount show"
                      }
                    >
                      <i>₹</i>
                      {course_details_list[enrollmentUserState.course_id - 1]
                        ?.course_price - enrollmentUserState.discount_amount}
                    </p>
                    <p
                      className={
                        enrollmentUserState.discount_amount == 0
                          ? "amount"
                          : "amount strike_amount"
                      }
                    >
                      {" "}
                      <i>₹</i>
                      {
                        course_details_list[enrollmentUserState.course_id - 1]
                          ?.course_price
                      }
                    </p>
                    <p className="duration">
                      {
                        course_details_list[enrollmentUserState.course_id - 1]
                          ?.duration_in_months
                      }{" "}
                      months
                    </p>
                    <p className="class_count">
                      {
                        course_details_list[enrollmentUserState.course_id - 1]
                          ?.no_of_classes
                      }{" "}
                      classes
                    </p>
                  </div>
                </li>
              </ul>
            </div>

            <div
              className={
                courseID != null ||
                (courseID != "undefined" && enrollmentUserState.is_enrolled)
                  ? ""
                  : "none"
              }
            >
              <div
                className={
                  switchValue.state ? "first_row disabled" : "first_row"
                }
              >
                <div className="payment_method_container">
                  <p>
                    {enrollmentUserState.is_enrolled
                      ? "Payment method"
                      : "Select Payment method"}
                  </p>

                  <div className="payment_method">
                    <button
                      className={
                        enrollmentUserState.is_enrolled &&
                        !enrollmentUserState.is_installment &&
                        paymentMethod == "payInfull"
                          ? "btn btn2 show active lock"
                          : !enrollmentUserState.is_enrolled &&
                            !enrollmentUserState.is_installment
                          ? paymentMethod == "payInfull"
                            ? "btn btn2 show active"
                            : "btn btn2 show"
                          : "btn btn2 none"
                      }
                      onClick={() => setPaymentMethodClick("payInfull")}
                    >
                      Pay in full <i className="fas fa-money-bill"></i>
                    </button>

                    <button
                      className={
                        enrollmentUserState.is_enrolled &&
                        enrollmentUserState.is_installment &&
                        paymentMethod == "installment"
                          ? "btn btn2 show active lock"
                          : !enrollmentUserState.is_enrolled &&
                            !enrollmentUserState.is_installment
                          ? paymentMethod == "installment"
                            ? "btn btn2 show active"
                            : "btn btn2 show"
                          : "btn btn2 none"
                      }
                      onClick={() => {
                        setPaymentMethodClick("installment");

                        setCurrentInstallmentValues({
                          ...currentInstallmentValues,
                          current_no_of_installment: 1, // inital values
                          current_installmentInterval: getDaysBetweenMonths(
                            startDateValue,
                            addMonths(
                              new Date(startDateValue),
                              course_details_list[courseID]?.duration_in_months
                            )
                          ), // TODO: change this later |for inital values to update in setCurrentInstallmentValues
                          // current_installmentAmount: isNaN(parseFloat(balanceRefValue.current.value)) ? 0 : balanceRefValue.current.value
                          current_installmentAmount:
                            balanceRefValue.current.value,
                        });
                      }}
                    >
                      Installments <i className="fas fa-donate"></i>
                    </button>
                  </div>
                </div>

                <div
                  className={
                    paymentMethod == null
                      ? "course_duration_container"
                      : "course_duration_container active"
                  }
                >
                  <p>Course Duration</p>

                  <div className="course_duration">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disabled={
                          enrollmentUserState.is_enrolled ? true : false
                        }
                        // disablePast
                        label={
                          enrollmentUserState.is_enrolled
                            ? "Start Date"
                            : "Select Start Date"
                        }
                        // error={false}
                        value={
                          enrollmentUserState.is_enrolled
                            ? payInFullTransactionList.start_date
                            : startDateValue
                        }
                        inputFormat={"dd/MM/yyyy"}
                        onChange={(newValue) => {
                          setStartDate(newValue);
                          setEndDate(
                            addMonths(
                              new Date(newValue),
                              course_details_list[courseID]?.duration_in_months
                            )
                          );
                        }}
                        onError={(error) => {
                          if (error != null) {
                            setErrorFieldMessages({
                              ...errorFieldMessages,
                              error__showStartDate: true,
                            });
                          } else {
                            setErrorFieldMessages({
                              ...errorFieldMessages,
                              error__showStartDate: false,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            autoComplete="off"
                            {...params}
                            helperText={params?.inputProps?.placeholder}
                          />
                        )}
                      />
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        // disabled={
                        //   enrollmentUserState.is_enrolled ? true : false
                        // }
                        disabled={true}
                        inputFormat={"dd/MM/yyyy"}
                        label="End Date"
                        onChange={(newValue) => {
                          // do something
                        }}
                        // value={
                        //   enrollmentUserState.is_enrolled
                        //     ? addMonths(
                        //         new Date(payInFullTransactionList.start_date),
                        //         course_details_list[
                        //           enrollmentUserState.course_id - 1
                        //         ]?.duration_in_months
                        //       )
                        //     : addMonths(
                        //         new Date(startDateValue),
                        //         course_details_list[courseID]
                        //           ?.duration_in_months
                        //       )
                        // }
                        value={
                          enrollmentUserState.is_enrolled
                            ? payInFullTransactionList.end_date
                            : addMonths(
                                new Date(startDateValue),
                                course_details_list[courseID]
                                  ?.duration_in_months
                              )
                        }
                        inputRef={EndDate_new}
                        // value={new Date(startDateValue.getDate() + 2)}
                        // onChange={(newValue) => {
                        //     setValue(newValue);
                        // }}
                        renderInput={(params) => (
                          <TextField
                            autoComplete="off"
                            {...params}
                            helperText={params?.inputProps?.placeholder}
                          />
                        )}
                      />
                    </LocalizationProvider>

                    {enrollmentUserState.is_enrolled ? (
                      ""
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "350px",
                        }}
                      >
                        <div>
                          <button
                            className="courseDuration_btn"
                            onClick={ADD_TRANSACTION}
                          >
                            Add Transaction
                          </button>
                          <p
                            style={{
                              textAlign: "center",
                              fontSize: 14,
                              marginTop: "0px",
                              color: "#grey",
                            }}
                          >
                            <span style={{ fontSize: 22, color: "#ff3e3e" }}>
                              *
                            </span>
                            Offline transaction
                          </p>
                        </div>
                        <span style={{ marginTop: "10px" }}>or</span>

                        <div>
                          <button
                            className={
                              AddTransaction
                                ? "courseDuration_btn greyOut"
                                : "courseDuration_btn"
                            }
                            onClick={GENERATE_LINK}
                          >
                            Generate Link
                          </button>
                          <p
                            style={{
                              textAlign: "center",
                              fontSize: 14,
                              marginTop: "0px",
                              color: "#grey",
                            }}
                          >
                            <span style={{ fontSize: 22, color: "#ff3e3e" }}>
                              *
                            </span>
                            Online transaction
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div
                className={
                  switchValue.state ? "second_row disabled" : "second_row"
                }
              >
                {/* <div className={
                                (paymentMethod == 'payInfull' && !enrollmentUserState.is_enrolled)
                                    ? 'transaction_details_container active'
                                    : (paymentMethod != 'payInfull' && !enrollmentUserState.is_enrolled)
                                        ? 'transaction_details_container'
                                        : (paymentMethod != 'payInfull' && enrollmentUserState.is_enrolled && !enrollmentUserState.is_installment)
                                            ? 'transaction_details_container active'
                                            : 'transaction_details_container'}> */}

                {/* Installment */}
                <div
                  className={
                    paymentMethod == "installment" &&
                    !enrollmentUserState.is_enrolled
                      ? "transaction_details_container active"
                      : "transaction_details_container"
                  }
                >
                  <p>Installment Details</p>
                  <div className="transaction_details">
                    <TextField
                      type="number"
                      autoComplete="off"
                      id="outlined-basic"
                      label="Down Payment"
                      InputProps={{
                        inputProps: {
                          min: course_details_list[courseID]
                            ?.down_payment_amount,
                          max: course_details_list[courseID]?.course_price,
                        },
                      }}
                      variant="outlined"
                      inputRef={downPaymentRefValue}
                      error={errorFieldMessages.error__downPaymentAmount}
                      helperText={
                        errorFieldMessages.error__downPaymentAmount
                          ? errorFieldMessages.error__downPaymentAmountMessage
                          : ""
                      }
                      value={currentInstallmentValues?.current_down_payment}
                      onChange={(event) => {
                        // let currentValue = Math.abs(event.target.value)
                        // if (currentValue < course_details_list[courseID].down_payment_amount
                        //     || currentValue > currentInstallmentValues.current_course_price) {
                        //     currentValue = course_details_list[courseID].down_payment_amount
                        // }

                        let currentValue = event.target.value;

                        if (currentValue == "") {
                          setCurrentInstallmentValues({
                            ...currentInstallmentValues,
                            current_down_payment: currentValue,
                          });
                          setErrorFieldMessages({
                            ...errorFieldMessages,
                            error__downPaymentAmount: true,
                            error__downPaymentAmountMessage:
                              "Downpayment cant be empty",
                          });
                        } else if (currentValue == 0) {
                          setCurrentInstallmentValues({
                            ...currentInstallmentValues,
                            current_down_payment: currentValue,
                          });
                          setErrorFieldMessages({
                            ...errorFieldMessages,
                            error__downPaymentAmount: true,
                            error__downPaymentAmountMessage:
                              "Downpayment cant be 0",
                          });
                        } else if (
                          currentValue <
                          course_details_list[courseID].down_payment_amount
                        ) {
                          setCurrentInstallmentValues({
                            ...currentInstallmentValues,
                            current_down_payment: currentValue,
                          });
                          setErrorFieldMessages({
                            ...errorFieldMessages,
                            error__downPaymentAmount: true,
                            error__downPaymentAmountMessage:
                              "Amount cant be less than Down payment",
                          });
                        } else if (
                          currentValue >
                          course_details_list[courseID].course_price -
                            currentInstallmentValues.current_discount_amount
                        ) {
                          setCurrentInstallmentValues({
                            ...currentInstallmentValues,
                            current_down_payment: currentValue,
                          });
                          setErrorFieldMessages({
                            ...errorFieldMessages,
                            error__downPaymentAmount: true,
                            error__downPaymentAmountMessage:
                              "Down payment cant be more than Course price",
                          });
                        } else {
                          setErrorFieldMessages({
                            ...errorFieldMessages,
                            error__downPaymentAmount: false,
                            error__downPaymentAmountMessage: "",
                          });

                          setCurrentInstallmentValues({
                            ...currentInstallmentValues,
                            current_down_payment: currentValue,
                            current_balance:
                              currentInstallmentValues.current_course_price -
                              currentValue -
                              currentInstallmentValues?.current_discount_amount,
                            current_installmentAmount:
                              (currentInstallmentValues.current_course_price -
                                currentValue -
                                currentInstallmentValues?.current_discount_amount) /
                              currentInstallmentValues.current_no_of_installment,
                          });
                        }

                        // setCurrentCourseDownPaymentAmount(event.target.value)
                      }}
                    />
                    <TextField
                      autoComplete="off"
                      id="outlined-basic"
                      label="Balance"
                      variant="outlined"
                      // value={(currentInstallmentValues.current_course_price - currentInstallmentValues.current_down_payment)}
                      value={currentInstallmentValues.current_balance}
                      // helperText="Discount applied"
                      // color="success"
                      onChange={(event) => {
                        // do something
                      }}
                      inputRef={balanceRefValue}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                </div>
              </div>

              <div
                className={
                  switchValue.state ? "third_row disabled" : "third_row"
                }
              >
                <div
                  className={
                    paymentMethod == "installment" &&
                    !enrollmentUserState.is_enrolled
                      ? "installment_plan_container active"
                      : "installment_plan_container"
                  }
                >
                  {/* <p>Installment Plan</p> */}
                  <p></p>

                  <div className="installment_plan">
                    <TextField
                      autoComplete="off"
                      id="outlined-number"
                      label="No. of Installments"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputRef={noOfInstallmentRefValue}
                      InputProps={{
                        inputProps: {
                          min: 1,
                          max:
                            course_details_list[courseID]?.duration_in_months -
                            1,
                        },
                      }}
                      value={currentInstallmentValues.current_no_of_installment}
                      error={errorFieldMessages.error__no_of_installment}
                      helperText={
                        errorFieldMessages.error__no_of_installment
                          ? errorFieldMessages.error__no_of_installmentMessage
                          : ""
                      }
                      onChange={(event) => {
                        setCurrentInstallmentValues({
                          ...currentInstallmentValues,
                          current_no_of_installment: event.target.value,
                        });

                        if (event.target.value == "") {
                          setErrorFieldMessages({
                            ...errorFieldMessages,
                            error__no_of_installment: true,
                            error__no_of_installmentMessage:
                              "installment count can't be empty",
                          });
                        } else if (event.target.value == 0) {
                          setErrorFieldMessages({
                            ...errorFieldMessages,
                            error__no_of_installment: true,
                            error__no_of_installmentMessage:
                              "installment count can't be 0",
                          });
                        } else if (event.target.value < 0) {
                          setErrorFieldMessages({
                            ...errorFieldMessages,
                            error__no_of_installment: true,
                            error__no_of_installmentMessage:
                              "installment count can't be negative values",
                          });
                        } else if (
                          event.target.value >
                          course_details_list[courseID].duration - 1
                        ) {
                          setErrorFieldMessages({
                            ...errorFieldMessages,
                            error__no_of_installment: true,
                            error__no_of_installmentMessage:
                              "installment count can't be more than course duration",
                          });
                        } else {
                          setErrorFieldMessages({
                            ...errorFieldMessages,
                            error__no_of_installment: false,
                            error__no_of_installmentMessage: "",
                          });

                          let calculatedCurrentInstallmentInterval,
                            calculatedCurrentInstallmentAmount;

                          if (event.target.value > 0) {
                            calculatedCurrentInstallmentInterval =
                              getDaysBetweenMonths(
                                startDateValue,
                                addMonths(
                                  new Date(startDateValue),
                                  course_details_list[courseID]
                                    ?.duration_in_months
                                )
                              ) / event.target.value;
                            calculatedCurrentInstallmentAmount =
                              balanceRefValue.current.value /
                              event.target.value;
                          } else {
                            calculatedCurrentInstallmentInterval = 0;
                            calculatedCurrentInstallmentAmount = 0;
                          }

                          setCurrentInstallmentValues({
                            ...currentInstallmentValues,
                            current_no_of_installment: event.target.value,
                            current_installmentInterval:
                              calculatedCurrentInstallmentInterval,
                            current_installmentAmount:
                              calculatedCurrentInstallmentAmount,
                          });
                          setCurrentInterval_day(
                            Math.floor(calculatedCurrentInstallmentInterval)
                          );

                          // setCurrentCourseInstallmentPaymentAmount((course_details_list[courseID]?.course_price - (currentCourseDownPaymentAmount
                          //     ? currentCourseDownPaymentAmount
                          //     : course_details_list[courseID]?.down_payment_amount)) / event.target.value)
                        }
                      }}
                    />

                    {/* <TextField className='textfield' id="outlined-basic" label="No. of Installments" variant="outlined"

                                    /> */}
                    <TextField
                      autoComplete="off"
                      className="textfield"
                      id="outlined-basic"
                      label="Enter Installment Interval (in days)"
                      variant="outlined"
                      onInput={(event) => {
                        // do something
                        setCurrentInterval_day(event.target.value);
                        if (event.target.value <= 0) {
                          setErrorFieldMessages({
                            ...errorFieldMessages,
                            error_for_interval: true,
                            error_message_for_interval:
                              "Interval days cannot less than 0",
                          });
                        } else if (event.target.value > INterval_days) {
                          setErrorFieldMessages({
                            ...errorFieldMessages,
                            error_for_interval: true,
                            error_message_for_interval: `Interval days cannot greater than ${INterval_days}`,
                          });
                        } else {
                          setErrorFieldMessages({
                            ...errorFieldMessages,
                            error_for_interval: false,
                            error_message_for_interval: "",
                          });
                        }
                      }}
                      // value={datediff(parseDate(formatDate(startDateValue)), parseDate(formatDate(endDateValue)))}
                      value={CurrentInterval_day}
                      type={"number"}
                      error={errorFieldMessages.error_for_interval}
                      helperText={errorFieldMessages.error_message_for_interval}
                    />

                    <TextField
                      autoComplete="off"
                      className="textfield"
                      id="outlined-basic"
                      label="Installment amount"
                      variant="outlined"
                      onChange={(event) => {
                        // do something
                      }}
                      // value={currentCourseInstallmentPaymentAmount ? Math.ceil(currentCourseInstallmentPaymentAmount) : 0
                      // value={currentInstallmentValues.current_installmentAmount
                      //     ? Math.ceil(currentInstallmentValues.current_installmentAmount)
                      //     : currentInstallmentValues.current_balance}
                      value={Math.round(
                        currentInstallmentValues.current_installmentAmount
                      )}
                    />

                    {/* <button className={enrollmentUserState.is_enrolled ? "btn btn2 none" : "btn btn2 show"}
                                        onClick={(event) => {
                                            // setViewdetails(true)
                                            // setEnrollmentUserState({
                                            //     ...enrollmentUserState, // this makes all the keys value remain same the below ones
                                            //     is_enrolled: true,
                                            //     is_installment: true
                                            // })

                                        }}>Generate <i class="fas fa-cogs"></i></button> */}

                    {/* <NavLink to={"/installment_details"} className={(showViewdetails) ? "btn btn2" : "btn btn2 none"}
                                    >View EMI Details</NavLink> */}
                  </div>
                </div>

                {/* Pay in full */}

                <div
                  className={
                    !enrollmentUserState.is_enrolled && AddTransaction
                      ? "transaction_details_container active"
                      : "transaction_details_container"
                  }
                >
                  <p>Enter Transaction Details</p>

                  <div className="transaction_details">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disableFuture
                        inputFormat={"dd/MM/yyyy"}
                        label="Enter transaction date"
                        value={transactionDetailsPayInfull.transaction_date}
                        // error={errorFieldMessages.error__showTransactionDate}
                        onChange={(newValue) => {
                          // setValue(newValue);
                          setTransactionDetailsPayInfull({
                            ...transactionDetailsPayInfull,
                            transaction_date: formatDate(newValue),
                          });
                        }}
                        onError={(error) => {
                          if (error != null) {
                            setErrorFieldMessages({
                              ...errorFieldMessages,
                              error__showTransactionDate: true,
                            });
                          } else {
                            setErrorFieldMessages({
                              ...errorFieldMessages,
                              error__showTransactionDate: false,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            autoComplete="off"
                            {...params}
                            helperText={params?.inputProps?.placeholder}
                          />
                        )}
                        PopoverProps={{
                          anchorOrigin: {
                            horizontal: "right",
                            vertical: "top",
                          },
                          transformOrigin: {
                            horizontal: "right",
                            vertical: "top",
                          },
                        }}
                      />
                    </LocalizationProvider>

                    <TextField
                      autoComplete="off"
                      className="textfield"
                      id="outlined-basic"
                      label="Enter transaction reference"
                      variant="outlined"
                      error={errorFieldMessages.error__showTransactionReference}
                      helperText={
                        errorFieldMessages.error__showTransactionReference
                          ? "Cant use Special chars!"
                          : ""
                      }
                      onChange={(newValue) => {
                        if (newValue.target.value.match(/[^a-zA-Z0-9 ]+/)) {
                          setErrorFieldMessages({
                            ...errorFieldMessages,
                            error__showTransactionReference: true,
                          });
                        } else {
                          setErrorFieldMessages({
                            ...errorFieldMessages,
                            error__showTransactionReference: false,
                          });

                          setTransactionDetailsPayInfull({
                            ...transactionDetailsPayInfull,
                            transaction_reference: newValue.target.value,
                          });
                        }
                      }}
                    />

                    <TextField
                      autoComplete="off"
                      className="textfield"
                      id="outlined-basic"
                      label="Enter Comment"
                      variant="outlined"
                      error={errorFieldMessages.error__showTransactionComment}
                      helperText={
                        errorFieldMessages.error__showTransactionComment
                          ? "Cant use Special chars!"
                          : ""
                      }
                      onChange={(newValue) => {
                        if (newValue.target.value.match(/[^a-zA-Z ]+/)) {
                          setErrorFieldMessages({
                            ...errorFieldMessages,
                            error__showTransactionComment: true,
                          });
                        } else {
                          setErrorFieldMessages({
                            ...errorFieldMessages,
                            error__showTransactionComment: false,
                          });

                          setTransactionDetailsPayInfull({
                            ...transactionDetailsPayInfull,
                            transaction_comment: newValue.target.value,
                          });
                        }
                      }}
                    />

                    <button
                      className="btn btn2"
                      onClick={() => {
                        if (
                          errorFieldMessages.error__showStartDate ||
                          errorFieldMessages.error__showTransactionDate ||
                          errorFieldMessages.error__showTransactionReference ||
                          errorFieldMessages.error__showTransactionComment ||
                          errorFieldMessages.error__showDiscountAmount ||
                          errorFieldMessages.error__no_of_installment ||
                          errorFieldMessages.error__downPaymentAmount ||
                          errorFieldMessages.error_for_interval
                        ) {
                          toast.warn("Fields contains error");
                          toast.clearWaitingQueue();
                          return;
                        } else if (
                          transactionDetailsPayInfull.transaction_date ==
                            null ||
                          transactionDetailsPayInfull.transaction_date == ""
                        ) {
                          toast.warn("Please Enter Transaction Date");
                          toast.clearWaitingQueue();
                          return;
                        } else if (
                          transactionDetailsPayInfull.transaction_reference ==
                            null ||
                          transactionDetailsPayInfull.transaction_reference ==
                            ""
                        ) {
                          toast.warn("Please Enter Transaction Reference");
                          toast.clearWaitingQueue();
                          return;
                        } else if (
                          transactionDetailsPayInfull.transaction_comment ==
                            null ||
                          transactionDetailsPayInfull.transaction_comment == ""
                        ) {
                          toast.warn("Please Enter Transaction Comment");
                          toast.clearWaitingQueue();
                          return;
                        } else {
                          postTransactionData();
                        }
                      }}
                    >
                      Done <i className="fas fa-paper-plane"></i>
                    </button>
                  </div>
                </div>
              </div>

              {/* className={(showTransactionList) ? "fourth_row active" : "fourth_row" */}

              <div
                className={
                  enrollmentUserState.is_enrolled &&
                  !enrollmentUserState.is_installment
                    ? "fourth_row active"
                    : "fourth_row"
                }
              >
                <p>Payment Details</p>

                {/* <DataTable active={true}
                                dataKeys={PaymentTableKeys}
                                datas={transaction_list}
                                tableHeaders={PaymentTableHeaders}
                                pagination={false} /> */}

                <div className="tableContainer">
                  <div className="ltr">
                    <table className="mytable">
                      <thead>
                        <tr>
                          <th>Payment</th>
                          <th>Amount Paid</th>
                          <th>Transaction Date</th>
                          <th>Transaction Time</th>
                          <th>Transaction Reference</th>
                          <th>Transaction Comment</th>
                          <th>Payment link</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          <tr>
                            <td>{payInFullTransactionList.payment_mode}</td>
                            <td>
                              {payInFullTransactionList.total_amount_to_be_paid}
                            </td>
                            <td>{payInFullTransactionList.transaction_date}</td>
                            <td>{payInFullTransactionList.transaction_time}</td>
                            <td>
                              {payInFullTransactionList.transaction_reference}
                            </td>
                            <td>
                              {payInFullTransactionList.transaction_comment}
                            </td>
                            <td>
                              <button
                                onClick={COPY_LINK}
                                style={{
                                  background: "transparent",
                                  border: "none",
                                  cursor: "pointer",
                                  pointerEvents:
                                    payInFullTransactionList.transaction_date !=
                                      "" &&
                                    payInFullTransactionList.transaction_date !=
                                      null
                                      ? "none"
                                      : "all",
                                  color:
                                    payInFullTransactionList.transaction_date !=
                                      "" &&
                                    payInFullTransactionList.transaction_date !=
                                      null
                                      ? "#ccc"
                                      : "#007872",
                                  fontSize: 16,
                                  textDecoration: "underline",
                                }}
                              >
                                Link
                              </button>
                            </td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* <div className={switchValue.state ? "first_row disabled" : "first_row"}> */}

              <div
                className={
                  switchValue.state
                    ? enrollmentUserState.is_enrolled &&
                      !enrollmentUserState.is_installment
                      ? "emi_details disabled none"
                      : "emi_details disabled"
                    : enrollmentUserState.is_enrolled &&
                      enrollmentUserState.is_installment
                    ? "emi_details"
                    : "none"
                }
              >
                <TextField
                  autoComplete="off"
                  id="outlined-basic"
                  label="No. of installment"
                  variant="outlined"
                  value={currentInstallmentValues.current_no_of_installment}
                  onChange={(event) => {
                    // do something
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                <TextField
                  autoComplete="off"
                  id="outlined-basic"
                  label="Installment Interval"
                  variant="outlined"
                  value={currentInstallmentValues.current_installmentInterval}
                  onChange={(event) => {
                    // do something
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                <TextField
                  autoComplete="off"
                  id="outlined-basic"
                  label="Installment Amount"
                  variant="outlined"
                  value={currentInstallmentValues.current_installmentAmount}
                  onChange={(event) => {
                    // do something
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                <NavLink
                  to={`/installment_details/${childname}/${id}`}
                  className={
                    enrollmentUserState.is_enrolled
                      ? "btn btn2 show"
                      : "btn btn2 none"
                  }
                  onClick={(event) => {
                    // setViewdetails(true)
                    setEnrollmentUserState({
                      ...enrollmentUserState, // this makes all the keys value remain same the below ones
                      is_enrolled: true,
                      is_installment: true,
                    });
                  }}
                >
                  View EMI Details
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          courseID != null && !enrollmentUserState.is_enrolled
            ? "summary_container"
            : "none"
        }
      >
        <p className="heading">Summary</p>

        <div className="summary_content">
          <div className="summary_content_items">
            <p>Initial Amount</p>
            <span>
              Rs.{" "}
              {paymentMethod == "payInfull"
                ? course_details_list[courseID]?.course_price
                : errorFieldMessages.error__downPaymentAmount
                ? course_details_list[courseID]?.down_payment_amount
                : currentInstallmentValues.current_down_payment}{" "}
              /-
            </span>
          </div>
          <div className="summary_content_items">
            <p>Discount Amount</p>
            <span>
              Rs. {currentInstallmentValues?.current_discount_amount} /-
            </span>
          </div>
          {/* <div className="divider"></div> */}
          <div className="summary_content_items finalAmount">
            <p>Final Amount</p>
            <span>
              Rs.{" "}
              {paymentMethod == "payInfull"
                ? course_details_list[courseID]?.course_price -
                  currentInstallmentValues?.current_discount_amount
                : errorFieldMessages.error__downPaymentAmount
                ? course_details_list[courseID]?.down_payment_amount
                : currentInstallmentValues.current_down_payment}{" "}
              /-
            </span>
          </div>
        </div>
      </div>

      <div
        className={
          toggleDiscountRemarks
            ? "discount_remarks_container active"
            : "discount_remarks_container"
        }
      >
        <div className="discount_remarks">
          <p className="heading">
            <i class="fas fa-tag"></i> Discount Remarks
          </p>
          <textarea ref={discountRemarkRef}></textarea>
          <div className="discount_remark_btn">
            <button
              className="btn btn2"
              onClick={() => {
                setToggleDiscountRemarks(false);
              }}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                if (discountRemarkRef.current.value == "") {
                  toast.warn("Please Enter Discount Remarks");
                  toast.clearWaitingQueue();
                } else {
                  setDiscountApplied(true);
                  setStoreDiscountRemarks(discountRemarkRef.current.value);
                  setDiscountShow_instant(
                    DiscountShow_instant,
                    (DiscountShow_instant.discountAmount =
                      discountAmountRef.current.value)
                  );
                  setCurrentInstallmentValues({
                    ...currentInstallmentValues,
                    current_discount_amount: discountAmountRef.current.value,
                    // current_balance: (currentInstallmentValues.current_balance - discountAmountRef.current.value),
                    current_course_price:
                      currentInstallmentValues.current_course_price,
                    // course amt after discount

                    // current_balance:
                    //   currentInstallmentValues.current_balance -
                    //   discountAmountRef.current.value,
                    current_balance:
                      currentInstallmentValues.current_course_price -
                      currentInstallmentValues?.current_down_payment -
                      discountAmountRef.current.value,
                    current_installmentAmount:
                      (currentInstallmentValues.current_course_price -
                        currentInstallmentValues?.current_down_payment -
                        discountAmountRef.current.value) /
                      currentInstallmentValues.current_no_of_installment,
                  });
                  toast.success("Discount Remarks Saved");
                  toast.clearWaitingQueue();
                  setToggleDiscountRemarks(false);
                }
              }}
              className="btn btn2"
            >
              Save <i class="fas fa-save"></i>
            </button>
          </div>
        </div>
      </div>

      <div
        className={togglePlanGuide ? "plan_guide active" : "plan_guide"}
        onClick={() => {
          setTogglePlanGuide(false);
        }}
      >
        {/* <img src={planImage} alt="" width="100%" /> */}
        <img
          src={require("../../../images/planGuide.png")}
          alt=""
          width="100%"
        />
      </div>

      <div
        className={
          OpenGenerateModal
            ? "generate_link_container"
            : "generate_link_container hide"
        }
      >
        <GenerateLink
          setOpenGenerateModal={setOpenGenerateModal}
          copyLink={copyLink?.url}
          id={id}
          childname={childname}
        />
      </div>
      {/* ENROLLMENT COMPLETED CHILD UI */}

      {enrollmentUserState?.is_enrolled && (
        <div className="complete_enrollment_container">
          <table>
            <thead>
              <tr>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {" "}
                  {payInFullTransactionList?.is_complete == 1
                    ? "Completed"
                    : "In progress"}{" "}
                </td>
              </tr>
            </tbody>
          </table>
          {payInFullTransactionList?.is_complete == 1 && (
            <button
              onClick={() => {
                setEnrollmentUserState({
                  course_id: 0,
                  discount_amount: 0,
                  enrollment_id: false,
                  is_enrolled: false,
                  is_installment: false,
                  is_paused: false,
                  is_payment_complete: false,
                  is_valid: false,
                });
              }}
            >
              Add new enrollment{" "}
            </button>
          )}

          {enrollmentUserState?.is_complete == 0 && (
            <button
              onClick={() => {
                History(`/renewenroll/${childname}/${id}`);
              }}
            >
              Renew enrollment
            </button>
          )}

          {enrollmentUserState?.view_history && (
            <button onClick={EnrollmentHistory_Handler}>
              View enrollment history
            </button>
          )}
        </div>
      )}

      {EnrollmentHistory_Modal && (
        <div className="enrollment_history_container">
          <div className="enrollment_history_block">
            <div className="heading">
              <div className="back_btn_heading">
                {EnrollmentEmiHistory && (
                  <button
                    onClick={() => {
                      setEnrollmentEmiHistory(false);
                    }}
                  >
                    <i class="fa-solid fa-circle-arrow-left"></i>
                  </button>
                )}
                <h2>
                  {EnrollmentEmiHistory ? "Emi details" : "Enrollment history"}
                </h2>
              </div>
              <button
                onClick={() => {
                  setEnrollmentHistory_Modal(false);
                  setEnrollmentEmiHistory(false);
                }}
              >
                <i class="fa-solid fa-circle-xmark"></i>
              </button>
            </div>

            {EnrollmentEmiHistory ? (
              <div className="emi_details_table_wrapper">
                <table className="emi_details_table">
                  <thead>
                    <tr>
                      <th>Payment</th>
                      <th>Paid on</th>
                      <th>Payment mode</th>
                    </tr>
                  </thead>
                  {EnrollmentEmiData?.length > 0 ? (
                    <tbody>
                      {EnrollmentEmiData?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item?.payment_no}</td>
                            <td>{item?.total_paid_on}</td>
                            <td>{item?.payment_mode}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : (
                    <tbody>
                      <p>No data</p>
                    </tbody>
                  )}
                </table>
              </div>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th>Created on</th>
                    <th>Course name</th>
                    <th>Start date</th>
                    <th>End date</th>
                    <th>Down payment</th>
                    <th>installment amount</th>
                    <th>Payment method</th>
                    <th>Payment mode</th>
                    <th>Amount paid</th>
                    <th>Enroll ID</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {EnrollmentHistory?.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        className={item?.highlight == 1 ? "active" : ""}
                      >
                        <td>{item?.created_on}</td>
                        <td>{item?.course_name}</td>
                        <td>{item?.start_date}</td>
                        <td>{item?.end_date}</td>
                        <td>{item?.down_payment}</td>
                        <td>{item?.installment_amount}</td>
                        <td>{item?.payment_method}</td>
                        <td>{item?.payment_mode}</td>
                        <td>{item?.amount_paid}</td>
                        <td>{item?.enroll_id}</td>
                        <td>
                          <button
                            onClick={() =>
                              ViewEmiDetails(item?.si_no, item?.highlight)
                            }
                            disabled={
                              item?.payment_method == "Full payment"
                                ? true
                                : false
                            }
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      )}
    </>
  );
}
