import React, { memo } from "react";

const GroupSelectedchild = ({ SelectedChildList, RemoveChildHandler }) => {
  return (
    <div className="group_selected_child_container">
      <label htmlFor="">Selected child list</label>
      <div className="group_selected_child_item_container">
        {SelectedChildList?.length == 0 && (
          <p className="no_child">No child added</p>
        )}
        {SelectedChildList?.map((item, index) => {
          return (
            <div key={index} className="group_selected_child_item">
              <p>{item?.child_name}</p>
              <button onClick={() => RemoveChildHandler(item?.child_id)}>
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(GroupSelectedchild);
