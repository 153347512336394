import React, { useEffect, useLayoutEffect, useState } from "react";
import "./individualSchedule.css";
import { Autocomplete, TextField } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
// import { Calendar } from 'react-calendar';import AdapterDateFns from '@mui/lab/AdapterDateFns';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { useNavigate, useParams } from "react-router-dom";

import { DAYS, URL } from "../../../DROPDOWNDATA";
import { toast } from "react-toastify";

import useEditAll from "../../../customHooks/useEditall";
import Loader from "../../../components/Loader/Loader";

export default function IndividualSchedule({
  ChlidList,
  ScheduleType,
  EditAll,
  setEditAll,
  setScheduleType,
}) {
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  const History = useNavigate();
  const { childID } = useParams();
  const authKey = "Bearer " + localStorage.getItem("loggedToken");
  const [value, setValue] = React.useState(null);
  const [No0fClass, setNoofClass] = useState(2);

  const [showNumDate, setShowNumDate] = useState([]);
  const [disabled, setDisabled] = useState(true);

  const [coursesData, setCoursesData] = useState();

  const [enablerList, setEnablerList] = useState([
    { users_id: 474, users_firstname: "No enabler" },
  ]);
  const [is_AddSchedule, setis_AddSchedule] = useState("/add_schedule");
  const [loading, setloading] = useState(false);
  const [editAll_Data, setEditAll_Data, getEdit_All_Data] = useEditAll();
  // console.log(editAll_Data);
  const [weekTime, setWeekTime] = useState({
    day7: "19:35",
    day6: "18:32",
    day5: "17:32",
    day4: "16:30",
    day2: "14:00",
    day3: "15:30",
    day1: "13:00",
  });

  // ERROR MESSEGE
  const [Error_Message, setError_Message] = useState({
    No_of_class: false,
    No_of_class_message: "",
  });
  // STATE DATE AND END DATE , NO OF CLASSS
  const [START_DATE, setSTART_DATE] = useState();
  const [EndDate, setEndDate] = useState();
  const [Total_no_class, setTotal_no_class] = useState();
  // SHOW SELECT TYPE
  const [Show_Link_input, setShow_Link_input] = useState();
  let ARRAY_TIME = {};
  let DATAs = [];
  function forLoop() {
    DATAs = [];
    let Day_inputs = document.getElementsByName("day");
    let Time_inputs = document.getElementsByName("time");
    for (let i = 0; i < Day_inputs.length; i++) {
      let dateValue = "";
      switch (Day_inputs[i].value) {
        case "Sunday":
          dateValue = 0;
          break;
        case "Monday":
          dateValue = 1;
          break;
        case "Tuesday":
          dateValue = 2;
          break;
        case "Wednesday":
          dateValue = 3;
          break;
        case "Thursday":
          dateValue = 4;
          break;
        case "Friday":
          dateValue = 5;
          break;
        case "Saturday":
          dateValue = 6;
          break;

        default:
          dateValue = "";
          break;
      }

      DATAs.push({ day: dateValue, time: Time_inputs[i].value });
    }
    // console.log(DATAs);
  }

  // DATA TO DB
  const [sendToDB, setSendToDB] = useState({
    child_view_id: "",
    enabler_id: "",
    schedule_list: [],
    start_date_new: "",
    no_of_class: "",
    select_link_type: "",
  });

  const handleDialogTimeChange = (event) => {
    ARRAY_TIME[event.target.name] = event.target.value;
    setWeekTime(ARRAY_TIME);
    forLoop();
    // Get_end_date(sendToDB?.start_date_new);
  };

  const sameTime = (event) => {
    let time1_value = document.getElementsByName("time")[0].value;
    if (event.target.checked) {
      // console.log(time1_value);
      setWeekTime({
        day7: time1_value,
        day6: time1_value,
        day5: time1_value,
        day4: time1_value,
        day2: time1_value,
        day3: time1_value,
        day1: time1_value,
      });
      // console.log(weekTime);

      setDisabled(false);
    } else {
      setDisabled(true);
    }
    forLoop();
    // console.log(DATAs);
  };
  const currencies = [
    {
      value: 1,
      label: 1,
    },
    {
      value: 2,
      label: 2,
    },
    {
      value: 3,
      label: 3,
    },
    {
      value: 4,
      label: 4,
    },
    {
      value: 5,
      label: 5,
    },
    {
      value: 6,
      label: 6,
    },
    {
      value: 7,
      label: 7,
    },
  ];

  const storeNoClass = (e) => {
    // console.log(e.target.value);
    setNoofClass(e.target.value);

    let ArrayDate = [];
    for (let i = 0; i < e.target.value; i++) {
      ArrayDate.push(i);
    }
    setShowNumDate(ArrayDate);
    // forLoop();
    // forLoop();
    setTimeout(() => {
      Get_end_date(sendToDB?.start_date_new);
    }, 1000);
    console.log(DATAs);
  };
  async function Get_end_date(date) {
    forLoop();
    let data;
    await fetch(URL + "/calculate_end_date", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authKey,
      },
      body: JSON.stringify(
        (data = {
          class_timings: DATAs,
          start_date: date,
          number_of_classes: sendToDB?.no_of_class,
        })
      ),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        console.log(obj.body);
        if (obj?.body?.end_date) {
          setEndDate(obj?.body?.end_date);
        }

        // if (obj.body?.error) {
        //   setEndDate(coursesData?.end_date);
        // } else {
        //   setEndDate(obj?.body?.end_date);
        // }
        // if (!ScheduleType) {
        //   setEndDate(coursesData?.end_date);
        // }
      })
      .catch((err) => {
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  }

  async function getCourse_list(value) {
    let data;
    await fetch(URL + "/child_course", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authKey,
      },
      body: JSON.stringify((data = { child_view_id: value })),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        // console.log(obj.body);
        setCoursesData(obj.body);
        setEndDate(obj.body.end_date);
        setTotal_no_class(obj?.body?.total_classes);
        if (!ScheduleType) {
          setShow_Link_input(obj.body.renew);
        }
        let Date_reverse = obj?.body?.start_date;
        let Start_date_reverse = Date_reverse.split("-").reverse().join("-");
        // console.log("====================================");
        // console.log(Start_date_reverse);
        // console.log("====================================");
        setSTART_DATE(Start_date_reverse);
        setSendToDB(
          sendToDB,
          (sendToDB.start_date_new = Start_date_reverse),
          (sendToDB.no_of_class = obj?.body?.total_classes),
          (sendToDB.select_link_type = obj?.body?.renew ? "" : "false")
        );
      });
  }
  async function get_enabler_list(value) {
    console.log(value);
    let data;
    await fetch(URL + "/child_enabler_details", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authKey,
      },
      body: JSON.stringify((data = { child_view_id: value })),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        console.log(obj.body);
        setEnablerList(obj.body);
      });
  }
  const HandleInputs = (event, value) => {
    getCourse_list(value?.child_view_id);
    get_enabler_list(value?.child_view_id);
    setSendToDB(
      sendToDB,
      (sendToDB.child_view_id = value?.child_view_id),
      (sendToDB.enabler_id = sendToDB.enabler_id),
      (sendToDB.schedule_list = sendToDB.schedule_list)
    );
    if (document.querySelector('.no_of_classes')) {
      document.querySelector('.no_of_classes').classList.remove('error')
    }
    setError_Message({ No_of_class: false, No_of_class_message: "" });
  };

  const Handle_Enabler_Input = (event, value) => {
    // console.log(enablerList);
    // console.log(event);
    // console.log(value);
    setSendToDB(
      sendToDB,

      (sendToDB.child_view_id = sendToDB.child_view_id),
      (sendToDB.enabler_id = value.users_id),
      (sendToDB.schedule_list = sendToDB.schedule_list)
    );
  };

  const HandleDays = (events, value) => {
    forLoop();
    Get_end_date(sendToDB?.start_date_new);
  };
  const Select_All_Enabler = (event) => {
    // console.log(event.target.checked);
    if (event.target.checked) {
      async function Get_Enabler_list() {
        await fetch(URL + "/pending_child_enabler_list", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: authKey,
          },
        })
          .then((r) =>
            r.json().then((data) => ({ status: r.status, body: data }))
          )
          .then((obj) => {
            // console.log(obj.body);
            setEnablerList(obj.body.enabler_list);
          });
      }
      Get_Enabler_list();
    } else {
      get_enabler_list(sendToDB.child_view_id);
    }
  };
  const createSchedule = () => {
    console.log(sendToDB);
    forLoop();
    // console.log(DATAs);
    function callDB() {
      async function AddSchedule() {
        setloading(true);
        let Link_type = "";
        if (sendToDB?.select_link_type == "false") {
          Link_type = false;
        } else {
          Link_type = true;
        }
        await fetch(URL + is_AddSchedule, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: authKey,
          },
          body: JSON.stringify({
            child_view_id: sendToDB.child_view_id,
            enabler_id: sendToDB.enabler_id,
            schedule_list: DATAs,
            start_date: sendToDB?.start_date_new,
            number_of_classes: sendToDB?.no_of_class,
            renew: coursesData?.renew ? Link_type : false,
          }),
        })
          .then((r) =>
            r.json().then((data) => ({ status: r.status, body: data }))
          )
          .then((obj) => {
            console.log(obj.body);

            if (obj.body == "Meeting already created") {
              setloading(false);
              toast.warn("Meeting already created");
              toast.clearWaitingQueue();
              History(
                `/individualSchedule/${btoa(sendToDB.child_view_id)}`
              );
            } else {
              setloading(false);
              toast.success("Meeting Created Successfully");
              toast.clearWaitingQueue();
              History(
                `/individualSchedule/${btoa(sendToDB.child_view_id)}`
              );
              setEditAll({});
              setScheduleType(false);
              if (is_AddSchedule != "/add_schedule") {
                setis_AddSchedule("/add_schedule");
                window.location.reload();
              }
            }
          });
        // console.log(DATAs);
        // console.log("worked");
      }
      if (sendToDB.child_view_id == "") {
        toast.warn("Kindly select the child");
        toast.clearWaitingQueue();
        return false;
      }
      if (sendToDB.enabler_id == "") {
        toast.warn("Kindly select the enabler");
        toast.clearWaitingQueue();
        return false;
      }
      if (sendToDB.no_of_class == "") {
        toast.warn("Kindly fill the total no of class");
        toast.clearWaitingQueue();
        return false;
      }
      if (parseInt(sendToDB.no_of_class) < parseInt(No0fClass)) {
        toast.warn("Total class cannot be less than class per week");
        toast.clearWaitingQueue();
        return;
      }
      if (!ScheduleType) {
        if (sendToDB.select_link_type == "") {
          toast.warn("Kindly fill the link type");
          toast.clearWaitingQueue();
          return false;
        }
      }

      if (DATAs.length < 0) {
        toast.warn("Kindly add the classes");
        toast.clearWaitingQueue();
        return false;
      }
      // if (
      //   sendToDB.child_view_id != "" &&
      //   sendToDB.enabler_id != "" &&
      //   sendToDB.no_of_class != "" &&
      //   sendToDB.select_link_type != "" &&
      //   DATAs.length > 0
      // ) {
      //   AddSchedule();
      //   // alert("ok");
      // }
      //  else {
      //   // console.log(sendToDB.child_view_id);
      //   // console.log(sendToDB.enabler_id);
      //   // console.log(DATAs);
      //   toast.warn("Kindly fill the fields");
      //   toast.clearWaitingQueue();
      // }
      AddSchedule();
      // alert("ok");
    }
    callDB();

    console.log(DATAs);
    console.log(sendToDB);
  };

  // useEffect(() => {
  //   forLoop();
  // }, [showNumDate]);

  useLayoutEffect(() => {
    if (ScheduleType) {
      console.log(ChlidList);
      setis_AddSchedule("/edit_schedule");
      setSendToDB(
        sendToDB,
        (sendToDB.child_view_id = ChlidList[0]?.child_view_id)
      );
      getCourse_list(ChlidList[0]?.child_view_id);
      // get_enabler_list(ChlidList[0]?.child_view_id);

      setEnablerList([
        {
          users_id: EditAll?.users_id,

          users_firstname: EditAll.users_firstname,
        },
      ]);
      storeNoClass({
        target: {
          value: EditAll.meeting_details.length,
        },
      });
      console.log("====================================");
      console.log(EditAll?.meeting_details);
      console.log("====================================");
      setShowNumDate([]);
      const FilterOnly = EditAll?.meeting_details?.map((item, index) => {
        setShowNumDate((prev) => {
          return [...prev, item.day];
        });
      });
      // setShowNumDate(FilterOnly);
      console.log(showNumDate);

      const FilterTime = EditAll?.meeting_details?.map((item, index) => {
        return item.time;
      });
      FilterTime?.forEach((item, index) => {
        console.log("day" + (index + 1));
        setWeekTime(weekTime, (weekTime["day" + (index + 1)] = item));
      });
      // setCoursesData(coursesData, (coursesData.renew = false));
    } else {
      setShowNumDate([1, 3]);
    }
  }, [ScheduleType, ChlidList, EditAll]);

  const NumberOfClass = (e) => {
    console.log("====================================");
    console.log(e.target.value);
    console.log("====================================");
    setTotal_no_class(e.target.value);
    if (e.target.value > 0) {
      if (e.target.value > coursesData?.enrolled_claseess * 2) {
        setSendToDB(sendToDB, (sendToDB.no_of_class = ""));
        setError_Message({
          No_of_class: true,
          No_of_class_message: `You cannot add classes more than ${coursesData?.enrolled_claseess * 2
            }`,
        });
        e.target.classList.add("error");
      } else if (e.target.value < coursesData?.total_classes) {
        setSendToDB(sendToDB, (sendToDB.no_of_class = ""));
        setError_Message({
          No_of_class: true,
          No_of_class_message: `Classes cannot be reduced`,
        });
        e.target.classList.add("error");
      } else {
        setError_Message({ No_of_class: false, No_of_class_message: "" });
        setSendToDB(sendToDB, (sendToDB.no_of_class = e.target.value));
        e.target.classList.remove("error");
        Get_end_date(sendToDB?.start_date_new);
      }
    } else {
      setSendToDB(sendToDB, (sendToDB.no_of_class = ""));
      e.target.classList.add("error");
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="individualSchedule_container">
      {/* <p>Program Name : Something</p> */}
      <div className="block">
        <div className="element-container">
          {ScheduleType ? (
            <h3 style={{ textAlign: "center" }}>{ChlidList[0]?.child_name}</h3>
          ) : (
            <Autocomplete
              disablePortal
              id="combo-box Default_name"
              options={ChlidList}
              getOptionLabel={(option) => {
                return option.child_name;
              }}
              // {ScheduleType ? def}
              // value={ChlidList[0]}
              // defaultValue={ScheduleType ? ChlidList[0] : ChlidList[0]}
              onChange={HandleInputs}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Select Child" />
              )}
            />
          )}
          {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Select Date"
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} helperText={"MM/DD/YYYY"} />
              )}
            />
          </LocalizationProvider> */}

          {sendToDB?.child_view_id ? (
            <div className="childPreview_container">
              <div className="preview_block">
                <h4>Child id</h4>
                <p>{sendToDB?.child_view_id}</p>
              </div>
              <div className="preview_block">
                <h4>Course Selected</h4>
                <p>{coursesData?.course_name}</p>
              </div>
              <div className="preview_block">
                {/* <h4>Start Date</h4>
                <input
                  type={"date"}
                  disabled={EditAll?.start_date_disable || false}
                  // defaultValue={sendToDB.start_date_new}
                  value={START_DATE}
                  min={coursesData?.enrollment_start_date}
                  onChange={(events) => {
                    setSTART_DATE(events.target.value);
                    setSendToDB(
                      sendToDB,
                      (sendToDB.start_date_new = events.target.value)
                    );
                    Get_end_date(events.target.value);
                  }}
                /> */}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={"Start date"}
                    value={START_DATE}
                    inputFormat={"dd/MM/yyyy"}
                    minDate={coursesData?.enrollment_start_date}
                    onChange={(value) => {
                      setSTART_DATE(formatDate(value));
                      setSendToDB(
                        sendToDB,
                        (sendToDB.start_date_new = formatDate(value))
                      );
                      Get_end_date(formatDate(value));
                    }}
                    renderInput={(params) => (
                      <TextField
                        autoComplete="off"
                        {...params}
                        helperText={params?.inputProps?.placeholder}
                      />
                    )}
                  />
                </LocalizationProvider>


              </div>
              <div className="preview_block">
                <h4>End Date</h4>
                <p>{EndDate}</p>
                <small>DD-MM-YYYY</small>
                {/* <input type={"date"} value="2022-05-02" /> */}
              </div>
              <div className="preview_block">
                <h4>Number of Classes</h4>
                {/* <p>max - 100</p> */}
                {/* <p>{coursesData?.total_classes}</p> */}
                <input
                  className="no_of_classes"
                  type="number"
                  defaultValue={sendToDB?.no_of_class}
                  value={Total_no_class}
                  min={No0fClass}
                  max={coursesData?.enrolled_claseess * 2}
                  onInput={(events) => NumberOfClass(events)}
                  pattern="[0-9]"
                />{" "}
                <br />
                {Error_Message.No_of_class && (
                  <small className="no_of_class_error_message">
                    {Error_Message.No_of_class_message}
                  </small>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="element-container selectDate_and_time">
        <div className="no_ofClass_container">
          <TextField
            id="standard-select-currency-native"
            select
            label="No. of Class per week"
            value={No0fClass}
            onChange={storeNoClass}
            sx={{ width: 300 }}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
          >
            {currencies.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        </div>

        <div className="dayTime_container">
          <div className="day_time_container">
            {showNumDate?.map((item, index) => {
              return (
                <div className="dayTime_item_holder" key={index}>
                  <p className="heading">Day {index + 1}</p>
                  <Autocomplete
                    disablePortal
                    id="combo-box"
                    options={DAYS}
                    getOptionLabel={(option) => {
                      return option.label;
                    }}
                    defaultValue={DAYS[item]}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Days"
                        name={`day`}
                        onSelect={(events) => HandleDays(events, value)}
                      />
                    )}
                  />
                  {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      label="Select Time"
                      value={}
                      onChange={handleDialogTimeChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider> */}

                  <input
                    type="time"
                    name={`time`}
                    id=""
                    className={disabled ? "TimePicker" : "TimePicker dis"}
                    value={weekTime[`day${index + 1}`]}
                    onChange={(events) => handleDialogTimeChange(events)}
                    placeholder="00:00"
                  />
                </div>
              );
            })}
          </div>
          <div className="link_and_checkbox">
            {Show_Link_input && (
              <div className="select_class_link">
                <div className="select_input_container">
                  <input
                    type="radio"
                    name="select_link"
                    id="existinglink"
                    value="false"
                    onChange={(e) => {
                      setSendToDB(
                        sendToDB,
                        (sendToDB.select_link_type = "false")
                      );
                    }}
                  />
                  <label htmlFor="existinglink">Use existing link</label>
                </div>
                <div className="select_input_container">
                  <input
                    type="radio"
                    name="select_link"
                    id="newLink"
                    value="true"
                    onChange={(e) => {
                      setSendToDB(
                        sendToDB,
                        (sendToDB.select_link_type = "true")
                      );
                    }}
                  />
                  <label htmlFor="newLink">New link</label>
                </div>
              </div>
            )}

            <div className="same_time_checkBx">
              <FormControlLabel
                control={<Checkbox />}
                label="Same time for all days"
                onChange={sameTime}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="block">
        {/* <p className="heading">Select Days</p> */}

        <div
          className="element-container"
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Autocomplete
            disablePortal
            id="combo-box"
            options={enablerList}
            getOptionLabel={(option) => {
              return option.users_firstname;
            }}
            onChange={Handle_Enabler_Input}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Select Enabler" />
            )}
          />
          <div style={{ marginLeft: "20px" }}>
            <FormControlLabel
              control={<Checkbox />}
              label="Select from all enablers"
              onChange={(event) => Select_All_Enabler(event)}
            />
          </div>
        </div>
      </div>
      <div
        className="create_schedule_btns_holder"
        style={{ marginTop: "10px" }}
      >
        <button
          className="btn btn1"
          onClick={() => {
            setScheduleType(false);
            History(`/scheduling`);
            window.location.reload();
          }}
        >
          Cancel
        </button>
        <button className="btn btn2" onClick={createSchedule}>
          Create Schedule
        </button>
      </div>
    </div>
  );
}
