import React, { memo } from "react";

const GroupChildselect = ({
  Childlist,
  SelectedChild,
  ChildSelectHandler,
  AddChildHandler,
  SelectedChildList,
}) => {
  return (
    <div className="group_child_add_container">
      <label htmlFor="groupchild">Select child and Add</label>

      <div className="group_child_select_btn">
        <select
          name=""
          id="groupchild"
          value={JSON.stringify(SelectedChild)}
          onChange={ChildSelectHandler}
        >
          <option value="">Select child</option>
          {Childlist?.map((item, index) => {
            return (
              <option key={index} value={JSON.stringify(item)}>
                {item?.child_name}
              </option>
            );
          })}
        </select>

        <button
          onClick={AddChildHandler}
          className={SelectedChildList?.length == 6 ? "disable" : ""}
        >
          <i className="fa-solid fa-circle-plus"></i>Add
        </button>
      </div>
    </div>
  );
};

export default memo(GroupChildselect);
