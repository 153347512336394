import React from "react";
import "./Listening.css";

const Listening = ({ Data }) => {
  const Download = (url) => {
    const element = document.createElement("iframe");
    element.src = url;
    document.body.appendChild(element);
  };
  return (
    <div>
      <table className="listening_table_container graded_table_common">
        <thead>
          <tr>
            <th>Date</th>
            <th>Time</th>
            <th>Phase</th>
            <th>Grade</th>
            <th>Inference</th>
            <th>Total score</th>
            <th>Status</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
          {Data?.map((item, index) => {
            return (
              <tr key={index}>
                <td>{item?.created_date}</td>
                <td>{item?.created_time}</td>
                <td>{item?.phase}</td>
                <td>{item?.grade}</td>
                <td>{item?.inference}</td>
                <td>{item?.total_score}</td>
                <td>{item?.status}</td>
                <td>
                  <button
                    onClick={() => Download(item?.download)}
                    className={`${
                      item?.download ? "" : "pointer-events-none opacity-30"
                    }`}
                  >
                    <i className="text-[30px] fa-regular fa-circle-down"></i>
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {Data?.length == 0 && (
        <p style={{ textAlign: "center", fontSize: "22px", fontWeight: "600" }}>
          No data
        </p>
      )}
    </div>
  );
};

export default Listening;
