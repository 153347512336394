import React, { useEffect, useState, useRef } from "react";
import "./Childdetails.css";
import Topbar from "../childPage/topMenu/TopMenu";
import { useParams } from "react-router-dom";
import { URL } from "../../DROPDOWNDATA";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../components/Loader/Loader";
import UploadImage from "../../images/upload.png";
import useEnablerList from "../../customHooks/useEnablerList";
const Childdetails = (props) => {
  const [Enabler_List] = useEnablerList();
  const Pdf_ref = useRef();
  const { id, childname } = useParams();
  const CHILD_ID = atob(id);

  const [ChildDetails, setChildDetails] = useState({});
  const [BoradList, setBoradList] = useState([]);

  const [Child_detail_edit, setChild_detail_edit] = useState(true);
  const [ParentDetails_edit, setParentDetails_edit] = useState(true);
  const [GeneralDetail_edit, setGeneralDetail_edit] = useState(true);
  const [EnablerDetail_edit, setEnablerDetail_edit] = useState(true);
  const [GradeDetail_edit, setGradeDetail_edit] = useState(true);

  const [Savechange, setSavechange] = useState(false);

  const [IsLoad, setIsLoad] = useState(false);

  const [ShowPdf_upload, setShowPdf_upload] = useState(false);
  const [Pdf_source, setPdf_source] = useState();
  const [PdfError_message, setPdfError_message] = useState({
    status: false,
    message: "",
  });

  const [EnablerView, setEnablerView] = useState(false);

  async function GetBoardList() {
    await fetch(URL + "/board_details", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        setBoradList(obj.body);
        UpdateSelect();
      })
      .catch((err) => {
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  }

  async function getChild_data() {
    let item = {
      child_view_id: CHILD_ID,
    };

    setIsLoad(true);
    await fetch(URL + "/child_details", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(item),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        setChildDetails(obj.body);
        setIsLoad(false);
        setSavechange(false);
        setChild_detail_edit(true);
        setParentDetails_edit(true);
        setGeneralDetail_edit(true);
        setEnablerDetail_edit(true);
        setGradeDetail_edit(true);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
        setIsLoad(false);
      });
  }
  let child_gender_ = document.querySelector("#child_gender_");
  const UpdateSelect = () => {
    // child_gender_.querySelectorAll("option").forEach((item) => {
    //   if (item.value == ChildDetails?.child_gender) {
    //     item.setAttribute("selected", true);
    //   }
    // });
  };

  const EditDetails = async () => {
    let item = {
      child_view_id: CHILD_ID,
      child_details: 1,
      parent_details: 1,
      general_details: 1,
      child_name: ChildDetails?.child_name,
      child_dob: ChildDetails?.dob,
      child_gender: ChildDetails?.child_gender,
      grade: parseInt(ChildDetails?.child_standard),
      child_school_board: ChildDetails?.school_board,
      parent_name: ChildDetails?.parent_name,
      alt_number: ChildDetails?.parent_alt_mobile,
      users_email: ChildDetails?.parent_email,
      child_assessment_device: ChildDetails?.child_assessment_device,
      child_internet_connectivity: ChildDetails?.child_internet_connectivity,
      school_name: ChildDetails?.school_name,
      child_enabler_id: ChildDetails?.enabler_id,
      child_updated_grade: ChildDetails?.child_updated_grade,
    };

    setIsLoad(true);
    await fetch(URL + "/edit_details", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(item),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        GetBoardList();
        getChild_data();
        setChild_detail_edit(true);
        setParentDetails_edit(true);
        setGeneralDetail_edit(true);
        setEnablerDetail_edit(true);
        setGradeDetail_edit(true);
        toast.success("Details Added Successfully");
        toast.clearWaitingQueue();
      })
      .catch((err) => {
        setIsLoad(false);
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  };

  useEffect(() => {
    props.setBreadcrumItem([
      { name: "Student List", link: `/` },
      {
        name: atob(childname),
        link: `/child_details/${childname}/${id}`,
      },
    ]);
    GetBoardList();
    getChild_data();
  }, [id]);

  function Details_layout({ left, right, isEdit }) {
    return (
      <div className="details_list">
        <p className="left">{left}</p>
        <span>:</span>
        <p className="right">
          {right}{" "}
          {isEdit && right && (
            <button>
              <i class="fa-solid fa-pen"></i>
            </button>
          )}
        </p>
      </div>
    );
  }

  const getAge = (birthDate) =>
    Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e10);

  let Percentage = "";
  if (ChildDetails?.child_created_on?.length > 4) {
    Percentage = "0%";

    if (ChildDetails?.parent_eval_date?.length > 4) {
      Percentage = "18%";

      if (ChildDetails?.level_1_completed_on?.length > 4) {
        Percentage = "36%";

        if (ChildDetails?.level_2_completed_on?.length > 4) {
          Percentage = "50%";

          if (ChildDetails?.sent_to_parent?.length > 4) {
            Percentage = "68%";

            if (ChildDetails?.enrolled_on?.length > 4) {
              Percentage = "90%";
            }
          }
        }
      }
    }
  }

  const TriggerFileupload = () => {
    Pdf_ref.current.click();
  };

  const inputFile = (e) => {
    let File = e.target.files[0];
    setPdf_source(e.target.files[0]);

    let Type = File?.type.split("/")[1];
    if (Type == "pdf") {
      setPdfError_message({
        PdfError_message,
        status: false,
        message: "File upload successfully",
      });
    } else {
      setPdfError_message({
        PdfError_message,
        status: true,
        message: "Please upload pdf format",
      });
    }
  };

  const UploadPdfToDB = () => {
    if (Pdf_source) {
      setIsLoad(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("loggedToken")
      );

      var formdata = new FormData();
      formdata.append("uploadfiles", Pdf_source);
      formdata.append("child_view_id", CHILD_ID);
      formdata.append("image_name", Pdf_source?.name);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(URL + "/upload_assessment", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          setIsLoad(false);
          setShowPdf_upload(false);
          setPdf_source();
          setPdfError_message({
            status: "",
            message: "",
          });
          toast.success("File added successfully");
          toast.clearWaitingQueue();
        })
        .catch((error) => {
          setIsLoad(false);
          toast.error("Failed to upload");
          toast.clearWaitingQueue();
        });
    } else {
      toast.error("Upload pdf file");
      toast.clearWaitingQueue();
    }
  };

  if (IsLoad) {
    return <Loader />;
  }

  return (
    <div>
      <Topbar id={id} childname={childname} />
      {/* <div className="child_details_container">
        <div className="details_container_list">
          <Details_layout
            left="Child Name"
            right={ChildDetails?.child_name}
            isEdit={false}
          />
          <Details_layout
            left="Parent Name"
            right={ChildDetails?.child_parent_name}
            isEdit={false}
          />
          <Details_layout
            left="Gender"
            right={ChildDetails?.child_gender}
            isEdit={false}
          />
          <Details_layout
            left="D.O.B"
            right={ChildDetails?.child_dob}
            isEdit={false}
          />
          <Details_layout
            left="Class"
            right={ChildDetails?.child_class}
            isEdit={false}
          />
          <Details_layout
            left="Email"
            right={ChildDetails?.child_parent_email}
            isEdit={false}
          />
          <Details_layout
            left="Mobile no"
            right={ChildDetails?.child_parent_mobile_no}
            isEdit={false}
          />
          <Details_layout
            left="Enabler name"
            right={ChildDetails?.child_enabler_name}
            isEdit={true}
          />
          <Details_layout
            left="Address"
            right={ChildDetails?.child_address}
            isEdit={false}
          />
          <Details_layout
            left="State"
            right={ChildDetails?.child_state}
            isEdit={false}
          />
        </div>
      </div> */}

      <div className="child_details_block">
        <div className="child_roadmap_container">
          <span id="rodamap_progress"></span>
          <span
            id="rodamap_progress_active"
            style={{ width: Percentage }}
          ></span>

          <RoadmapCard
            date={ChildDetails?.child_created_on}
            name="Registered"
            completed={ChildDetails?.child_created_on?.length > 4 ? 1 : 0}
          />
          <RoadmapCard
            date={ChildDetails?.parent_eval_date}
            name="Parent questionnaire"
            completed={ChildDetails?.parent_eval_date?.length > 4 ? 1 : 0}
          />
          <RoadmapCard
            date={ChildDetails?.level_1_completed_on}
            name="Level 1"
            completed={ChildDetails?.level_1_completed_on?.length > 4 ? 1 : 0}
          />
          <RoadmapCard
            date={ChildDetails?.level_2_completed_on}
            name="Level 2"
            completed={ChildDetails?.level_2_completed_on?.length > 4 ? 1 : 0}
          />
          <RoadmapCard
            date={ChildDetails?.sent_to_parent}
            name="Screening report shared"
            completed={ChildDetails?.sent_to_parent?.length > 4 ? 1 : 0}
          />
          <RoadmapCard
            date={ChildDetails?.enrolled_on}
            name="Enrolled for intervention"
            completed={ChildDetails?.enrolled_on?.length > 4 ? 1 : 0}
          />
        </div>

        <div className="child_detail_row">
          {/* CHILD DETAILS */}
          <div className="data_container">
            <div className="data_heading">
              <h3>Child details</h3>
              <button
                onClick={() => {
                  setChild_detail_edit(false);
                  setSavechange(true);
                }}
              >
                <i class="fa-solid fa-pen-to-square"></i>
                <p>{Child_detail_edit ? "Edit" : "Edit"}</p>
              </button>
            </div>

            <div className="child_detail_data_block">
              <InputComponent
                name={"Name"}
                value={ChildDetails}
                setValue={setChildDetails}
                valuename="child_name"
                disable={Child_detail_edit}
              />

              <div className="child_data_input">
                <p>Gender</p>
                <select
                  disabled={Child_detail_edit}
                  name=""
                  id="child_gender_"
                  value={ChildDetails?.child_gender}
                  defaultValue={ChildDetails?.child_gender}
                  onChange={(e) => {
                    setChildDetails({
                      ...ChildDetails,
                      ["child_gender"]: e.target.value,
                    });
                  }}
                >
                  <option value="2">Boy</option>
                  <option value="3">Girl</option>
                </select>
              </div>

              <div className="child_data_input">
                <p>DOB</p>
                <input
                  disabled={Child_detail_edit}
                  type="date"
                  value={ChildDetails?.dob}
                  defaultValue={ChildDetails?.dob}
                  onChange={(e) => {
                    setChildDetails({
                      ...ChildDetails,
                      ["dob"]: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="child_data_input">
                <p>Age</p>
                <input
                  type="text"
                  value={
                    ChildDetails?.dob != "" && ChildDetails?.dob != null
                      ? getAge(ChildDetails?.dob)
                      : 0
                  }
                  disabled
                />
              </div>

              <InputComponent
                name={"School"}
                value={ChildDetails}
                setValue={setChildDetails}
                valuename="school_name"
                disable={
                  ChildDetails?.to_edit
                    ? Child_detail_edit
                      ? true
                      : false
                    : true
                }
              />
              <div className="child_data_input">
                <p>Board</p>
                <select
                  disabled={
                    ChildDetails?.to_edit
                      ? Child_detail_edit
                        ? true
                        : false
                      : true
                  }
                  name=""
                  id=""
                  value={ChildDetails?.school_board}
                  defaultValue={ChildDetails?.school_board}
                  onChange={(e) => {
                    setChildDetails({
                      ...ChildDetails,
                      ["school_board"]: e.target.value,
                    });
                  }}
                >
                  {BoradList?.map((item, index) => {
                    return (
                      <option key={index} value={item?.board_id}>
                        {item?.board}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>

          {/* PARENT DETAILS */}
          <div>
            <div className="data_container parent_block">
              <div className="data_heading">
                <h3>Parent details</h3>
                <button
                  onClick={() => {
                    setParentDetails_edit(false);
                    setSavechange(true);
                  }}
                >
                  <i class="fa-solid fa-pen-to-square"></i>
                  <p>{ParentDetails_edit ? "Edit" : "Edit"}</p>
                </button>
              </div>

              <div className="child_detail_data_block">
                <InputComponent
                  name={"Name"}
                  value={ChildDetails}
                  setValue={setChildDetails}
                  valuename="parent_name"
                  disable={ParentDetails_edit}
                />
                <InputComponent
                  name={"Mobile number"}
                  value={ChildDetails}
                  setValue={setChildDetails}
                  valuename="parent_mobile"
                  disable={true}
                />
                <InputComponent
                  name={"Alternate number"}
                  value={ChildDetails}
                  setValue={setChildDetails}
                  valuename="parent_alt_mobile"
                  disable={ParentDetails_edit}
                />
                <InputComponent
                  name={"Email address"}
                  value={ChildDetails}
                  setValue={setChildDetails}
                  valuename="parent_email"
                  disable={ParentDetails_edit}
                />
              </div>
            </div>
            <div className="data_container parent_block">
              <div className="data_heading">
                <h3>Grade details</h3>
                <button
                  onClick={() => {
                    setGradeDetail_edit(false);
                    setSavechange(true);
                  }}
                >
                  {" "}
                  <i class="fa-solid fa-pen-to-square"></i>
                  <p>{GradeDetail_edit ? "Edit" : "Edit"}</p>
                </button>
              </div>
              <div className="child_data_input grade_inputs">
                <p>Grade as per DL Parent registration</p>
                <select
                  disabled={
                    ChildDetails?.to_edit
                      ? GradeDetail_edit
                        ? true
                        : false
                      : true
                  }
                  name=""
                  id=""
                  value={ChildDetails?.child_standard}
                  defaultValue={ChildDetails?.child_standard}
                  onChange={(e) => {
                    setChildDetails({
                      ...ChildDetails,
                      ["child_standard"]: e.target.value,
                    });
                  }}
                >
                  <option value="1">Grade 1</option>
                  <option value="2">Grade 2</option>
                  <option value="3">Grade 3</option>
                  <option value="4">Grade 4</option>
                  <option value="5">Grade 5</option>
                  <option value="6">Grade 6</option>
                  <option value="7">Grade 7</option>
                  <option value="8">Grade 8</option>
                  <option value="9">Grade 9</option>
                  <option value="10">Grade 10</option>
                  <option value="11">Grade 11</option>
                  <option value="12">Grade 12</option>
                </select>
              </div>
              <div className="child_data_input grade_inputs">
                <p>Grade as per intake form</p>
                <select
                  disabled={true}
                  name=""
                  id=""
                  value={ChildDetails?.child_original_grade}
                  defaultValue={ChildDetails?.child_original_grade}
                >
                  <option value="">Intake grade</option>
                  <option value="1">Grade 1</option>
                  <option value="2">Grade 2</option>
                  <option value="3">Grade 3</option>
                  <option value="4">Grade 4</option>
                  <option value="5">Grade 5</option>
                  <option value="6">Grade 6</option>
                  <option value="7">Grade 7</option>
                  <option value="8">Grade 8</option>
                  <option value="9">Grade 9</option>
                  <option value="10">Grade 10</option>
                  <option value="11">Grade 11</option>
                  <option value="12">Grade 12</option>
                </select>
              </div>
              <div className="child_data_input grade_inputs">
                <p>Current grade</p>
                <select
                  disabled={GradeDetail_edit ? true : false}
                  name=""
                  id=""
                  value={ChildDetails?.child_updated_grade}
                  defaultValue={ChildDetails?.child_updated_grade}
                  onChange={(e) => {
                    setChildDetails({
                      ...ChildDetails,
                      ["child_updated_grade"]: e.target.value,
                    });
                  }}
                >
                  <option value="">Current grade</option>
                  <option value="1">Grade 1</option>
                  <option value="2">Grade 2</option>
                  <option value="3">Grade 3</option>
                  <option value="4">Grade 4</option>
                  <option value="5">Grade 5</option>
                  <option value="6">Grade 6</option>
                  <option value="7">Grade 7</option>
                  <option value="8">Grade 8</option>
                  <option value="9">Grade 9</option>
                  <option value="10">Grade 10</option>
                  <option value="11">Grade 11</option>
                  <option value="12">Grade 12</option>
                </select>
              </div>
            </div>
          </div>

          {/* GENERAL DETAILS */}
          <div>
            <div className="data_container parent_block">
              <div className="data_heading">
                <h3>General details</h3>
                <button
                  onClick={() => {
                    setGeneralDetail_edit(false);
                    setSavechange(true);
                  }}
                >
                  <i class="fa-solid fa-pen-to-square"></i>
                  <p>{GeneralDetail_edit ? "Edit" : "Edit"}</p>
                </button>
              </div>

              <div className="child_attachment_upload">
                <p>Has your child has been assessed before?</p>
                <p style={{ fontSize: "12px", margin: "10px 0" }}>
                  {ChildDetails?.child_assessed == 1 ? "yes" : "No"}
                </p>
                {ChildDetails?.child_assessed == 1 && (
                  <div style={{ display: "flex" }}>
                    {ChildDetails?.child_assessment_report?.length > 6 && (
                      <a
                        href={`${ChildDetails?.child_assessment_report}`}
                        target="_blank"
                      >
                        View
                      </a>
                    )}
                    <button
                      onClick={() => {
                        setShowPdf_upload(true);
                      }}
                    >
                      Upload
                    </button>
                  </div>
                )}
              </div>

              <div className="child_detail_data_block">
                <div className="child_data_input">
                  <p>Available device</p>
                  <select
                    disabled={GeneralDetail_edit}
                    name=""
                    id=""
                    value={ChildDetails?.child_assessment_device}
                    defaultValue={ChildDetails?.child_assessment_device}
                    onChange={(e) => {
                      setChildDetails({
                        ...ChildDetails,
                        ["child_assessment_device"]: e.target.value,
                      });
                    }}
                  >
                    <option value="">Not selected</option>
                    <option value="Mobile Device">Mobile Device</option>
                    <option value="Laptop">Laptop</option>
                    <option value="Tablet">Tablet</option>
                  </select>
                </div>
                <div className="child_data_input">
                  <p>Internet connection</p>
                  <select
                    disabled={GeneralDetail_edit}
                    name=""
                    id=""
                    value={ChildDetails?.child_internet_connectivity}
                    defaultValue={ChildDetails?.child_internet_connectivity}
                    onChange={(e) => {
                      setChildDetails({
                        ...ChildDetails,
                        ["child_internet_connectivity"]: e.target.value,
                      });
                    }}
                  >
                    <option value="">Not selected</option>
                    <option value="Mobile Data">Mobile data</option>
                    <option value="LAN / WIFI">LAN / Wifi</option>
                  </select>
                </div>
                <div className="child_data_input">
                  <p>Referred by</p>
                  <input type="text" value={ChildDetails?.refferal} disabled />
                </div>
              </div>
            </div>

            <div className="data_container parent_block">
              <div className="data_heading">
                <h3>Enabler details</h3>
                {ChildDetails?.to_edit && (
                  <button
                    onClick={() => {
                      setEnablerDetail_edit(false);
                      setSavechange(true);
                    }}
                  >
                    <i class="fa-solid fa-pen-to-square"></i>
                    <p>{EnablerDetail_edit ? "Edit" : "Edit"} </p>
                  </button>
                )}
              </div>
              {!ChildDetails?.to_edit && (
                <table className="enabler_details_table">
                  <thead>
                    <tr>
                      <th>Enabler list</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <button
                          onClick={() => {
                            setEnablerView(true);
                          }}
                        >
                          <i class="fa-solid fa-eye"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}

              {ChildDetails?.to_edit && (
                <select
                  className="enabler_name_edit_container"
                  defaultValue={ChildDetails?.enabler_id}
                  disabled={EnablerDetail_edit}
                  onChange={(e) => {
                    setChildDetails({
                      ...ChildDetails,
                      ["enabler_id"]: e.target.value,
                    });
                  }}
                >
                  {Enabler_List?.map((item, index) => {
                    return (
                      <option value={item?.users_id} key={index}>
                        {item?.users_firstname}
                      </option>
                    );
                  })}
                </select>
              )}
            </div>

            {Savechange && (
              <div className="child_details_actions_btn">
                <button
                  onClick={() => {
                    getChild_data();
                  }}
                >
                  Discard changes
                </button>
                <button
                  onClick={() => {
                    EditDetails();
                  }}
                >
                  Save changes
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {ShowPdf_upload && (
        <div className="pdf_upload_container">
          <div className="pdf_section">
            <input
              type="file"
              name=""
              id=""
              accept=".pdf"
              ref={Pdf_ref}
              className="pdf_file_upload"
              onChange={(e) => {
                inputFile(e);
              }}
            />

            <div className="pdf_image_wrapper" onClick={TriggerFileupload}>
              <img src={UploadImage} alt="" />
            </div>
            <p style={{ color: PdfError_message?.status ? "red" : "#007872" }}>
              {PdfError_message?.message}
            </p>

            <button onClick={UploadPdfToDB}>Upload</button>
            <button
              className="cancel"
              onClick={() => {
                setShowPdf_upload(false);
                setPdf_source();
                setPdfError_message({
                  status: "",
                  message: "",
                });
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {EnablerView && (
        <div className="enablerdetail_list_view_container">
          <div className="enablerdetail_list_view_block">
            <div className="heading">
              <h2>Enabler list</h2>
              <button
                onClick={() => {
                  setEnablerView(false);
                }}
              >
                <i class="fa-solid fa-circle-xmark"></i>
              </button>
            </div>

            <ol>
              {ChildDetails?.enabler_name?.split(",")?.map((item, index) => {
                return (
                  <li key={index}>
                    {index + 1} . {item}
                  </li>
                );
              })}
            </ol>
          </div>
        </div>
      )}
    </div>
  );
};

const InputComponent = ({
  name,
  value,
  setValue,
  valuename,
  disable = false,
}) => {
  return (
    <div className="child_data_input">
      <p>{name}</p>
      <input
        type="text"
        value={value?.[valuename]}
        onInput={(e) => setValue({ ...value, [valuename]: e.target.value })}
        disabled={disable}
      />
    </div>
  );
};

const RoadmapCard = ({ date, completed, name }) => {
  return (
    <div className="roadmap_card">
      <p className="roadmap_date">{date}</p>
      {completed == 1 ? (
        <i className="fa-solid fa-circle-check"></i>
      ) : (
        <i class="fa-solid fa-circle"></i>
      )}
      <p className="roadmap_name">{name}</p>
    </div>
  );
};

export default Childdetails;
