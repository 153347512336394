import React, { useState } from "react";
import "./topMenu.css";
import {
  Menu,
  MenuItem,
  MenuButton,
  SubMenu,
  MenuDivider,
} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { Link } from "@mui/material";
import { NavLink, useParams, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function TopMenu({ id, childname }) {
  const Location = useNavigate();
  const History = useLocation();
  const UserRole = useSelector((store) => store.privilege);
  const [Intervention, setIntervention] = useState(0);
  const [ShowDropdown, setShowDropdown] = useState(false);

  return (
    <div className={`topMenu-container ${UserRole?.role == 'School principal' ? '!hidden' : ""}`}>
      <div className="menu_item_element">
        <NavLink to={`/child_details/${childname}/${id}`}>
          <button>Details</button>
        </NavLink>
      </div>
      <div className="menu_item_element">
        <NavLink to={`/parentreport/${childname}/${id}`}>
          <button>Parent report</button>
        </NavLink>
      </div>
      <div className="menu_item_element">
        <NavLink to={`/child_journal/${childname}/${id}`}>
          <button>Child Journal</button>
        </NavLink>
      </div>

      {/* 
            <div className="menu_item_element nav_lock">
                <NavLink to="/child_parent_evaluation">
                    <button>Parent Evaluation</button>
                </NavLink>
            </div>

            <div className="menu_item_element nav_lock">
                <NavLink to="/child_screening_1">
                    <button>Child Screening 1</button>
                </NavLink>
            </div>

            <div className="menu_item_element nav_lock">
                <NavLink to="/child_screening_2">
                    <button>Child Screening 2</button>
                </NavLink>
            </div> */}

      <div className="menu_item_element">
        <NavLink to={`/screeningprocess/${childname}/${id}`}>
          <button>Screening Process</button>
        </NavLink>
      </div>
      <div className="menu_item_element">
        <NavLink to={`/screeningreport/${childname}/${id}`}>
          <button>Screening Report</button>
        </NavLink>
      </div>
      <div className="menu_item_element nav_lock">
        <NavLink to="/child_post_screening">
          <button>Post Screening</button>
        </NavLink>
      </div>

      {UserRole?.role == 'schooladmin' ? <div className="menu_item_element">
        <NavLink to={`/individualenrollment/${childname}/${id}`} >
          <button>Enrollment & Payment</button>
        </NavLink>
      </div> : <div className="menu_item_element">
        <NavLink to={`/child_enrollment_payment/${childname}/${id}`} className={History.pathname?.includes('renewenroll') ? 'active' : ''} >
          <button>Enrollment & Payment</button>
        </NavLink>
      </div>}



      <div className="menu_item_element intervention_dropdown_container">
        {/* <NavLink to={`/individualSchedulestudent/${id}/${childname}`}> */}
        <button
          className={Intervention == 1 || Intervention == 2 || Intervention == 3 ? "active" : ""}
          onClick={() => {
            setShowDropdown(!ShowDropdown);
          }}
        >
          Intervention<i class="fa-solid fa-caret-down"></i>
        </button>
        {/* </NavLink> */}

        {ShowDropdown && (
          <div className="intervention_dropdown_list">
            <div className="menu_item_element">


              <button
                onClick={() => {
                  setIntervention(1);
                  setShowDropdown(false);
                  Location(
                    `/individualSchedulestudent/${id}/${childname}`
                  );
                }}
              >
                Schedule
              </button>
            </div>
            <div className="menu_item_element">

              <button
                onClick={() => {
                  setIntervention(2);
                  setShowDropdown(false);
                  Location(`/gradeddetails/${childname}/${id}`);
                }}
              >
                Graded details
              </button>

            </div>
            <div className="menu_item_element">


              <button
                onClick={() => {
                  setIntervention(3);
                  setShowDropdown(false);
                  Location(`/PTM/${childname}/${id}`);
                }}
              >
                PTM
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  // https://szhsin.github.io/react-menu/
}
