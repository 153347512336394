import React from "react";
import "./PTMtemplate.css";

const SQR_View = ({ Data }) => {
  return (
    <div className="SQR_view_container">
      <h2>{Data?.main_question}</h2>
      <p>{Data?.given_answer_txt}</p>
      <p>{Data?.others}</p>
    </div>
  );
};

export default SQR_View;
