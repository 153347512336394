import React, { useEffect, useState } from "react";
import "./Screeningreport.css";
import Topbar from "../childPage/topMenu/TopMenu";
import { useParams } from "react-router-dom";
import { URL } from "../../DROPDOWNDATA";
import { ToastContainer, toast } from "react-toastify";
const Screeningreport = (props) => {
  const { id, childname } = useParams();
  const [ScreeningReport, setScreeningReport] = useState();
  const CHILD_ID = atob(id);

  async function GetScreeningDetails() {
    let item = {
      child_view_id: CHILD_ID,
    };

    await fetch(`${URL}/screening_report_details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(item),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        console.log(obj);
        setScreeningReport(obj.body);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  }

  useEffect(() => {
    props.setBreadcrumItem([
      { name: "Student List", link: `/` },
      {
        name: atob(childname),
        link: `/child_details/${childname}/${id}`,
      },
    ]);

    GetScreeningDetails();
  }, [id]);

  return (
    <div>
      <Topbar id={id} childname={childname} />

      <div className="screeningreport_container">
        {/* <h3>Screening report</h3>

        <div className="screeningreport_row">
          <Card
            heading="Teacher evaluation"
            date={"22-05-2022"}
            time="2:40 AM"
          />
          <Card
            heading="Parent evaluation"
            date={"29-08-2022"}
            time="7:49 AM"
          />
          <Card
            heading="Child Screening - 1"
            date={"29-08-2022"}
            time="7:49 AM"
          />
          <Card
            heading="Child Screening - 2"
            date={"29-08-2022"}
            time="7:49 AM"
          />

          <div className="screening_report_btn">
            <button>Download consolidated report</button>
            <button>View consolidated report</button>
          </div>
        </div> */}

        <h3>Report status</h3>
        <div className="screeningreport_report_table">
          <div className="screeningreport_table_container">
            <p className="screening_assessor_name">
              Assessor :{" "}
              <span style={{}}>{ScreeningReport?.acccessor_name}</span>
            </p>
            <p className="screening_approver_name">
              Approver : <span>{ScreeningReport?.approver_name}</span>
            </p>
            <div className="screening_report_btn">
              <a href="">Download consolidated report</a>
              <a
                href={`${ScreeningReport?.consolidated_report}`}
                target={"_blank"}
              >
                View consolidated report
              </a>
            </div>

            <p className="screeningcurrent_status">
              Current status : <span>{ScreeningReport?.report_status}</span>
            </p>

            <table className="screening_report_table_data">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Date</th>
                  {/* <th>Action</th> */}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Report being prepared</td>
                  <td>{ScreeningReport?.created_on}</td>
                  {/* <td className="view_report">View report</td> */}
                </tr>
                <tr>
                  <td>Report sumbited for approval</td>
                  <td>{ScreeningReport?.report_prepared}</td>
                  {/* <td className="view_report">View report</td> */}
                </tr>
                <tr>
                  <td>Report approved</td>
                  <td>{ScreeningReport?.approved_on}</td>
                  {/* <td className="view_report">View report</td> */}
                </tr>
                <tr>
                  <td>Report sent to parents</td>
                  <td>{ScreeningReport?.report_sent_date}</td>
                  {/* <td className="view_report">View report</td> */}
                </tr>
              </tbody>
            </table>
          </div>

          <a
            href={`${ScreeningReport?.screening_report_url}`}
            target="_blank"
            className="download_report_btn"
            style={{
              opacity:
                ScreeningReport?.screening_report_url?.length > 5 ? 1 : 0.6,
              pointerEvents:
                ScreeningReport?.screening_report_url?.length > 5
                  ? "all"
                  : "none",
            }}
          >
            View final report
          </a>
        </div>
      </div>
    </div>
  );
};

const Card = ({ heading, date, time }) => {
  return (
    <div className="screening_report_container">
      <h3>{heading}</h3>
      {/* <p className="completed_on">Completed on :</p> */}
      <div className="completed_on_time">
        <p>
          Date: <span>{date}</span>
        </p>
        <p>
          Time: <span>{time}</span>
        </p>
      </div>
      <TodoList />
      <TodoList />
    </div>
  );
};

const TodoList = () => {
  return (
    <div className="screening_todo">
      <p className="todo_title">Acadamic Observation</p>
      <p className="todo_status">Completed</p>
    </div>
  );
};

export default Screeningreport;
