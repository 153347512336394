import React, { memo, useRef, useState } from "react";

const Groupselect = ({
  SchoolList,
  SelectedSchool,
  SchoolnameHandler,
  SelectschoolHandler,
  ShowState,
}) => {
  const [Showmodal, setShowmodal] = useState(false);
  const SchoolnameRef = useRef("");
  document.addEventListener("mouseup", (e) => {
    if (
      e.target == document.querySelector("#groupschool") ||
      e.target.parentNode == e.target.closest(".group_school_name_container")
    ) {
      setShowmodal(true);
    } else {
      setShowmodal(false);
    }
  });

  return (
    <div className="group_school_block">
      <label htmlFor="groupschool">Select school</label>
      <div
        className="school_select_wrapper"
        onClick={() => {
          if (ShowState == "preview") {
            setShowmodal(true);
          }
        }}
      >
        <input
          type="text"
          name=""
          placeholder="Search school name"
          id="groupschool"
          value={SelectedSchool?.school_name}
          onInput={SchoolnameHandler}
          ref={SchoolnameRef}
          autoComplete={"off"}
          className={ShowState == "preview" ? "" : "disable"}
        />
        {ShowState == "preview" ? <i class="fa-solid fa-angle-down"></i> : ""}
      </div>
      {Showmodal && (
        <div className="group_school_name_container">
          {SchoolList?.length === 0 && <p>No result found</p>}
          {SchoolList?.map((item, index) => {
            return (
              <button
                onClick={() => {
                  SelectschoolHandler(item);
                  setShowmodal(false);
                }}
                key={index}
              >
                {item?.school_name}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default memo(Groupselect);
