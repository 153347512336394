import React, { useEffect, useState } from "react";
import "./Donut.css";
import Chart from "react-apexcharts";
const Donut = ({ overAll, total_count }) => {
  const [SorceData, setSorceData] = useState("total");
  const [StoreChartData, setStoreChartData] = useState({
    name: [],
    data: [],
  });
  const [isvalid, setisvalid] = useState(true);
  useEffect(() => {
    document.querySelectorAll(".apexcharts-pie-area")?.forEach((item) => {
      item.style.stroke = `${item.getAttribute("fill")}`;
      item.style.filter = `drop-shadow(0px 0px 4px ${item.getAttribute(
        "fill"
      )}`;
      item.style.strokeWidth = 5;
    });
    setTimeout(() => {
      setStoreChartData({
        name: overAll[SorceData]?.name,
        data: overAll[SorceData]?.count,
      });
      for (let i = 0; i < overAll[SorceData]?.count?.length; i++) {
        if (overAll[SorceData]?.count[i] == 0) {
          setisvalid(false);
          // console.log(overAll[SorceData]?.count_data[i]);
        } else {
          setisvalid(true);
          return;
        }
      }
    }, 1000);
  }, [overAll, SorceData]);
  const sourceView = (e) => {
    setSorceData(e.target.value);
  };
  function GetLabelData(e) {
    // console.log("====================================");
    // console.log(e);
    // console.log("====================================");
    return e;
  }
  return (
    <div className="donut_container">
      {isvalid && (
        <div className="child_count_donut">
          <h3>{total_count}</h3>
          <p>New parent</p>
        </div>
      )}
      {!isvalid && (
        <div className="empty_grade_donut">
          <p>
            0<span>%</span>
          </p>
        </div>
      )}

      <div className="donut_btn">
        <p>Source</p>

        <select name="" id="" onChange={(e) => sourceView(e)}>
          <option value="total">All</option>
          <option value="Company">Company</option>
          <option value="School/Institution">School / institute</option>
          <option value="Social Media">Social media</option>
          <option value="Others">Others</option>
        </select>
      </div>
      {overAll && (
        <Chart
          options={{
            chart: {
              type: "donut",
            },
            legend: {
              position: "bottom",
            },
            labels: StoreChartData.name,
            colors: ["#5875B8", "#F08391", "#ADDAC8", "#FECFB3"],
            responsive: [
              {
                breakpoint: 980,
                options: {
                  chart: {
                    width: 200,
                  },
                },
              },
            ],
            dataLabels: {
              // formatter: function (val, opts) {
              //   // return opts.w.config.series[opts.seriesIndex];
              //   // return opts.w.config.series[opts.seriesIndex];
              //   return GetLabelData(opts.w.config.series[opts.seriesIndex]);
              // },
            },
          }}
          series={StoreChartData.data}
          type="donut"
        />
      )}
    </div>
  );
};

export default Donut;
