import React from "react";

const Viewremarks = ({ Data }) => {
  return (
    <div className="PTM_view_remarks">
      <div>
        <p>
          Remarks from {Data?.role == "master" ? "master enabler" : "enabler"}
        </p>
        <p>{Data?.enabler_remarks}</p>
      </div>
      <div>
        <p>Remarks from parent</p>
        <p>{Data?.parent_remarks}</p>
      </div>

      {Data?.role == "master" && (
        <div>
          <p>Internal remarks</p>
          <p>{Data?.internal_remarks}</p>
        </div>
      )}
    </div>
  );
};

export default Viewremarks;
