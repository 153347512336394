import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

import "./logout.css"

export default function Logout(props) {
    const History = useNavigate();
    // const [setshowLogoutsetshowLogoutsetshowLogout, setshowLogout] = useState(true)


    return (
        <div className={props.logoutState ? "logout_container" : "none"}>

            <div className="logout_holder">

                <div className='logout_heading'>
                    <p className="heading">Do you really want to logout?</p>
                    <span onClick={() => {
                        props.setLogoutState(false)
                    }}>&times;</span>
                </div>

                <div className="logout_content">
                    {/* <p>Do you really want to logout?</p> */}

                    <div className='action_btns'>
                        {/* <button onClick={() => {
                            localStorage.clear()
                            window.location.reload()
                            // setshowLogout(false)
                        }} className='btn btn2'>Yes</button> */}


                        <button className='btn btn2' to={"/login"}
                            onClick={() => {
                                document.querySelectorAll('.nav li a').forEach((item) => {
                                    item.classList.remove('active');
                                })
                                // document.querySelectorAll('.nav li a')[1].classList.add('active')
                                History('/login')
                                props.setLogoutState(false)

                                props.setIsLogged(false)
                                localStorage.clear()
                                window.localStorage.clear()
                                // window.location.reload()
                            }}>
                            Yes
                        </button>

                        <button onClick={() => {
                            props.setLogoutState(false)
                        }} className='btn btn1'>
                            No
                        </button>
                    </div>

                </div>


            </div>

        </div>
    )
}
