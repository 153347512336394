import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/Loader";
import { URL } from "../../DROPDOWNDATA";
import GroupcCassAndName from "./GroupComponents/GroupcCassAndName";
import GroupChildselect from "./GroupComponents/GroupChildselect";
import GroupDate from "./GroupComponents/GroupDate";
import Groupselect from "./GroupComponents/Groupselect";
import GroupSelectedchild from "./GroupComponents/GroupSelectedchild";
import "./Groupenrollment.css";
import { useSelector } from "react-redux";

const Groupenrollment = () => {
  const { name } = useSelector((store) => store.privilege);
  // LOADER
  const [Loading, setLoading] = useState(false);
  // SHOW STATE
  const [ShowState, setShowState] = useState("preview");
  // SCHOOL LIST
  const [SelectedSchool, setSelectedSchool] = useState({
    school_id: "",
    school_name: "",
  });
  const [SchoolList, setSchoolList] = useState([]);
  // CLASS LIST
  const [SelectedClass, setSelectedClass] = useState("");
  const [ClassList, setClassList] = useState([]);

  // GROUP LIST
  const [SelectedGroup, setSelectedGroup] = useState("");
  const [GroupList, setGroupList] = useState([]);

  // CHILD LIST
  const [SelectedChild, setSelectedChild] = useState({
    child_id: "",
    child_name: "",
  });
  const [Childlist, setChildlist] = useState([]);

  // SELECTED CHILD LIST
  const [SelectedChildList, setSelectedChildList] = useState([]);

  // START DATE , END DATE , GROUP NAME , ENROLLMENT TYPE
  const [EnrollDetails, setEnrollDetails] = useState({
    start_date: "",
    end_date: "",
    no_of_class: "",
    group_name: "",
    type: 0,
    is_paused: "",
    status: "",
    is_complete: "",
    enrollment_id: "",
    view_history: "",
    start_edit_allow: "",
    preview_start_date: "",
    preview_end_date: "",
  });

  // SCHOOLNAME HANDLER START
  const SchoolnameHandler = async (e) => {
    setSchoolList([]);
    setSelectedSchool({
      school_id: SelectedSchool?.school_id,
      school_name: e.target.value,
    });
    setLoading(true);
    let Data = {
      search: e.target.value,
    };
    await fetch(URL + `/school_list?page_no=1`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(Data),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        setSchoolList(obj.body?.school_details);
        setLoading(false);
      })
      .catch((err) => {
        toast.error("something went wrong");
        toast.clearWaitingQueue();
        setLoading(false);
      });
  };
  // SCHOOLNAME HANDLER END

  // SELECT SCHOOL HANDLER START
  async function SelectschoolHandler(data) {
    setSelectedGroup("");
    setLoading(true);
    setSelectedSchool({
      school_id: data?.school_id,
      school_name: data?.school_name,
    });
    setClassList([]);
    let Data = {
      school_id: Number(data?.school_id),
    };
    await fetch(URL + `/school_details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(Data),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        let ClassList_Empty = [];
        let ClassListArray = obj.body?.class_details;
        for (let i = 0; i < ClassListArray.length; i++) {
          let classname = ClassListArray[i].name?.split(",");
          let classID = ClassListArray[i].class_id?.split(",");
          for (let j = 0; j < ClassListArray[i].count; j++) {
            ClassList_Empty.push({
              class_id: classID[j],
              class_name: `${
                ClassListArray[i]?.class_standard + " Std " + classname[j]
              }`,
            });
          }
        }
        setClassList(ClassList_Empty);

        GetGroupList(Number(data?.school_id));
      })

      .catch((err) => {
        toast.error("something went wrong");
        toast.clearWaitingQueue();
        setLoading(false);
      });
  }
  // SELECT SCHOOL HANDLER END

  // CREATE NEW GROUP HANDLER START
  function CreateNewGroup() {
    setSelectedGroup("");
    setSelectedClass("");
    // setGroupList([]);
    setSelectedChildList([]);
    setShowState("create");
    setEnrollDetails({
      start_date: "",
      end_date: "",
      no_of_class: "",
      group_name: "",
      type: 0,
      is_paused: "",
      status: "",
      is_complete: "",
      enrollment_id: "",
      view_history: "",
      start_edit_allow: "",
    });
  }
  // CREATE NEW GROUP HANDLER END

  // GET GROUP LIST API START
  const GetGroupList = async (id) => {
    let Data = {
      school_id: id,
    };
    await fetch(URL + `/get_group_enrollment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(Data),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        setGroupList(obj.body);
        setLoading(false);
      })
      .catch((err) => {
        toast.error("something went wrong");
        toast.clearWaitingQueue();
        setLoading(false);
      });
  };
  // GET GROUP LIST API END

  // CLASS SELECT HANDLER START
  const ClassHandler = async (e) => {
    setSelectedClass(e.target.value);
    setSelectedChild({
      child_id: "",
      child_name: "",
    });
    setChildlist([]);

    if (e.target.value) {
      setLoading(true);
      let Data = {
        class_id: e.target.value,
      };
      await fetch(URL + `/child_details_class_group`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("loggedToken"),
        },
        body: JSON.stringify(Data),
      })
        .then((r) =>
          r.json().then((data) => ({
            status: r.status,
            body: data,
          }))
        )
        .then((obj) => {
          setChildlist(obj.body);
          setLoading(false);
        })
        .catch((err) => {
          toast.error("something went wrong");
          toast.clearWaitingQueue();
          setLoading(false);
        });
    }
  };
  // CLASS SELECT HANDLER END

  // CHILD SELECT HANDLER START
  const ChildSelectHandler = (e) => {
    if (e.target.value) setSelectedChild(JSON.parse(e.target.value));
  };
  // CHILD SELECT HANDLER END

  // GROUP SELECT HANDLER START
  const GroupSelectHandler = async (e) => {
    setSelectedGroup(e.target.value);

    if (e.target.value) {
      setLoading(true);
      let Data = {
        group_id: e.target.value,
      };
      await fetch(URL + `/get_enrollment_details`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("loggedToken"),
        },
        body: JSON.stringify(Data),
      })
        .then((r) =>
          r.json().then((data) => ({
            status: r.status,
            body: data,
          }))
        )
        .then((obj) => {
          let Group_data = obj.body;
          setSelectedChildList(Group_data?.child_list);
          setEnrollDetails({
            start_date: Group_data?.start_date,
            end_date: Group_data?.end_date,
            no_of_class: Group_data?.no_of_classess || "",
            type: Group_data?.is_paid,
            group_name: Group_data?.group_name,
            is_paused: Group_data?.is_paused,
            status: Group_data?.status,
            is_complete: Group_data?.is_complete,
            enrollment_id: Group_data?.enrollment_id,
            view_history: Group_data?.view_history,
            start_edit_allow: Group_data?.start_edit_allow,
            preview_start_date: Group_data?.start_date,
            preview_end_date: Group_data?.end_date,
          });
          setLoading(false);
        })
        .catch((err) => {
          toast.error("something went wrong");
          toast.clearWaitingQueue();
          setLoading(false);
        });
    }
  };
  // GROUP SELECT HANDLER END

  // ACTIVE TOGGLE HANDLER START
  const ActiveToggleHandler = (e) => {
    if (e.target.checked) {
      setEnrollDetails((prev) => ({ ...prev, is_paused: 1 }));
    } else {
      setEnrollDetails((prev) => ({ ...prev, is_paused: 0 }));
    }
  };
  // ACTIVE TOGGLE HANDLER END

  // ADD CHILD HANDLER START
  function AddChildHandler() {
    if (SelectedChild?.child_id) {
      let is_add = true;
      SelectedChildList?.forEach((item, index) => {
        if (item.child_id == SelectedChild?.child_id) {
          is_add = false;
        }
      });

      if (is_add) {
        setSelectedChildList((prev) => [...prev, SelectedChild]);
        setSelectedChild({
          child_id: "",
          child_name: "",
        });
      } else {
        toast.warn("Child already added");
        toast.clearWaitingQueue();
      }
    } else {
      toast.warn("Kindly select any child");
      toast.clearWaitingQueue();
    }
  }
  // ADD CHILD HANDLER START

  // REMOVE CHILD HANDLER START
  function RemoveChildHandler(id) {
    let DuplicateArray = SelectedChildList?.filter((item, index) => {
      return item?.child_id != id;
    });
    setSelectedChildList(DuplicateArray);
  }
  // REMOVE CHILD HANDLER END

  // VALIDATION START
  function Validations(value, message) {
    if (value == "") {
      toast.warn(message);
      toast.clearWaitingQueue();
      return false;
    } else {
      return true;
    }
  }
  // VALIDATION END

  // CANCEL HANDLER START
  function CancelHandler() {
    setShowState("preview");
    setSelectedGroup("");
    setSelectedClass("");
    setSelectedChildList([]);
    setEnrollDetails({
      start_date: "",
      end_date: "",
      no_of_class: "",
      group_name: "",
      type: 0,
      is_paused: "",
      status: "",
      is_complete: "",
      enrollment_id: "",
      view_history: "",
      start_edit_allow: "",
      preview_start_date: "",
      preview_end_date: "",
    });
  }
  // CANCEL HANDLER END

  // EDIT HANDLER START
  function EditHandler() {
    setShowState("edit");
    setSelectedClass("");
  }
  // EDIT HANDLER END

  // RENEW HANDLER START
  function RenewHandler() {
    setShowState("renew");
  }
  // RENEW HANDLER END

  // ADD NEW ENROLL HANDLER START
  function AddnewEnroll_Handler() {
    setShowState("addnew");
    setEnrollDetails((prev) => ({
      ...prev,
      start_date: new Date().toISOString()?.split("T")[0],
      end_date: "",
      type: 0,
    }));
  }
  // ADD NEW ENROLL HANDLER END

  // CREATE ENROLLMENT START
  const CreateEnrollmentHandler = async () => {
    if (Validations(SelectedSchool?.school_id, "Select any school"))
      if (SelectedChildList?.length > 1) {
        if (Validations(EnrollDetails?.no_of_class, "Enter no of classes"))
          if (Validations(EnrollDetails?.start_date, "Select start date"))
            if (Validations(EnrollDetails?.end_date, "Select end date"))
              if (
                Validations(
                  EnrollDetails?.group_name,
                  "Kindly enter group name"
                )
              )
                if (EnrollDetails?.type != null) {
                  if (ShowState == "create" || ShowState == "edit") {
                    SubmitHandler();
                  } else if (ShowState == "renew") {
                    Submit_Renew_Addnew_Data("renew_group_enrollment");
                  } else if (ShowState == "addnew") {
                    Submit_Renew_Addnew_Data("add_new_group_enrollment");
                  }
                } else {
                  toast.warn("Kindly select enrollment type");
                  toast.clearWaitingQueue();
                }
      } else {
        toast.warn("Kindly add any child");
        toast.clearWaitingQueue();
      }
  };
  // CREATE ENROLLMENT END

  // SUBMIT API START
  const SubmitHandler = async () => {
    setLoading(true);
    let ChildIDArray = [];
    SelectedChildList?.forEach((item, index) => {
      ChildIDArray.push(item?.child_id);
    });
    let Data = {
      child_list: ChildIDArray,
      group_name: EnrollDetails?.group_name,
      start_date: EnrollDetails?.start_date,
      end_date: EnrollDetails?.end_date,
      group_id: SelectedGroup,
      is_paused: EnrollDetails?.is_paused,
      no_of_classes: EnrollDetails?.no_of_class,
    };
    await fetch(URL + `/create_group_enrollment`, {
      method: ShowState == "create" ? "POST" : "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(Data),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        if (obj.body?.error) {
          toast.error(obj.body?.message);
          toast.clearWaitingQueue();
        } else {
          setShowState("preview");
          SelectschoolHandler(SelectedSchool);
          setSelectedClass("");
          toast.success(obj.body?.message);
          toast.clearWaitingQueue();
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.error("something went wrong");
        toast.clearWaitingQueue();
        setLoading(false);
      });
  };
  // SUBMIT API END

  // SUBMIT RENEW AND ADD NEW API START
  const Submit_Renew_Addnew_Data = async (Path) => {
    setLoading(true);
    let ChildIDArray = [];
    SelectedChildList?.forEach((item, index) => {
      ChildIDArray.push(item?.child_id);
    });
    let Data = {
      child_list: ChildIDArray,
      group_name: EnrollDetails?.group_name,
      start_date: EnrollDetails?.start_date,
      end_date: EnrollDetails?.end_date,
      group_id: SelectedGroup,
      no_of_classes: EnrollDetails?.no_of_class,
    };
    await fetch(URL + `/${Path}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(Data),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        if (obj.body?.error) {
          toast.error(obj.body?.message);
          toast.clearWaitingQueue();
        } else {
          setShowState("preview");
          SelectschoolHandler(SelectedSchool);
          setSelectedClass("");
          toast.success(obj.body?.message);
          toast.clearWaitingQueue();
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.error("something went wrong");
        toast.clearWaitingQueue();
        setLoading(false);
      });
  };
  // SUBMIT RENEW AND ADD NEW API END

  useEffect(() => {
    SchoolnameHandler({ target: { value: "" } });
  }, []);

  return (
    <div className="group_enroll_container">
      <h2>Group enrollment</h2>
      <div className="group_child_container">
        <div className="group_left_container">
          {/* SELECT SCHOOL START */}
          <Groupselect
            SchoolList={SchoolList}
            SelectedSchool={SelectedSchool}
            SchoolnameHandler={SchoolnameHandler}
            SelectschoolHandler={SelectschoolHandler}
            ShowState={ShowState}
          />
          {/* SELECT SCHOOL END */}

          {/* CLASS LIST AND GROPU LIST START*/}
          <GroupcCassAndName
            SelectedSchool={SelectedSchool}
            ClassList={ClassList}
            SelectedClass={SelectedClass}
            ClassHandler={ClassHandler}
            GroupList={GroupList}
            SelectedGroup={SelectedGroup}
            GroupSelectHandler={GroupSelectHandler}
            ShowState={ShowState}
            CreateNewGroup={CreateNewGroup}
          />
          {/* CLASS LIST AND GROPU LIST END*/}

          {/* ACTIVE TOGGLE START */}
          {ShowState == "edit" && (
            <div className="group_active_container">
              <p>Group paused</p>
              <input
                type="checkbox"
                name=""
                id="groupactive"
                checked={EnrollDetails?.is_paused == 0 ? false : true}
                onChange={ActiveToggleHandler}
              />
              <label htmlFor="groupactive"></label>
            </div>
          )}
          {/* ACTIVE TOGGLE END */}

          {/* PREVIEW START */}
          {SelectedGroup != "" && ShowState == "preview" && (
            <div className="group_preview_container">
              <h3>Enrollment details</h3>
              {/* DATE PREVIEW */}
              <div className="group_date_preview">
                <div className="date_preview_item">
                  <p>Start date</p>
                  <span>{EnrollDetails?.start_date}</span>
                </div>
                <div className="date_preview_item">
                  <p>End date</p>
                  <span>{EnrollDetails?.end_date}</span>
                </div>
                <div className="date_preview_item">
                  <p>Status</p>
                  <span>{EnrollDetails?.status}</span>
                </div>
              </div>
              {/* Enrollment type */}
              {/* <p className="group_active_preview">
                Enrollment type :{" "}
                <span>{EnrollDetails?.type == 0 ? "Free" : "Paid"}</span>
              </p> */}

              {/* CHILD NAME PREVIEW */}
              <div className="group_childlist_preview">
                <h3>Child list</h3>
                <div className="child_list_item_preview">
                  {SelectedChildList?.map((item, index) => {
                    return (
                      <p key={index}>
                        <span>
                          {index + 1}. {item?.child_name}
                        </span>
                      </p>
                    );
                  })}
                </div>
              </div>

              <div className="group_preview_btn_container">
                <button onClick={EditHandler}>
                  <i className="fa-solid fa-pen"></i> Edit
                </button>

                <p>Or</p>
                {EnrollDetails?.is_complete == 0 && (
                  <button onClick={RenewHandler}>Renew enrollment</button>
                )}
                {EnrollDetails?.is_complete == 1 && (
                  <button onClick={AddnewEnroll_Handler}>
                    Add new enrollment
                  </button>
                )}
              </div>
            </div>
          )}
          {/* PREVIEW END */}

          {/* SELECT CHILD AND ADD START*/}

          {ShowState != "preview" && (
            <GroupChildselect
              Childlist={Childlist}
              SelectedChild={SelectedChild}
              ChildSelectHandler={ChildSelectHandler}
              AddChildHandler={AddChildHandler}
              SelectedChildList={SelectedChildList}
            />
          )}
          {/* SELECT CHILD AND ADD END*/}

          {/* SELECTED CHILD START */}
          {ShowState != "preview" && (
            <GroupSelectedchild
              SelectedChildList={SelectedChildList}
              RemoveChildHandler={RemoveChildHandler}
            />
          )}
          {/* SELECTED CHILD END */}
        </div>

        {ShowState != "preview" && (
          <div className="group_right_container">
            {/* SHOW PREVIOUS DATE START */}
            {ShowState == "renew" && (
              <div className="group_date_preview">
                <div className="date_preview_item">
                  <p>Current start date</p>
                  <span>
                    {EnrollDetails?.preview_start_date?.split("-")[1] +
                      "-" +
                      EnrollDetails?.preview_start_date?.split("-")[2] +
                      "-" +
                      EnrollDetails?.preview_start_date?.split("-")[0]}
                  </span>
                </div>
                <div className="date_preview_item">
                  <p>Current end date</p>
                  <span>
                    {EnrollDetails?.preview_end_date?.split("-")[1] +
                      "-" +
                      EnrollDetails?.preview_end_date?.split("-")[2] +
                      "-" +
                      EnrollDetails?.preview_end_date?.split("-")[0]}
                  </span>
                </div>
              </div>
            )}
            {/* SHOW PREVIOUS DATE END */}

            {/* SELECT START AND END DATE START*/}
            <GroupDate
              setEnrollDetails={setEnrollDetails}
              EnrollDetails={EnrollDetails}
              ShowState={ShowState}
            />
            {/* SELECT START AND END DATE END*/}

            {/* ADD GROUP NAME START */}
            <div className="group_add_name_container">
              <label htmlFor="groupnameinput">Enter group name</label>
              <input
                type="text"
                placeholder="group name"
                id="groupnameinput"
                defaultValue={EnrollDetails?.group_name}
                onInput={(e) => {
                  e.target.value = e.target.value?.replace(
                    /[^a-z0-9A-Z -]/g,
                    ""
                  );
                  setEnrollDetails((prev) => ({
                    ...prev,
                    group_name: e.target.value,
                  }));
                }}
              />
            </div>
            {/* ADD GROUP NAME END */}

            {/* ENROLL TYPE START */}
            <div className="group_enroll_type  hidden">
              <label htmlFor="">Enrollment type</label>

              <div className="group_enroll_type_container">
                <div className="group_enroll_type_item">
                  <input
                    type="radio"
                    name="group_enroll_type"
                    id="freeenroll"
                    value={0}
                    checked={EnrollDetails?.type == 0}
                    onChange={(e) => {
                      setEnrollDetails((prev) => ({
                        ...prev,
                        type: e.target.value,
                      }));
                    }}
                  />
                  <label htmlFor="freeenroll">Free</label>
                </div>
                <div className="group_enroll_type_item">
                  <input
                    disabled
                    type="radio"
                    name="group_enroll_type"
                    id="paidenroll"
                    value={1}
                    checked={EnrollDetails?.type == 1 ? true : false}
                    onChange={(e) => {
                      setEnrollDetails((prev) => ({
                        ...prev,
                        type: e.target.value,
                      }));
                    }}
                  />
                  <label htmlFor="paidenroll">Paid</label>
                </div>
              </div>
            </div>
            {/* ENROLL TYPE END */}
          </div>
        )}
      </div>
      {/* NEXT BUTTON START */}
      {ShowState != "preview" && (
        <div className="group_next_btn">
          <button onClick={CancelHandler}>Cancel</button>
          <button onClick={CreateEnrollmentHandler}>Save</button>
        </div>
      )}
      {/* NEXT BUTTON END */}

      {/* LOADER START */}
      {Loading && (
        <div className="loader_screen">
          <Loader />
        </div>
      )}
      {/* LOADER END */}
    </div>
  );
};

export default Groupenrollment;
