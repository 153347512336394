import React, { useEffect } from "react";
import "./style.css";
import Pagination from "react-js-pagination";
import { useNavigate } from "react-router-dom";
import usePost from "../../Hooks/usePost";
import { URL } from "../../DROPDOWNDATA";
import { useState } from "react";
const Courses = ({ setBreadcrumItem }) => {
  const Navigate = useNavigate();
  const [Pg_no, setPg_no] = useState(1);
  const { Postdata, LoadingPost, Postcall } = usePost(
    `${URL}/teacher_list?page_no=${Pg_no}`
  );
  useEffect(() => {
    setBreadcrumItem([{ name: "Courses", link: "/courses" }]);
    Postcall({
      search: "",
    });
  }, [Pg_no]);

  return (
    <div>
      <h2 className="my-2 text-[20px] font-semibold">Course list</h2>
      <div>
        <table className="courses-table w-[85%] mx-auto mt-4">
          <thead>
            <tr className="bg-[#FFDAC1]">
              <th>Si.no</th>
              <th>Teacher Name</th>
              <th>Teacher Mobile</th>
              <th>E-mail</th>
              <th>Class</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Postdata?.body?.details?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{(Pg_no - 1) * 10 + (index + 1)}</td>
                  <td>{item?.name}</td>
                  <td>{item?.phone_number}</td>
                  <td>{item?.email || "-"}</td>
                  <td>{item?.standard}</td>
                  {/* <td>{item?.section}</td> */}
                  <td>
                    <button
                      onClick={() => Navigate(`/courses/${item?.teacher_id}/1`)}
                    >
                      <i class="fa-solid fa-eye"></i>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          activePage={Pg_no}
          itemsCountPerPage={10}
          totalItemsCount={Math.ceil(Postdata?.body?.total_count)}
          pageRangeDisplayed={10}
          itemClass="paginationLi_new"
          prevPageText="Prev"
          nextPageText="Next"
          activeLinkClass="pagLi_sc"
          activeClass="pagLi_sc"
          itemClassFirst="go_first"
          itemClassLast="go_first"
          itemClassPrev="prev_btn"
          itemClassNext="next_btn"
          onChange={(e) => {
            // console.log(e);
            setPg_no(e);
          }}
        />
      </div>
    </div>
  );
};

export default Courses;
