import { combineReducers, createStore } from 'redux';
import UserReducer from './Reducer/UserReducer';
import GroupschedulelistReducer from './Reducer/GroupschedulelistReducer';
import GroupschedulePopupReducer from './Reducer/GroupschedulePopupReducer';



const RootReduces = combineReducers({
    privilege: UserReducer,
    Groupschedule: GroupschedulelistReducer,
    GroupschedulePopup: GroupschedulePopupReducer
})

export const Store = createStore(RootReduces)