import "./loader.css"

import React from 'react'

export default function Loader() {
    return (
        <div className="loader_container">


            <div className="circle_holder loadercircle">
                <div className="inner_circle loadercircle">
                    <div className="most_inner_circle loadercircle">
                        <div className="most_inner_most_circle loadercircle">


                        </div>
                    </div>
                </div>
            </div>

            {/* <div id="loader">
                <div class="ls-particles ls-part-1"></div>
                <div class="ls-particles ls-part-2"></div>
                <div class="ls-particles ls-part-3"></div>
                <div class="ls-particles ls-part-4"></div>
                <div class="ls-particles ls-part-5"></div>
                <div class="lightsaber ls-left ls-green"></div>
                <div class="lightsaber ls-right ls-red"></div>
            </div> */}


        </div>
    )
}
