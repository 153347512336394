import { GROUP_RESCHEDULE } from "../Actiontype";

const initialState = {
    call_link: "",
    class_no: "",
    enabler_id: "",
    enabler_name: "",
    meeting_date: "",
    meeting_day: "",
    meeting_status: "",
    meeting_time: "",
    si_no: "",
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GROUP_RESCHEDULE:
            return {
                ...state,
                ...action.payload,
            };

        default:
            return state;
    }
};
