import React, { useState, useRef, useEffect } from 'react'
import "./navbar.css"
import { Link } from "react-router-dom"
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import styled from '@emotion/styled'
import Zoom from '@mui/material/Zoom';
import dl_logo from "../../images/logo.png"
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function Navbar(props) {

    const Userrole = useSelector((store) => store.privilege);

    let navItems = ["Dashboard", "Student List", "Manage parents", "Manage school", "Manage role", "Scheduling", "Task", "Group Enrollment"]
    let navItemsIcons = ["fa-chart-line", "fa-child", "fa-user", "fa-school", "fa-chalkboard-user", "fa-calendar-days", "fa-list-check", "fa-user-group"]
    let navItemsLinks = ["/dashboard", "/", "/viewSchedule", "/manageschool/all", "/addrole/all/list/0", "/scheduling", "/Task", "/groupenrollment"]

    if (!Userrole?.is_notification) {
        navItems.splice(6, 1);
        navItemsIcons.splice(6, 1);
        navItemsLinks.splice(6, 1);
    }
    if (!Userrole?.is_schedule) {
        navItems.splice(5, 1);
        navItemsIcons.splice(5, 1);
        navItemsLinks.splice(5, 1);
    }

    if (Userrole?.role == "School principal") {
        navItems = ["Student List", "Manage school", "Courses"]
        navItemsIcons = ["fa-child", "fa-school", "fa-book"]
        navItemsLinks = ["/", "/manageschool/all", "/courses"]
    }

    useEffect(() => {
        // localStorage.removeItem('childID')
        // localStorage.removeItem('childName')
    }, [])

    return (
        <div className={props.toogleClick ? "nav active" : "nav"}>
            <ul>

                <li>
                    <a href="">
                        <span className='icon'>
                            <img src={dl_logo} alt="" width="30px" />
                        </span>
                        <span className={props.toogleClick ? "title hidden" : "title"}>DLearners</span>
                    </a>
                </li>


                {
                    navItems.map((item, index) => {

                        return (
                            <li className={index == 0 || index == 1 ? "" : ""} key={index} >
                                <NavLink onClick={() => {
                                    props.setBreadcrumItem([{ name: item, link: navItemsLinks[index] }])
                                    // setSelectedItem(item)
                                    localStorage.removeItem("childID");
                                    localStorage.removeItem("childName");

                                    sessionStorage.setItem('selectedSideBarItem', item)

                                }} to={navItemsLinks[index]} className={item == sessionStorage.getItem('selectedSideBarItem') ? "active" : ""}>

                                    <Tooltip title={item} placement="right" className='icon'>
                                        <Button> <i className={`fas ` + navItemsIcons[index]}></i></Button>
                                    </Tooltip>

                                    <span className={props.toogleClick ? "title hidden" : "title"}>{item}</span>
                                </NavLink>
                            </li>
                        )
                    })
                }


                {/* <li>
                    <Link to="/" className='active'>
                        <span className='icon'>
                            <i className="fas fa-chart-line"></i>
                        </span>
                        <span className={toogleClick ? "title hidden" : "title"}>Dashboard</span>
                    </Link>
                </li>

                <li>
                    <Link to="/dataTable" onClick={switchTable} >
                        <span className='icon'>
                            <i className="fas fa-ghost"></i>
                        </span>
                        <span className={toogleClick ? "title hidden" : "title"}>Manage parents</span>
                    </Link>
                </li>
                <li>
                    <a href="">
                        <span className='icon'>
                            <i className="fas fa-school"></i>
                        </span>
                        <span className={toogleClick ? "title hidden" : "title"} >Manage schools</span>
                    </a>
                </li>
                <li>
                    <a href="">
                        <span className='icon'>
                            <i className="fas fa-chalkboard-teacher"></i>
                        </span>
                        <span className={toogleClick ? "title hidden" : "title"} > Manage Enabler</span>
                    </a>
                </li>
                <li>
                    <a href="">
                        <span className='icon'>
                            <i className="fas fa-cookie"></i>
                        </span>
                        <span className={toogleClick ? "title hidden" : "title"}>Intervention Sessions</span>
                    </a>
                </li> */}

            </ul>

        </div >
    )
}
