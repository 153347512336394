import { Copyright } from '@material-ui/icons';
import React, { useState, useEffect } from 'react'
import { URL } from '../DROPDOWNDATA';
const useEnrollment = (id) => {
    const authKey = "Bearer " + localStorage.getItem("loggedToken");
    const CHILD_ID = localStorage.getItem("childID");

    const [course_details_list, setCourse_details_list] = useState([]);
    const [currentInstallmentValues, setCurrentInstallmentValues] = useState({
        current_course_price: 0,
        current_down_payment: 0,
        current_discount_amount: 0,
        current_balance: 0,
        current_no_of_installment: 1, // total_installment
        current_installmentInterval: 1, //installment_duration
        current_installmentAmount: 0, // per_installment_amount
    });

    // Course Duration
    const [startDateValue, setStartDate] = useState(new Date());
    const [endDateValue, setEndDate] = useState(new Date());

    const [isDiscountApplied, setDiscountApplied] = useState(false);
    const [toggleDiscountRemarks, setToggleDiscountRemarks] = useState(false);

    const [loader, setLoader] = useState(false);

    const [togglePlanGuide, setTogglePlanGuide] = useState(false);
    // Pay in full
    const [transactionDetailsPayInfull, setTransactionDetailsPayInfull] =
        useState({
            transaction_date: null,
            transaction_reference: null,
            transaction_comment: null,
        });
    const [errorFieldMessages, setErrorFieldMessages] = useState({
        error__showStartDate: false,

        error__showTransactionDate: false,
        error__showTransactionReference: false,
        error__showTransactionComment: false,
        error__showDiscountAmount: false,

        error__downPaymentAmount: false,
        error__no_of_installment: false,

        error_for_interval: false,
        error_message_for_interval: "",

        error__discountAmountMessage: null,
        error__downPaymentAmountMessage: null,
        error__no_of_installmentMessage: null,
    });
    const [payInFullTransactionList, setPayInFullTransactionList] = useState([]);

    const [enrollmentUserState, setEnrollmentUserState] = useState({});

    const [enrollmentID, setEnrollmentID] = useState(null);

    //   V3
    const [DiscountShow_instant, setDiscountShow_instant] = useState({
        discountAmount: "",
        discountRemarks: "",
    });
    const [AddTransaction, setAddTranaction] = useState(false);
    const [OpenGenerateModal, setOpenGenerateModal] = useState(false);
    const [copyLink, setcopyLink] = useState({
        "text": "copy",
        "url": ""
    });



    // useEffect(() => {
    //     getProgramme(id);
    // }, [id])
    return [loader, setLoader, AddTransaction, setAddTranaction, DiscountShow_instant, setDiscountShow_instant, enrollmentID, setEnrollmentID, enrollmentUserState, setEnrollmentUserState, payInFullTransactionList, setPayInFullTransactionList, errorFieldMessages, setErrorFieldMessages, transactionDetailsPayInfull, setTransactionDetailsPayInfull, togglePlanGuide, setTogglePlanGuide, toggleDiscountRemarks, setToggleDiscountRemarks, isDiscountApplied, setDiscountApplied, endDateValue, setEndDate, startDateValue, setStartDate, currentInstallmentValues, setCurrentInstallmentValues, course_details_list, setCourse_details_list, OpenGenerateModal, setOpenGenerateModal, copyLink, setcopyLink]
}

export default useEnrollment