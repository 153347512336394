import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./Groupschedule.css";
import SidebarList from "../scheduleSession/SidebarList/SidebarList";
import { useNavigate } from "react-router-dom";
import Groupscheduleadd from "./Groupscheduleadd/Groupscheduleadd";
import Sidebarlist from "./SidebarList/Sidebarlist";

const Groupschedule = () => {
  const [value, setValue] = useState(1);
  const History = useNavigate();

  function Tabpanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }

  Tabpanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    if (newValue == 0) {
      History(`/scheduleSession`);
    } else {
      setValue(newValue);
    }
  };

  return (
    <div className="scheduleSessionList">
      <div className="schedule_session_tabLayout_container">
        <div className="schedule_session_tabLayout">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                sx={{ width: "100%" }}
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  sx={{ width: "100%" }}
                  label="Individual"
                  {...a11yProps(0)}
                />
                <Tab sx={{ width: "100%" }} label="Group" {...a11yProps(1)} />
                {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
              </Tabs>
            </Box>

            {/* Individual */}
            <Tabpanel value={value} index={0}></Tabpanel>

            {/* Group */}
            <Tabpanel value={value} index={1}>
              <Groupscheduleadd />
            </Tabpanel>
          </Box>
        </div>

        {/* <div className="create_schedule_btns_holder">
          <button className='btn btn1'>Cancel</button>
          <button className='btn btn2'>Create Schedule</button>
      </div> */}
      </div>

      <div className="sidebarList">
        <Sidebarlist />
      </div>
    </div>
  );
};

export default Groupschedule;
