import React from "react";
import Declined from "../TaskComponents/Declined";
import "./Taskdeclined.css";

const Taskdeclined = ({ Notification_Data }) => {
  return (
    <div className="task_declined_container">
      {Notification_Data?.notification_list?.map((item, index) => {
        return <Declined key={index} item={item} />;
      })}
    </div>
  );
};

export default Taskdeclined;
