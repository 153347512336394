import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import "./Linechart.css";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function Linechart({ FilterValue, labelData, ChartData }) {
  const options = {
    responsive: true,
    interaction: {
      mode: "nearest",
      intersect: false,
      axis: "xy",
    },

    borderWidth: 3,
    elements: {
      line: {
        tension: 0.5,
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 11,
          },
        },
        display: true,
        title: {
          display: true,
          color: "#007872",
          text:
            FilterValue.category == 1
              ? "Hours"
              : FilterValue.category == 2
              ? "Week"
              : FilterValue.category == 3
              ? "Day"
              : FilterValue.category == 4
              ? "Year"
              : "Day",
          font: {
            size: 18,
            weight: "bold",
          },
        },
      },
      y: {
        grid: {
          drawBorder: false,
          color: function (context) {
            if (context.tick.value > 0) {
              return 0;
            } else if (context.tick.value < 0) {
              return 5;
            }

            return "green";
          },
        },
        ticks: {
          stepSize: 2,
          beginAtZero: true,
          callback: function (value, index) {
            if (value !== 0) return value;
            /* OR *
             if (value === 0) return 100 + '%';
             else return value + '%'; */
          },
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: true,
        //   text: "Chart.js Line Chart",
      },
    },
  };

  let Labels = {
    1: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    ],
  };

  const data = {
    labels: labelData,
    datasets: [
      {
        label: "Daily registration",
        data: ChartData,
        borderColor: "#007872",
        pointStyle: "circle",
        pointRadius: 3,
        fill: true,
        tension: 0.5,
        pointBackgroundColor: "#007872",
        animations: {
          y: {
            easing: "easeInOutSine",
            duration: 1000,
            delay: 500,
          },
        },
      },
    ],
  };
  return (
    <div className="daily_registration_container">
      <div className="daily_container_btn">
        <h2>Daily registration</h2>
        {/* <button>Daily</button>
        <button>Weekly</button>
        <button>Monthly</button>
        <button>Quartely</button>
        <button>Half yearly</button>
        <button>Annually</button> */}
      </div>
      <div className="Linechart_container">
        <Line options={options} data={data} />
      </div>
    </div>
  );
}
