import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./Manageparentchild.css";

import { ToastContainer, toast } from "react-toastify";
import { URL } from "../../../DROPDOWNDATA";
const Manageparentchild = ({ ParentDetails }) => {
  const [ChildData, setChildData] = useState([]);
  async function Get_parent_child() {
    let item = {
      parent_id: ParentDetails?.id,
    };

    await fetch(`${URL}/parent_list_child`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(item),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        console.log(obj);
        setChildData(obj.body);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  }

  useEffect(() => {
    Get_parent_child();
  }, [ParentDetails]);

  return (
    <div>
      <table className="direct_parent_child_table">
        <thead>
          <tr>
            <th>Parent Name</th>
            <th>Mobile Number</th>
            <th>Alternate no</th>
            <th>Email</th>
            <th>Created on</th>
            <th>no.of child</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{ParentDetails?.name}</td>
            <td>{ParentDetails?.mobile_no}</td>
            <td>{ParentDetails?.alt_no}</td>
            <td>{ParentDetails?.email}</td>
            <td>{ParentDetails?.date}</td>
            <td>{ParentDetails?.no_child}</td>
          </tr>
        </tbody>
      </table>

      <div className="manage_parent_child_list">
        <h4>Child list</h4>

        <div className="manage_link_child">
          {/* {ChildData?.map((item,index)=>{
                return 
            })}
          <NavLink to={`/child_enrollment_payment/dfdsf/3224`}>
            <p className="listof_child_link">Shamiksha</p>
          </NavLink>
          <NavLink to={`/child_enrollment_payment/dfdsf/3224`}>
            <p className="listof_child_link">Shamiksha</p>
          </NavLink>
          <NavLink to={`/child_enrollment_payment/dfdsf/3224`}>
            <p className="listof_child_link">Shamiksha</p>
          </NavLink> */}

          <table className="manage_parent_child_table">
            <thead>
              <tr>
                <th>Child id</th>
                <th>Child name</th>
                <th>Standard</th>
                <th>School name</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {ChildData?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item?.child_view_id}</td>
                    <td>{item?.child_name}</td>
                    <td>{item?.standard}</td>
                    <td>{item?.school_name}</td>
                    <td>{item?.child_status}</td>
                    <td>
                      <NavLink
                        to={`/child_details/${btoa(item?.child_name)}/${btoa(
                          item?.child_view_id
                        )}`}
                      >
                        <p className="listof_child_link">
                          <i class="fa-solid fa-eye"></i>
                        </p>
                      </NavLink>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Manageparentchild;
