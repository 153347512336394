import React, { forwardRef, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { URL } from "../../../DROPDOWNDATA";
import "./PTMtemplate.css";

const Addremarks = forwardRef(
  (
    {
      Data,
      SelectedClass,
      setCurrentQuestionIndex,
      CurrentQuestionIndex,
      role,
      setSelectedClass,
      setLoader,
    },
    ref
  ) => {
    const [Remarks, setRemarks] = useState({
      enabler: "",
      parent: "",
      internal: "",
    });

    const RemarksSubmit = async () => {
      if (Remarks?.enabler == "" || Remarks?.parent == "") {
        toast.error("Kindly fill the field");
        toast.clearWaitingQueue();
        return;
      }
      let data = {
        view_no: SelectedClass?.id,
        enabler_remarks: Remarks?.enabler,
        parent_remarks: Remarks?.parent,
        internal_remarks: Remarks?.internal,
      };
      setLoader(true);
      await fetch(`${URL}/add_class_feedback`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("loggedToken"),
        },
        body: JSON.stringify(data),
      })
        .then((r) =>
          r.json().then((data) => ({
            status: r.status,
            body: data,
          }))
        )
        .then((obj) => {
          console.log(obj.body);
          setCurrentQuestionIndex(0);
          setSelectedClass({
            id: "",
            view_type: "",
          });
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          toast.error("Something went wrong");
          toast.clearWaitingQueue();
        });
    };
    useEffect(() => {
      setRemarks({
        enabler: Data?.enabler_remarks,
        parent: Data?.parent_remarks,
        internal: Data?.internal_remarks,
      });
    }, []);

    return (
      <div className="PTM_add_remarks_container">
        <div className="PTM_add_remark_input">
          <label htmlFor="">
            Remarks from {role == "master" ? "master enabler" : "enabler"}
          </label>
          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            defaultValue={Remarks?.enabler}
            onInput={(e) => {
              setRemarks((prev) => ({ ...prev, enabler: e.target.value }));
            }}
          ></textarea>
        </div>
        <div className="PTM_add_remark_input">
          <label htmlFor="">Remarks from parent</label>
          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            defaultValue={Remarks?.parent}
            onInput={(e) => {
              setRemarks((prev) => ({ ...prev, parent: e.target.value }));
            }}
          ></textarea>
        </div>
        {role == "master" && (
          <div className="PTM_add_remark_input">
            <label htmlFor="">Internal remarks</label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              defaultValue={Remarks?.internal}
              onInput={(e) => {
                setRemarks((prev) => ({ ...prev, internal: e.target.value }));
              }}
            ></textarea>
          </div>
        )}

        <button ref={ref} onClick={RemarksSubmit}></button>
      </div>
    );
  }
);

export default Addremarks;
