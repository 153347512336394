import React, { forwardRef, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { URL } from "../../../DROPDOWNDATA";
import "./PTMtemplate.css";

const MQR = forwardRef(
  (
    {
      Data,
      SelectedClass,
      setCurrentQuestionIndex,
      CurrentQuestionIndex,
      reloadQuestion,
      setLoader,
    },
    ref
  ) => {
    const [ListoutQues, setListoutQues] = useState();

    const MQR_handle = async () => {
      let Q_A = [];

      ListoutQues?.forEach((item, index) => {
        Q_A.push({});

        let radioname = document.getElementsByName(`${item?.question_id}`);
        for (let i = 0; i < radioname.length; i++) {
          Q_A[index] = {};
          if (radioname[i].checked) {
            Q_A[index] = {
              question_id: item?.question_id,
              given_answer: radioname[i].value,
              others: "",
            };
            break;
          } else {
            Q_A[index] = {
              question_id: item?.question_id,
              given_answer: "",
              others: "",
            };
          }
        }
      });
      for (let i = 0; i < Q_A.length; i++) {
        if (Q_A[i]?.given_answer == "") {
          toast.error("Kindly fill the fields");
          toast.clearWaitingQueue();
          return;
        }
      }
      setLoader(true);
      await fetch(`${URL}/add_update_analysis`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("loggedToken"),
        },
        body: JSON.stringify({
          view_no: SelectedClass?.id,
          q_a: Q_A,
        }),
      })
        .then((r) =>
          r.json().then((data) => ({
            status: r.status,
            body: data,
          }))
        )
        .then((obj) => {
          console.log(obj.body);
          setCurrentQuestionIndex(CurrentQuestionIndex + 1);
          reloadQuestion();
        })
        .catch((err) => {
          setLoader(false);
          toast.error("Something went wrong");
          toast.clearWaitingQueue();
        });
    };

    useEffect(() => {
      console.log(Data);
      setListoutQues(Data?.question_list);
    }, [Data]);

    return (
      <div className="multiple_question_container">
        <h3>{Data?.main_question}</h3>

        <div className="mqc_option_heading_container">
          <p></p>

          {Data?.option_list?.map((item, index) => {
            return <p key={index}>{item?.option_text}</p>;
          })}
        </div>

        <div className="mqr_ques_list">
          {ListoutQues?.map((item, index) => {
            return (
              <div key={index} className="mqr_ques_list_block">
                <p>{item?.question_text}</p>

                {item?.option_arr?.map((radioitem, radioindex) => {
                  return (
                    <div key={radioindex} className="mqr_ques_radio">
                      <input
                        type="radio"
                        name={item?.question_id}
                        id={radioitem?.option_id}
                        defaultChecked={
                          radioitem?.is_selected == 1 ? true : false
                        }
                        value={radioitem?.option_id}
                      />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>

        <button ref={ref} onClick={MQR_handle}></button>
      </div>
    );
  }
);

export default MQR;
